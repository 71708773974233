import { FilterTemplate } from "@contractool/schema";
import * as React from "react";

import { AppContext } from "contexts";
import { Form as CommonForm } from "components/Form";
import { useInteractions } from "features/hooks/reports/Form";
import { translate } from "utils/translations";

import ChartTypeSelect from "./ChartTypeSelect";
import { loadFilterTemplates } from "utils/filters";
import { useState } from "react";

const Form = () => {
    const { config } = React.useContext(AppContext);

    const metricValues = config.metrics;
    const workflowOptions =
        config?.workflows.map((workflow: any) => ({
            label: workflow?.label,
            value: workflow?.key
        })) || [];
    const [workflow, setWorkflow] = useState<string>("default");
    const metricOptions = metricValues[workflow].map((value: string) => ({
        label: value,
        value
    }));


    const { filterTemplates } = loadFilterTemplates(workflow);
    const groupByOptions = filterTemplates.map((filter: FilterTemplate) => ({
        label: filter.label,
        value: filter.name
    }));

    const { groupByFieldVisible } = useInteractions();

    return (
        <>
            <CommonForm.TextInput name="title" label={translate("Widget name")} autoFocus={true} />
            <CommonForm.Dropdown
                name="workflow"
                label={translate("Workflow")}
                options={workflowOptions}
                className="mt-6"
                onChange={(value: string) => {
                    setWorkflow(value);
                }}
            />
            <CommonForm.Dropdown
                name="metric"
                label={translate("Metric")}
                options={metricOptions}
                className="mt-6"
            />
            {groupByFieldVisible && (
                <CommonForm.Dropdown
                    name="groupBy"
                    label={translate("Group By")}
                    options={groupByOptions}
                    className="mt-6"
                    autocomplete={true}
                />
            )}
            <CommonForm.Field
                component={ChartTypeSelect}
                injectedClassName="mt-4"
                name="chartType"
                label={translate("Chart Type")}
                className="mt-6"
            />
        </>
    );
};

export default Form;
