import Downshift, {ControllerStateAndHelpers} from 'downshift';
import {Placement} from 'popper.js';
import * as React from 'react';
import {createPortal} from 'react-dom';
import {
    Manager,
    Popper,
    PopperChildrenProps,
    Reference,
    ReferenceChildrenProps,
} from 'react-popper';

import {Icon} from 'components/Icon';
import {Option} from 'components/select';
import {cx} from 'utils/classnames';

/*
This is from node_modules.
ControllerStateAndHelpers<Item> = DownshiftState<Item> &
    PropGetters<Item> &
    Actions<Item>

interface DownshiftState<Item> {
    highlightedIndex: number | null
    inputValue: string | null
    isOpen: boolean
    selectedItem: Item | null
}

interface PropGetters<Item> {
    getRootProps: (
        options?: GetRootPropsOptions,
        otherOptions?: GetPropsCommonOptions,
    ) => any
    getToggleButtonProps: (options?: GetToggleButtonPropsOptions) => any
    getLabelProps: (options?: GetLabelPropsOptions) => any
    getMenuProps: (
        options?: GetMenuPropsOptions,
        otherOptions?: GetPropsCommonOptions,
    ) => any
    getInputProps: <T>(options?: T) => T & GetInputPropsOptions
    getItemProps: (options: GetItemPropsOptions<Item>) => any
}

interface Actions<Item> {
    reset: (
        otherStateToSet?: Partial<StateChangeOptions<Item>>,
        cb?: Callback,
    ) => void
    openMenu: (cb?: Callback) => void
    closeMenu: (cb?: Callback) => void
    toggleMenu: (
        otherStateToSet?: Partial<StateChangeOptions<Item>>,
        cb?: Callback,
    ) => void
    selectItem: (
        item: Item,
        otherStateToSet?: Partial<StateChangeOptions<Item>>,
        cb?: Callback,
    ) => void
    selectItemAtIndex: (
        index: number,
        otherStateToSet?: Partial<StateChangeOptions<Item>>,
        cb?: Callback,
    ) => void
    selectHighlightedItem: (
        otherStateToSet?: Partial<StateChangeOptions<Item>>,
        cb?: Callback,
    ) => void
    setHighlightedIndex: (
        index: number,
        otherStateToSet?: Partial<StateChangeOptions<Item>>,
        cb?: Callback,
    ) => void
    clearSelection: (cb?: Callback) => void
    clearItems: () => void
    setItemCount: (count: number) => void
    unsetItemCount: () => void
    setState: (
        stateToSet: Partial<StateChangeOptions<Item>> | StateChangeFunction<Item>,
        cb?: Callback,
    ) => void
    // props
    itemToString: (item: Item | null) => string
}
*/

interface IRenderReferenceProps<T> extends ControllerStateAndHelpers<Option<T>> {
    placeholder: string;
}

const getSelectedItem: <T>(options: Option<T>[], value: T | null) => Option<T> | null = (
    options,
    value,
) => options.find((option) => option.value === value) ?? null;

const itemToString: <T>(item: Option<T> | null) => string = (item) => item?.label ?? '';

function Select<T = string | number | boolean>({
    value,
    onChange,
    options = [],
    placeholder = 'Select',
    usePortal = true,
    dropdownPlacement = 'bottom-start',
    renderReference = DefaultRenderReference,
}: {
    value: T | null;
    onChange: (v: T | null) => void;
    options?: Option<T>[];
    placeholder?: string;
    usePortal?: boolean;
    dropdownPlacement?: Placement;
    renderReference?: React.FC<IRenderReferenceProps<T>>;
}): React.ReactElement {
    const refDownshift = React.useRef<any>();

    function render(content: React.ReactNode): React.ReactNode {
        return usePortal ? createPortal(content, document.body) : content;
    }

    const [selectedItem, setSelectedItem] = React.useState<Option<T> | null>(
        getSelectedItem<T>(options, value),
    );
    React.useEffect(() => {
        setSelectedItem(getSelectedItem<T>(options, value));
        // eslint-disable-next-line
    }, [value, options]);

    const handleChange: (item: Option<T> | null) => void = (item) => {
        setSelectedItem(item);
        onChange(item?.value ?? null);
    };

    return (
        <Manager>
            <Downshift
                itemToString={itemToString}
                selectedItem={selectedItem}
                onChange={handleChange}
                ref={refDownshift}
            >
                {(stateAndHelpers: ControllerStateAndHelpers<Option<T>>) => (
                    <div>
                        <Reference>
                            {({ref}: ReferenceChildrenProps) => (
                                <div ref={ref}>
                                    {renderReference({
                                        ...stateAndHelpers,
                                        placeholder,
                                    })}
                                </div>
                            )}
                        </Reference>

                        {stateAndHelpers.isOpen &&
                            render(
                                <Popper
                                    modifiers={{
                                        hide: {enabled: false},
                                        preventOverflow: {enabled: false},
                                    }}
                                    placement={dropdownPlacement}
                                >
                                    {({ref, style, placement}: PopperChildrenProps) => (
                                        <div
                                            ref={ref}
                                            style={style}
                                            data-placement={placement}
                                            className="dropdown__popper bg-white rounded-lg shadow-lg py-2"
                                        >
                                            <ul
                                                {...stateAndHelpers.getMenuProps()}
                                                className="max-h-66 overflow-auto px-2"
                                            >
                                                {options.map((item, index) => {
                                                    const highlighted =
                                                        stateAndHelpers.highlightedIndex === index;
                                                    const selected =
                                                        selectedItem?.value === item.value;

                                                    return (
                                                        <li
                                                            {...stateAndHelpers.getItemProps({
                                                                key: String(item.value) + index,
                                                                index,
                                                                item,
                                                            })}
                                                            className={`px-6 py-3 leading-tighter rounded cursor-pointer ${
                                                                highlighted ? 'bg-gray-100' : ''
                                                            } ${selected ? 'text-blue-500' : ''}`}
                                                        >
                                                            {item.label}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                </Popper>,
                            )}
                    </div>
                )}
            </Downshift>
        </Manager>
    );
}

function DefaultRenderReference<T>(props: IRenderReferenceProps<T>) {
    return (
        <button
            className={cx('dropdown__reference', props.isOpen ? 'border-blue-700' : '')}
            onClick={() => props.toggleMenu()}
        >
            <div className="dropdown__reference-text">
                {props.selectedItem?.label ?? props.placeholder}
            </div>

            <div className="dropdown__reference-icon">
                <Icon
                    name="triangle"
                    className={`text-gray-600 ${props.isOpen ? '' : 'rotate-180'}`}
                    size={2}
                />
            </div>
        </button>
    );
}

export default Select;
