import React, {FC, useState, useReducer} from 'react';
import {Icon} from '../components/Icon';
import {http} from '../utils/http';
import {translate} from 'utils/translations';
import {User} from '@contractool/schema';
import {sign} from '../utils/auth';
import {upload} from '../utils/upload';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import {useDropzone} from 'react-dropzone';

export const Avatar: FC<{user: User; className?: string}> = ({user, className}) => {
    return (
        <div
            style={{backgroundImage: `url(${sign(user.avatar_url)})`}}
            className={`rounded-full ${className} bg-center bg-cover bg-no-repeat`}
        />
    );
};

export const AvatarLink: FC<{avatar_url: string; name: string; className?: string}> = ({
    avatar_url,
    name,
    className,
}) => {
    return <img src={sign(avatar_url)} alt={name} className={`rounded-full ${className}`} />;
};

export const AvatarUpload: FC<{
    user: User;
    onUpload?: (user: User) => void;
    className?: string;
}> = ({user, onUpload, className}) => {
    const [show, setShow] = useState(false);
    const [{uploading, progress}, dispatch] = useUploadingReducer();

    const startUpload = (file: File) => {
        dispatch({type: 'start_uploading'});

        upload(file, {
            onUploadProgress: (progress) => {
                dispatch({type: 'progress_uploading', progress});
            },
        })
            .then((file) => http.post<User>(user.avatar_url, {file}))
            .then((response) => {
                dispatch({type: 'finish_uploading'});

                onUpload && onUpload(response.data);
            });
    };

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: (files) => startUpload(files[0]),
    });

    return (
        <div
            className={`group relative block cursor-pointer rounded-full overflow-hidden focus:outline-none ${className}`}
            onMouseEnter={() => !show && setShow(true)}
            onMouseLeave={() => show && setShow(false)}
            {...getRootProps()}
        >
            <input type="file" className="hidden" {...getInputProps()} />

            {!uploading ? (
                <Avatar user={user} className="w-full h-full" />
            ) : (
                <div className="rounded-full w-full h-full bg-gray-000 flex items-center justify-center">
                    <CircularProgressbar
                        value={progress}
                        className="w-6 h-6"
                        strokeWidth={5}
                        styles={buildStyles({
                            pathTransitionDuration: 0.5,
                            pathColor: '#2979ff',
                            trailColor: '#e9ecef',
                        })}
                    />
                </div>
            )}

            {show && !uploading && (
                <div className="absolute flex items-center justify-center top-0 left-0 bg-transparent-500 w-32 h-32">
                    <div className="flex flex-col items-center text-white">
                        <Icon name="camera_alt" size={6} />
                        <span className="text-sm">{translate('Change')}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export function useUploadingReducer() {
    type Action =
        | {type: 'start_uploading'}
        | {type: 'progress_uploading'; progress: number}
        | {type: 'finish_uploading'};

    type State = {progress: number; uploading: boolean};

    return useReducer(
        (_: State, action: Action) => {
            switch (action.type) {
                case 'start_uploading':
                    return {uploading: true, progress: 0};
                case 'progress_uploading':
                    return {uploading: true, progress: action.progress};
                case 'finish_uploading':
                    return {uploading: false, progress: 0};
            }
        },

        {
            progress: 0,
            uploading: false,
        },
    );
}
