import React, { FC, useState } from 'react';
import { DocumentCategory, Document } from '@contractool/schema';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/Button';
import { DocumentComponent } from '../../components/documents/Document';
import { Tabs, Tab } from '../../components/Tabs';
import { useRequest } from '../../utils/hooks/useRequest';
import { Checkbox } from '../../components/Checkbox';
import { translate } from 'utils/translations';
import { UploadFile } from 'components/Attachment';
import { http } from 'utils/http';

export function AddFiles({
  hasPermission,
  hideUploadFile,
  onCompletedUpload,
  onSelectFiles,
  onClose,
}: {
  hasPermission: boolean;
  hideUploadFile?: boolean;
  onCompletedUpload?: (file: { path: string; name: string }) => void;
  onSelectFiles: (selectedFiles: Document[]) => void;
  onClose: () => void;
}) {
  return (
    <>
      <Modal heading={translate('Add files')} onClose={onClose}>
        {!hideUploadFile && (
          <>
            <label
              htmlFor="new-files"
              className="block leading-none select-none text-gray-500"
            >
              {translate('Upload new files')}
            </label>
            <div
              id="new-files"
              className="pt-6 pb-8 mb-12 border-gray-200 border-b"
            >
              <UploadFile onCompleted={onCompletedUpload} />
            </div>
          </>
        )}
        {hasPermission && (
          <Library onSelectFiles={onSelectFiles} onClose={onClose} />
        )}
      </Modal>
    </>
  );
}

const CheckboxDocument: FC<{
  document: Document;
  selectedFiles: string[];
  onChange: (values: string[]) => void;
}> = ({ document, selectedFiles, onChange }) => {
  return (
    <Checkbox
      name={`${document.title}`}
      value={document.id.toString()}
      selectedValues={selectedFiles}
      onChange={(values) => onChange(values)}
      childsClassName="w-full"
    >
      <DocumentComponent
        document={document}
        change={false}
        deletable={false}
        download={false}
        onDelete={async () => {
          await http.delete(document.url);
        }}
      />
    </Checkbox>
  );
};

const Library: FC<{
  onSelectFiles: (selectedFiles: Document[]) => void;
  onClose: () => void;
}> = ({ onSelectFiles, onClose }) => {
  const [categories] = useRequest<DocumentCategory[]>(
    '/api/library/categories',
    [],
  );
  const [documents] = useRequest<Document[]>('/api/library', []);

  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

  return (
    <form>
      <label className="block leading-none select-none text-gray-500 pb-6">
        {translate('Add from library')}
      </label>
      <Tabs selected="category0" className="bg-white px-5">
        {categories.map((category, index) => {
          return (
            <Tab
              key={'document-category' + index}
              name={'category' + index}
              heading={category.title}
              className="pt-4 bg-white p-5"
            >
              {documents
                .filter((document) => document.category_id === category.id)
                .map((document, index) => {
                  return (
                    <CheckboxDocument
                      key={`${document.title}${index}`}
                      document={document}
                      onChange={setSelectedFiles}
                      selectedFiles={selectedFiles}
                    />
                  );
                })}
            </Tab>
          );
        })}
      </Tabs>

      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Cancel')}
        </Button>

        <Button
          color="blue"
          onClick={() =>
            onSelectFiles(
              documents.filter((document) =>
                selectedFiles.includes(String(document.id)),
              ),
            )
          }
        >
          {translate('Add files')}
        </Button>
      </Modal.Footer>
    </form>
  );
};
