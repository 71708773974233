import React, { useState } from 'react';
import { Field } from 'formik';

import { TextInput } from 'components/TextInput';
import { Field as FieldWrapper } from 'components/Field';

import { FilterCondition, FilterTemplate } from '@contractool/schema';

import Modal from './Modal';

type Props = {
  name: string;
  templates: FilterTemplate[];
  condition: FilterCondition;
  editCondition: (index: number, value: any) => void;
  index: number;
  error?: string;
};

const Condition: React.FC<Props> = ({
  name,
  templates,
  condition,
  editCondition,
  index,
  error,
}) => {
  const [openPropertyModal, setOpenPropertyModal] = useState<boolean>(false);

  const state = templates.find(
    (templateCondition) => templateCondition?.name === condition?.subject,
  );

  const handleClick = () => setOpenPropertyModal(true);

  return (
    <>
      {templates.length > 0 && (
        <Field name={name}>
          {({ field, meta }: any) => (
            <FieldWrapper {...field} errorMessage={meta.touched && meta.error}>
              <TextInput
                {...field}
                onFocus={handleClick}
                placeholder="Condition ..."
                value={condition.subject ? state?.label : ''}
                hasError={Boolean(error)}
              />
            </FieldWrapper>
          )}
        </Field>
      )}
      {openPropertyModal && (
        <Modal
          templates={templates}
          editCondition={editCondition}
          condition={condition}
          index={index}
          setOpenModal={setOpenPropertyModal}
        />
      )}
    </>
  );
};

export default Condition;
