import { EChartsOption } from 'echarts-for-react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { Context as ReportContext } from 'contexts/reports/tab/report';
import { Context as ReportTabContext } from 'contexts/reports/tab';
import { http } from 'utils/http';
import Chart from './Chart';
import Filters from './Filters';
import Header from './Header';

const style = {
  padding: '10px 0',
  marginBottom: 40,
  height: '100%',
  border: '1px solid lightgray',
};

const Report: React.FC = () => {
  const history = useHistory();
  const { idx: tabIdx } = useContext(ReportTabContext);
  const { idx, config } = useContext(ReportContext);

  const onReportList = useCallback(
    (data: any) => {
      let name = data.data?.name || data.name;
      name = name.split(' - (')[0]//!!! hardcoded in App\Reports\Echart\Pie.php
      history.push({
        pathname: `/reports/${tabIdx}/${idx}/project-list`,
        search: name ? `name=${encodeURIComponent(name)}` : '',
      });
    },
    [history, idx, tabIdx],
  );

  const ref = useRef(null);
  // todo: refactor / resolve the issue without using DOM element
  // trick for filters panel overlap issue
  useEffect(() => {
    if (ref.current) {
      (ref.current || ({} as any)).parentNode.style.zIndex = config.collapsed
        ? 0
        : 1;
    }
  }, [config]);

  const { metric, groupBy, chartType, filters, grid, workflow } = config;
  const [chartOptions, setChartOptions] = useState<EChartsOption>(null);
  const serializedFilters = JSON.stringify(filters);
  const fetchChart = useCallback(() => {
    http
      .post(`/api/reports/generic-report?workflow=${workflow}`, {
        metric,
        groupBy,
        chartType,
        filters,
      })
      .then((response: any) => {
        const data = response?.data;
        if (
          data?.series?.type === 'pie' &&
          data?.series?.data.length > 12 &&
          data?.series?.data.length / ((grid?.h - 1) * 3) > 5
        ) {
          data.legend.width = 30;
          data.legend.left = '30%';
          data.legend.textStyle = {
            width: 1800 / (data?.series?.data.length / (grid?.h - 1)),
            ellipsis: '...',
            overflow: 'truncate',
          };
          data.series.center = ['15%', '50%'];
        }

        setChartOptions(response?.data);
      });
  }, [serializedFilters, workflow, metric, groupBy, chartType, grid.h]);

  useEffect(() => {
    fetchChart();
  }, [fetchChart]);

  return (
    chartOptions && (
      <div className="bg-white flex rounded-xl" style={style} ref={ref}>
        <div
          className="flex-auto flex flex-col pl-8 pr-18"
          style={{ minWidth: 0 }}
        >
          <div className="mr-16">
            <Header />
          </div>
          <div className="flex-auto overflow-auto">
            <Chart options={chartOptions} onReportList={onReportList} />
          </div>
        </div>
        <Filters />
      </div>
    )
  );
};

export default Report;
