import React, { useState } from 'react';
import { Page } from 'components/layout/Page';
import { Button } from 'components/Button';
import { translate } from 'utils/translations';
import Search from 'components/Search';
import { useQueryParams } from 'hooks/useParams';
import { Route, Switch } from 'react-router';
import { Tab, Tabs } from 'components/Tabs';
import CollectionsList from './Grid/CollectionsList';
import DocumentsList from './Grid/DocumentsList';
import TasksList from './Grid/TasksList';
import NewDocument, { AiDocument } from './documents/New';
import NewTask, { AiTask } from './tasks/New';
import NewCollection, { AiCollection } from './collections/New';
import { useRequest } from 'hooks/useRequest';
import { Pagination } from 'components/Pagination';
import EditTask from './tasks/Edit';
import EditCollection from './collections/Edit';

export function AiPage() {
  const [params, { setParam, setParams }] = useQueryParams({
    phrase: '',
    page: 1,
    per_page: 30,
  });
  const [tab, setTab] = useState('collections');
  const [
    collections,
    { refresh: refreshCollections, pagination: paginationCollection },
  ] = useRequest<AiCollection[]>('/api/ai/collections', [], {
    params,
  });
  const [tasks, { refresh: refreshTasks, pagination: paginationTasks }] =
    useRequest<AiTask[]>('/api/ai/tasks', [], {
      params,
    });

  const [
    documents,
    { refresh: refreshDocuments, pagination: paginationDocuments },
  ] = useRequest<AiDocument[]>('/api/ai/documents', [], {
    params,
  });

  const handleSelect = (tabString: string) => {
    setTab(tabString);
  };

  return (
    <Page
      heading="Artificial Intelligence"
      right={
        <div className="flex">
          <Button.Link
            to="/ai/documents/new"
            className="mr-4"
            color="white"
            icon="add"
          >
            {translate('Upload Documents')}
          </Button.Link>
          <Button.Link
            to="/ai/tasks/new"
            className="mr-4"
            color="white"
            icon="add"
          >
            {translate('Add Task')}
          </Button.Link>
          <Button.Link to="/ai/collections/new" color="blue" icon="add">
            {translate('Add Collection')}
          </Button.Link>
        </div>
      }
    >
      <div className="mb-8">
        <Search
          value={params.phrase}
          onChange={(phrase) => {
            setParams({ phrase, page: 1 });
          }}
          onClear={() => setParam('phrase', '')}
          placeholder={`${translate(`Search ${tab}`)}...`}
        />
      </div>
      <Tabs selected={tab} className="bg-white px-5" onSelect={handleSelect}>
        <Tab
          name="collections"
          heading={translate('Collections')}
          className="pt-4 bg-white p-5"
        >
          <CollectionsList
            collections={collections}
            onDelete={() => {
              refreshCollections();
            }}
          />
        </Tab>
        <Tab
          name="tasks"
          heading={translate('Tasks')}
          className="pt-4 bg-white p-5"
        >
          <TasksList
            tasks={tasks}
            onDelete={() => {
              refreshTasks();
            }}
          />
        </Tab>
        <Tab
          name="documents"
          heading={translate('Documents')}
          className="pt-4 bg-white p-5"
        >
          <DocumentsList
            documents={documents}
            onDelete={() => {
              refreshDocuments();
            }}
          />
        </Tab>
      </Tabs>
      <div className="flex justify-center pt-16">
        <Pagination
          currentPage={
            tab === 'collections'
              ? paginationCollection.currentPage
              : tab === 'documents'
              ? paginationDocuments.currentPage
              : paginationTasks.currentPage
          }
          totalPages={
            tab === 'collections'
              ? paginationCollection.totalPages
              : tab === 'documents'
              ? paginationDocuments.totalPages
              : paginationTasks.totalPages
          }
          totalBlocks={7}
          onSelect={(page) => setParam('page', page)}
        />
      </div>
      <Switch>
        <Route path="/ai/documents/new">
          <NewDocument onCreate={() => refreshDocuments()} closeUrl="/ai" />
        </Route>

        <Route path="/ai/tasks/new">
          <NewTask onCreate={() => refreshTasks()} closeUrl="/ai" />
        </Route>

        <Route path="/ai/collections/new">
          <NewCollection onCreate={() => refreshCollections()} closeUrl="/ai" />
        </Route>

        <Route path="/ai/tasks/edit/:id">
          <EditTask onUpdate={() => refreshTasks()} closeUrl="/ai" />
        </Route>

        <Route path="/ai/collections/edit/:id">
          <EditCollection
            onUpdate={() => refreshCollections()}
            closeUrl="/ai"
          />
        </Route>
      </Switch>
    </Page>
  );
}
