import * as React from 'react';

import { CustomReportFilter } from '@contractool/schema/ReportTab';
import moment from "moment";
import { DatePicker } from 'components/DatePicker';

const DateRangeInputs: React.FC<{
    filter: CustomReportFilter;
    onUpdate: (updatedFilter: CustomReportFilter) => void;
}> = ({filter, onUpdate}) => {
    const { property, values } = filter;
    const [from, setFrom] = React.useState<string | null>(filter.from ?? "");
    const [to, setTo] = React.useState<string | null>(filter.to ?? "");
    React.useEffect(() => {
        setFrom(filter.from ? moment(new Date(filter.from)).format("MM-DD-YYYY") : "");
        setTo(filter.to ? moment(new Date(filter.to)).format("MM-DD-YYYY") : "");
    }, [filter.from, filter.to]);

    const handleTo = React.useCallback(
        (to: string) => {
            onUpdate({
                ...filter,
                values: ["custom_range"],
                to: moment(new Date(to)).format("YYYY-MM-DD"),
                property
            });
        },
        [onUpdate, filter, property]
    );

    const handleFrom = React.useCallback(
        (from: string) => {
            onUpdate({
                ...filter,
                values: ["custom_range"],
                from: moment(new Date(from)).format("YYYY-MM-DD"),
                property
            });
        },
        [onUpdate, filter, property]
    );
    return <div className="flex">
        <div className="w-2/4">
            <DatePicker
                name="from"
                formatInput="MM-dd-yyyy"
                value={from}
                placeholder="from"
                onChange={(value) => {
                    handleFrom(value || "");
                }}
            />
        </div>
        <div className="w-2/4">
            <DatePicker
                name="to"
                formatInput="MM-dd-yyyy"
                value={to}
                placeholder="to"
                onChange={(value) => {
                    handleTo(value || "");
                }}
            />
        </div>
    </div>;
};

export default DateRangeInputs;
