import React, {useCallback, useState} from 'react';
import {Field} from 'components/Field';
import {Switch} from 'components/Switch';
import {Checkbox} from 'components/Checkbox';
import {Radio} from 'components/Radio';
import {TextInput} from 'components/TextInput';
import {Section, Variation} from './DesignSystem';
import {TextArea} from 'components/TextArea';
import {MonthlySavings} from 'components';

export function Forms() {
    const [toggleOne, setToggleOne] = useState(true);
    const [toggleTwo, setToggleTwo] = useState(false);
    const [radioBasic, setRadioBasic] = useState('foo');
    const [checkboxOne, setCheckboxOne] = useState(['one']);
    const [basicTextArea, setBasicTextArea] = useState('');

    const [monthlySavingsValue, setMonthlySavingsValue] = React.useState({
        years: {
            '2020y': {
                months: {
                    '11m': 7,
                    '12m': 3,
                },
                sum: 10,
            },
            '2021y': {
                months: {
                    '1m': 3,
                    '2m': 5,
                    '3m': 7,
                    '4m': 9,
                },
                sum: 24,
            },
        },
        final: 34,
    });
    const handleMonthlySavingsChange = useCallback((value: any) => {
        setMonthlySavingsValue(value);
    }, []);

    return (
        <>
            <Section title="Field">
                <Variation hint="Field with static text">
                    <Field name="static-field" label="Username">
                        <p className="py-4">phpftw</p>
                    </Field>
                </Variation>

                <Variation hint="Field with input">
                    <Field name="username" label="Username">
                        <TextInput name="username" placeholder="Enter your name" />
                    </Field>
                </Variation>

                <Variation hint="Field with error">
                    <Field
                        name="email"
                        label="E-mail"
                        errorMessage="This email address is already taken"
                    >
                        <TextInput
                            name="email"
                            type="email"
                            defaultValue="invalid@email"
                            hasError={true}
                        />
                    </Field>
                </Variation>
            </Section>
            <Section title="TextInput">
                <Variation hint="Default text input">
                    <TextInput name="default-input" />
                </Variation>

                <Variation hint="Input with placeholder">
                    <TextInput name="name" placeholder="Enter your name" />
                </Variation>

                <Variation hint="Password field placeholder">
                    <TextInput name="password-input" type="password" />
                </Variation>

                <Variation hint="Input with autoComplete=name">
                    <TextInput name="some_name" autoComplete="name" />
                </Variation>

                <Variation hint="Input with an icon">
                    <TextInput name="some_name" icon="lock" />
                </Variation>
            </Section>

            <Section title="TextArea">
                <Variation hint="Default text area">
                    <div className="w-72">
                        <TextArea
                            name="default-text-area"
                            value={basicTextArea}
                            onChange={setBasicTextArea}
                            placeholder="Fill me in if you can!"
                            rows={4}
                        />
                    </div>
                </Variation>
            </Section>

            <Section title="Switch">
                <Variation hint="Switch with a label">
                    <Switch name="switch_notifications" value={true} onChange={() => {}}>
                        Notifications
                    </Switch>
                </Variation>

                <Variation hint="Switch ON">
                    <Switch name="switch_on" value={toggleOne} onChange={setToggleOne} />
                </Variation>

                <Variation hint="Switch OFF">
                    <Switch name="switch_off" value={toggleTwo} onChange={setToggleTwo} />
                </Variation>
            </Section>

            <Section title="Radio buttons">
                <Variation hint="Basic radio group">
                    <Radio
                        name="radio_basic"
                        value="foo"
                        selectedValue={radioBasic}
                        onChange={setRadioBasic}
                    >
                        Binary 0
                    </Radio>

                    <Radio
                        name="radio_basic"
                        value="bar"
                        selectedValue={radioBasic}
                        onChange={setRadioBasic}
                    >
                        Binary 1
                    </Radio>
                </Variation>
            </Section>

            <Section title="Checkbox">
                <Variation hint="Basic checkbox group">
                    <Checkbox
                        name="checkbox_one"
                        value="one"
                        selectedValues={checkboxOne}
                        onChange={setCheckboxOne}
                    >
                        Option one
                    </Checkbox>

                    <Checkbox
                        name="checkbox_one"
                        value="two"
                        selectedValues={checkboxOne}
                        onChange={setCheckboxOne}
                    >
                        Option two
                    </Checkbox>
                </Variation>
            </Section>

            {/* <Section title="Monthly Savings">
                <Variation hint="Monthly savings component">
                    <MonthlySavings
                        value={monthlySavingsValue}
                        onChange={handleMonthlySavingsChange}
                    />
                </Variation>
            </Section> */}
        </>
    );
}
