import { Filter, Project, User } from '@contractool/schema';
import { omit } from 'lodash';
import { useContext } from 'react';
import React, { useState } from 'react';
import { Switch, Route } from 'react-router';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { AppContext, GuardContext } from 'contexts';

import { Icon } from 'components/Icon';
import { Page } from 'components/layout/Page';
import { Pagination } from 'components/Pagination';
import { Tabs, Tab } from 'components/Tabs';
import { useBigLoader } from 'components/Loader';
import Workflow from 'components/Workflow';

import { useRequest } from 'utils/hooks/useRequest';
import { useQueryParams } from 'utils/hooks/useParams';
import { EventEmitter } from 'utils/eventEmitter';
import { translate } from 'utils/translations';
import { Guest } from 'views/Guest';

import { FilterNew } from './FilterNew';
import { Categories } from './Categories';
import { ProjectsList } from './Grid/ProjectsList';
import { ProjectsFilters } from './Grid/ProjectsFilters';
import { http } from 'utils/http';
import { useToasts } from 'hooks';
import { toQueryString } from 'utils/url';
import { useLocalStorage } from '@rehooks/local-storage';
import { WelcomePopup } from 'views/WelcomePopup';
import './ProjectsPage.css';

export interface SearchParams {
  phrase: string;
  page: number;
  team: { assignee: number[] };
  categories: number[];
  states: string[];
  fields: {};
  suppliers: number[];
  filter_id: number;
  milestone_quarter: number[];
  workflow: string;
}

export type SortDirection = 'asc' | 'desc';

export function ProjectsPage() {
  const { user } = useContext(GuardContext);
  const { config, assessmentWorkflow } = useContext(AppContext);
  const location = useLocation();

  const [sortBy, setSortBy] = useState<string>();
  const [sortDirection, setSortDirection] = useState<SortDirection>();
  const [temporaryFilter, setTemporaryFilter] = useState<Filter | undefined>();
  const [isQueryParsed, setQueryAsParsed] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const handleSort = (column: string, direction: SortDirection) => {
    setSortBy(column);
    setSortDirection(direction);
  };

  const [params, { setParam, setParams }] = useQueryParams<SearchParams>({
    phrase: '',
    page: 1,
    team: { assignee: [] },
    categories: [],
    states: [],
    fields: {},
    suppliers: [],
    filter_id: -1,
    milestone_quarter: [],
    workflow: assessmentWorkflow,
  });

  const resolveSelectedTab = () => {
    let selectedTab = '-1';

    if (params.filter_id) {
      selectedTab = params.filter_id + '';
    }
    if (params.filter_id === 0) {
      selectedTab = '0';
    }
    return selectedTab;
  };

  let selectedTab = resolveSelectedTab();

  const [selectedTabName, setSelectedTabName] = useState<string>(selectedTab);

  React.useEffect(() => {
    if (!isQueryParsed && assessmentWorkflow) {
      let shouldBeSelected = resolveSelectedTab();
      console.log(
        'setSelectedTabName useEffect',
        shouldBeSelected,
        params.filter_id,
        params.filter_id.toString(),
      );
      if (selectedTabName !== shouldBeSelected) {
        setSelectedTabName(shouldBeSelected);
      }
      setQueryAsParsed(true);
    }
  }, [
    isQueryParsed,
    params,
    selectedTabName,
    temporaryFilter,
    location,
    user,
    setParam,
  ]);

  const [projects, { pagination, refresh: refreshProjects }] = useRequest<
    Project[],
    { total: number }
  >(
    '/api/projects',
    [],
    {
      params: {
        ...params,
        workflow: assessmentWorkflow,
        sort_by: sortBy,
        sort_direction: sortDirection,
      },
    },
    setLoaded,
  );

  //this variable is used for refreshing the number of projects on all the others tabs.
  const filtersOmittedParams = {
    ...omit({ ...params }, ['page', 'filter_id', 'workflow']),
  };

  const [filters, { refresh: refreshFilters }] = useRequest<Filter[]>(
    '/api/project-filters?workflow=' + assessmentWorkflow,
    [],
    {
      params: filtersOmittedParams,
    },
  );

  const orderFiltersCategory = React.useCallback(async (payload: Filter[]) => {
    const { data: updatedDocumentCategory } = await http.post<Filter[]>(
      '/api/project-filters/reorder',
      { list: payload },
    );

    return updatedDocumentCategory;
  }, []);

  const getSortableFiltersList = (filters: Filter[]) => {
    return filters.filter((category: Filter) => category.id > 0);
  };

  const handleReorderList = React.useCallback(
    async (event: any) => {
      const payload = [...(getSortableFiltersList(filters))];

      const movedFilterCategory = payload.splice(event.oldIndex, 1);
      payload.splice(event.newIndex, 0, ...movedFilterCategory);
      useBigLoader().start(translate('Reordering...'));
      await orderFiltersCategory(payload);
      await refreshFilters();
      useBigLoader().stop();
    },
    [orderFiltersCategory, refreshFilters, filters],
  );

  const updateTabs = (name: string) => {
    if (name !== selectedTabName) {
      setLoaded(false);
    }
    setSelectedTabName(name);
    switch (name) {
      case 'temp': {
        setParams({
          team: { assignee: [] },
          filter_id: temporaryFilter?.id,
          page: 1,
        });
        break;
      }
      default: {
        setParams({ team: { assignee: [] }, filter_id: Number(name), page: 1 });
      }
    }
  };

  const getTempFilterProjectCount = (
    temporaryFilter: Filter,
    selected: boolean,
  ) => {
    return selected ? pagination.totalRecords : temporaryFilter.projects_count;
  };

  const history = useHistory();
  const { success, error } = useToasts();

  let isGuest =
    user.groups.length === 0 &&
    user.groups.filter((g) => g.key === 'guest').length > 0;

  const sendExportRequest = () => {
    http
      .post('/api/projects/exports?workflow=' + assessmentWorkflow, params)
      .then(() => {
        success(
          translate(
            "Your export is being prepared. You will receive a notification once it's ready",
          ),
        );
      })
      .catch(() => {
        error(translate('Error in creating export. Please try again later.'));
      });
  };

  const [welcomePopup, setWelcomePopup] = useLocalStorage('_welcomePopup');
  if (welcomePopup !== 'Y' && config.integration === 'dell_ip') {
    return <WelcomePopup onClose={setWelcomePopup} />;
  }

  return isGuest ? (
    <Guest />
  ) : (
    <Page
      heading={translate('Projects')}
      right={
        <Workflow
          onChange={() => {
            if (selectedTab !== '-1' && selectedTab !== '-2') {
              updateTabs('0');
            }
          }}
        />
      }
    >
      <ProjectsFilters
        params={params}
        setParam={setParam}
        setParams={setParams}
      />
      <div className="-mx-6 relative">
        <Tabs
          onSelect={updateTabs}
          selected={selectedTabName}
          className="projects-dasboard"
        >
          {temporaryFilter ? (
            <Tab
              name="temp"
              heading={
                <div>
                  {translate('Applied filter')}
                  <span
                    className={`${
                      selectedTabName === 'temp' ? 'bg-blue-100' : 'bg-gray-000'
                    } text-sm rounded-full px-2 py-1 ml-2`}
                  >
                    {getTempFilterProjectCount(
                      temporaryFilter,
                      selectedTabName === 'temp',
                    )}
                  </span>
                </div>
              }
              className="py-8"
            >
              {params ? (
                <SearchResults
                  phrase={params.phrase}
                  projectLenght={projects.length}
                />
              ) : null}
              <ProjectsList
                projects={projects}
                onRefresh={refreshProjects}
                loaded={loaded}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onSort={handleSort}
              />
            </Tab>
          ) : (
            []
          )}

          {filters
            .filter((filter: Filter) => filter.is_visible)
            .map((filter: Filter) => (
              <Tab
                key={filter.id}
                name={filter.id.toString()}
                heading={
                  <div>
                    {filter.title}
                    <span
                      className={`${
                        selectedTabName === filter.id.toString()
                          ? 'bg-blue-100'
                          : 'bg-gray-000'
                      } text-sm rounded-full px-2 py-1 ml-2`}
                    >
                      {selectedTabName === filter.id.toString() && loaded
                        ? pagination.totalRecords
                        : filter.projects_count}
                    </span>
                  </div>
                }
                className="py-8"
              >
                {params ? (
                  <SearchResults
                    phrase={params.phrase}
                    projectLenght={projects.length}
                  />
                ) : null}
                <ProjectsList
                  projects={projects}
                  onRefresh={refreshProjects}
                  loaded={loaded}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  onSort={handleSort}
                />
              </Tab>
            ))}
        </Tabs>
        <div
          className="absolute top-0 right-0 mt-5 text-gray-500 cursor-pointer"
          style={{ top: '-60px' }}
        >
          <div className="flex">
            <span
              onClick={sendExportRequest}
              className="cursor-pointer flex items-center mr-6 text-gray-600"
              data-testid="export"
            >
              <Icon name="file-excel" size={5} className="mr-3" />
              {translate('Export')}
            </span>
            <Link
              to={`/projects/categories?${toQueryString(params)}`}
              className="flex items-center text-gray-600"
              title={translate(`Manage filters`)}
            >
              <Icon name="tune" size={5} className="mr-3" />
              {translate('Filters')}
            </Link>
          </div>
        </div>
      </div>
      <div className="flex justify-center pt-16">
        <Pagination
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPages}
          totalBlocks={7}
          onSelect={(page) => {
            setParam('page', page);
          }}
        />
      </div>
      <Switch>
        <Route path="/projects/categories">
          <Categories
            filters={filters}
            onRefresh={(id) => {
              if (selectedTabName === id?.toString()) {
                setSelectedTabName('-1');
                setParams({
                  filter_id: -1,
                });
              }
              refreshFilters();
              refreshProjects();
            }}
            handleReorderList={handleReorderList}
            getSortableFiltersList={getSortableFiltersList}
            onClose={() => {
              history.replace({
                pathname: '/projects',
                search: toQueryString(params),
              });
            }}
            params={params}
          >
            <FilterNew
              onSubmit={(filter: Filter) => {
                setParams({
                  team: { assignee: [] },
                  filter_id: filter.id,
                });
                setParams({ team: { assignee: [] }, filter_id: filter.id });

                refreshFilters();
                setSelectedTabName(filter.id.toString());
                history.replace({
                  pathname: '/projects/categories',
                  search: toQueryString({
                    team: { assignee: [] },
                    filter_id: filter.id,
                  }),
                });
              }}
              heading={translate('Create new filter')}
            />
          </Categories>
        </Route>
      </Switch>
    </Page>
  );
}

export const SearchResults: React.FC<{
  phrase: string;
  projectLenght: number;
  user?: User;
}> = ({ phrase, projectLenght, user }) => {
  const [filters, setFilters] = React.useState<{
    states: string[];
    assignee: string[];
    suppliers: string[];
    categories: string[];
  }>({
    assignee: user ? [user.name] : [],
    suppliers: [],
    categories: [],
    states: [],
  });

  const transformParams = (filters: {
    states: string[];
    assignee: string[];
    suppliers: string[];
    categories: string[];
  }) => {
    if (phrase) {
      const allParams = [
        ...filters.states,
        ...filters.assignee,
        ...filters.suppliers,
        ...filters.categories,
      ];

      return allParams.reduce((acc, val, index) => {
        return index !== 0 ? `${acc}, ${val}` : `${val}`;
      }, '');
    }

    return '';
  };

  EventEmitter.unsubscribe('search-filter');
  EventEmitter.subscribe('search-filter', (data: any) => {
    console.log('search-filter event', data);
    let newData = { ...data };
    if (data.assignee && user) {
      newData = { assignee: [filters.assignee[0], ...data.assignee] };
    }

    let newFilters = { ...filters, ...newData };

    console.log('search-filter settingFilters', newFilters);
    setFilters(newFilters);
  });

  const filtersString = transformParams(filters);

  return phrase.length ? (
    <div className="text-gray-500 mb-2 px-5">
      {projectLenght} {projectLenght === 1 ? 'Result' : 'Results'} for{' '}
      <span className="text-gray-700">{phrase}</span>
      {filtersString ? ' in ' : ''}
      {filtersString ? (
        <span className="text-gray-700">{filtersString}</span>
      ) : (
        ''
      )}
    </div>
  ) : null;
};
