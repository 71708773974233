import { FieldTemplate, Project, ProjectStatistics } from "@contractool/schema";
import React, { useContext, useState, useEffect, ReactNode } from "react";
import { AppContext, GuardContext } from "contexts";
import { DummyList } from "components/DummyList";
import { http } from "utils/http";
import { translate } from "utils/translations";
import { Icon } from "components/Icon";
import { Avatar } from "components/Avatar";
import { ProjectStateIcon } from "../ProjectStateIcon";
import { SortDirection } from "views/projects/ProjectsPage";
import ReactTooltip from "react-tooltip";
import { IconCircle } from "components/IconCircle";
import { getWorkflow } from "hooks/workflows";
import { isAllowedForUser } from "utils/form";

const SortIcon = ({ direction }: { direction: "asc" | "desc" }) => (
    <Icon name={direction === "asc" ? "keyboard_arrow_up" : "keyboard_arrow_down"} size={5} />
);

export function ProjectsList({
                                 projects,
                                 onRefresh,
                                 loaded,
                                 onFavorite,
                                 sortBy,
                                 sortDirection,
                                 onSort
                             }: {
    projects: Project[];
    onRefresh: () => void;
    loaded: boolean;
    onFavorite?: (added: boolean) => void;
    sortBy?: string;
    sortDirection?: SortDirection;
    onSort?: (column: string, direction: SortDirection) => void;
}) {
    const { config, assessmentWorkflow } = useContext(AppContext);
    const { user } = useContext(GuardContext);
    const [isFavorite, setIsFavorite] = useState<boolean[]>([]);
    useEffect(() => {
        setIsFavorite(
            projects.map((project) => {
                return project.is_favorite;
            })
        );
    }, [projects]);

    const gridColumns = getWorkflow(assessmentWorkflow).fields.meta_data.filter((field: FieldTemplate) => {
        return field.grid && isAllowedForUser(field, user);
    });

    const handleSort = (column: string) => {
        if (onSort) {
            onSort(column, column !== sortBy || sortDirection === "desc" ? "asc" : "desc");
        }
    };

    return (
        <>
            <table className="table">
                <thead>
                <tr>
                    <th className="w-12" />
                    {config.config.has_categories && <th className="p-0 m-0">Cat.</th>}
                    <th>
                        <div
                            className="flex items-center cursor-pointer"
                            onClick={() => handleSort("title")}
                        >
                            {translate("Name")}
                            {sortBy === "title" && sortDirection && (
                                <SortIcon direction={sortDirection} />
                            )}
                        </div>
                    </th>
                    <th>
                        <div
                            className="flex items-center cursor-pointer"
                            onClick={() => handleSort("assignee")}
                        >
                            {translate("Assignee")}
                            {sortBy === "assignee" && sortDirection && (
                                <SortIcon direction={sortDirection} />
                            )}
                        </div>
                    </th>
                    <th>
                        <div
                            className="flex items-center cursor-pointer"
                            onClick={() => handleSort("state")}
                        >
                            {translate("State")}
                            {sortBy === "state" && sortDirection && (
                                <SortIcon direction={sortDirection} />
                            )}
                        </div>
                    </th>
                    <th>
                        <div
                            className="flex items-center cursor-pointer"
                            onClick={() => handleSort("state")}
                        >
                            {translate("Phase")}
                            {sortBy === "state" && sortDirection && (
                                <SortIcon direction={sortDirection} />
                            )}
                        </div>
                    </th>
                    <th>
                        <div
                            className="flex items-center cursor-pointer"
                            onClick={() => handleSort("aging")}
                        >
                            {translate("Age")}
                            {sortBy === "aging" && sortDirection && (
                                <SortIcon direction={sortDirection} />
                            )}
                        </div>
                    </th>
                    {config.config.tat_grid && <th>
                        <div
                            className="flex items-center cursor-pointer"
                            onClick={() => handleSort("tat")}
                        >
                            TAT
                            {sortBy === "tat" && sortDirection && (
                                <SortIcon direction={sortDirection} />
                            )}
                        </div>
                    </th>}
                    {gridColumns.map((field: FieldTemplate, index: number) => {
                        return (
                            <th key={`grid-label-${index}`}>
                                <div
                                    className="flex items-center cursor-pointer"
                                    onClick={() => handleSort("fields." + field.name)}
                                >
                                    {field.label}
                                    {sortBy === "fields." + field.name && sortDirection && (
                                        <SortIcon direction={sortDirection} />
                                    )}
                                </div>
                            </th>
                        );
                    })}
                </tr>
                </thead>
                <tbody>
                {!loaded ? (
                    <DummyList rowsCount={10} colsCount={7} />
                ) : projects.length === 0 ? (
                    <tr>
                        <td colSpan={8} className="text-center">
                            {translate("No projects found")}
                        </td>
                    </tr>
                ) : (
                    projects.map((project, index) => {
                        return (
                            <tr
                                key={project.id}
                                className="hover:bg-gray-000 rounded align-middle table-row border-b-1 border-gray-100"
                            >
                                <td
                                    className="px-6 py-7"
                                    data-cy-favorite={isFavorite[index]}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        let newFavorites = [...isFavorite];
                                        newFavorites[index] = !isFavorite[index];
                                        setIsFavorite(newFavorites);
                                        http.put(`/api/projects/${project.id}/favorite`).then(
                                            () => {
                                                onFavorite && onFavorite(!project.is_favorite);
                                                onRefresh();
                                            }
                                        );
                                    }}
                                >
                                    <ReactTooltip />
                                    <Icon
                                        name={isFavorite[index] ? "grade" : "grade_outline"}
                                        size={6}
                                        className={
                                            isFavorite[index]
                                                ? "text-yellow-600 cursor-pointer"
                                                : "text-gray-600 cursor-pointer"
                                        }
                                    />
                                </td>
                                {config.config.has_categories && (
                                    <td
                                        data-tip={project.category?.title}
                                        className="px-6 py-7 m-0"
                                    >
                                        {project.category?.icon ? (
                                            <IconCircle
                                                name={project.category.icon.name}
                                                circleSize={10}
                                                iconSize={6}
                                                // color={project.category.icon.color}
                                            />
                                        ) : (
                                            <div className="p-2 bg-gray-100 rounded-full h-10 w-10">
                                                <Icon
                                                    name="category"
                                                    size={6}
                                                    className="text-gray-600"
                                                />
                                            </div>
                                        )}
                                    </td>
                                )}
                                <ProjectLink project={project}>{project.title}</ProjectLink>
                                <ProjectLink project={project}>
                                    {project.team.assignee ? (
                                        <div
                                            data-tip={project.team.assignee.name}
                                            className="flex"
                                        >
                                            <Avatar
                                                user={project.team.assignee}
                                                className="w-10 h-10 mr-4"
                                            />
                                            <div
                                                className="self-center"
                                                title={project.team.assignee?.name}
                                            >
                                                {project.team.assignee?.name.split(" ")[0]}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="text-gray-600 ml-2">-</div>
                                    )}
                                </ProjectLink>
                                <ProjectLink project={project}>
                                    <div className="flex">
                                        <ProjectStateIcon state={project.state} />
                                        <div className={"ml-4 self-center"}>
                                            {project.state.label}
                                        </div>
                                    </div>
                                </ProjectLink>
                                <ProjectLink project={project}>
                                    <ProjectStatistic statistics={project.statistics} />
                                </ProjectLink>
                                <ProjectLink project={project}>
                                    {project.extra["aging_human"]}
                                </ProjectLink>
                                {config.config.tat_grid && <ProjectLink project={project}>
                                    {project.extra["tat_human"]}
                                </ProjectLink>}
                                {gridColumns.map((field: FieldTemplate, index: number) => {
                                    return (
                                        <ProjectLink
                                            project={project}
                                            key={`grid-value-${index}`}
                                        >
                                            {project.fields_human[field.name]}
                                        </ProjectLink>
                                    );
                                })}
                            </tr>
                        );
                    })
                )}
                </tbody>
            </table>
        </>
    );
}

const ProjectStatistic = ({ statistics }: { statistics: Array<ProjectStatistics> }) => {
    return (
        <div className="flex">
            {statistics.map((statistics, index) => (
                <div
                    key={index}
                    title={statistics.title}
                    className={`mr-1 w-4 h-2 ${
                        statistics.type !== "next" ? "bg-blue-500" : "bg-gray-200"
                    } rounded-full`}
                ></div>
            ))}
        </div>
    );
};

const ProjectLink = ({ children, project }: { children?: ReactNode; project: Project }) => {
    return (
        <td className="cursor-pointer" style={{ padding: 0 }}>
            <a
                href={`/#/projects/${project.id}`}
                rel="noopener noreferrer"
                className="px-6 py-9 block w-full"
            >
                {children}
            </a>
        </td>
    );
};
