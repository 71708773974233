import React, {FC} from 'react'

import {Form} from 'components/Form'
import {translate} from 'utils/translations'
import {getOptions} from 'utils/form'
import {useRequest} from 'hooks/useRequest'
import {AiTask} from '../tasks/New'


const CollectionForm: FC<{create?: boolean}> = () => {
  const [tasks] = useRequest<AiTask[]>('/api/ai/tasks', [])
  const tasksAsOptions = getOptions(tasks)
  const init = {
    content_style: '.mce-content-body {padding : 7px}',
    height: 200,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
            'undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help'
  }

  return (
    <>
      <Form.TextInput
        name="name"
        label={translate('Collection name')}
        className="mb-6"
        autoFocus={true}
        required
      />
      <Form.TextTinyMce
        name="description"
        label={translate('Description')}
        className="mb-6"
        init={init}
        required
      />
      <Form.DynamicMultiSelect
        name="tasks"
        label={translate('Tasks')}
        placeholder={translate('Select tasks..')}
        options={tasksAsOptions}
        autocomplete
      />
    </>
  )
}

export default CollectionForm
