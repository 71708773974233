import { isArray, isObject, reduce, snakeCase } from 'lodash';

const to_snake_case = (obj: any): any => {
  if (!isObject(obj)) {
    return obj;
  } else if (isArray(obj)) {
    return obj.map((value) => to_snake_case(value));
  }
  return reduce(
    obj,
    (result, value, key) => {
      return {
        ...result,
        [snakeCase(key)]: to_snake_case(value),
      };
    },
    {},
  );
};

export { to_snake_case };
