import * as yup from 'yup';

const schema = yup.object().shape({
  categories: yup.array().of(
    yup.object().shape({
      heading: yup.string().required('Required'),
    }),
  ),
});

export { schema };
