import React from 'react';

import { FieldTemplate, Project } from '@contractool/schema';

import { Icon } from 'components/Icon';
import Badge from 'components/Badge';

type Props = {
  fields: FieldTemplate[];
  initialProject: Project;
};

const ProgressFormBadge: React.FC<Props> = ({ fields, initialProject }) => {
  const progressFormFields = fields.filter(
    (field) => field.progressFormOnClick,
  );

  return (
    <div className="flex pt-6 pb-6">
      {progressFormFields.map((field) => {
        let badge;

        if (initialProject?.fields) {
          Object.entries(initialProject?.fields).forEach(([key, value]) => {
            if (value && key === field?.name) {
              const advancedRadioValue = field?.advancedRadioValues.find(
                (advancedRadioValue) => advancedRadioValue?.value === value,
              );

              if (advancedRadioValue?.icon) {
                badge = (
                  <div className="pr-3" key={field?.name}>
                    <Badge
                      icon={<Icon name={advancedRadioValue?.icon} size={4} />}
                      color={advancedRadioValue.color}
                    >
                      {value}
                    </Badge>
                  </div>
                );
              }
            }
          });
        }

        return badge;
      })}
    </div>
  );
};

export default ProgressFormBadge;
