import { Option } from 'components/Dropdown';

export enum Tab {
  ASK_USER = 'ask_user',
  AUTOMATIC = 'automatic',
}

export enum AskUserActionType {
  FILL_SOME_METADATA = 'fill_some_metadata',
  CHOOSE_USER_TO_ROLE = 'choose_user_to_role',
  ATTACH_DOCUMENT = 'attach_document',
  SIGN_DOCUMENT = 'sign_document',
}

export enum AutomaticActionType {
  CREATE_APPROVAL = 'create_approval',
  CREATE_TASK = 'create_task',
  SEND_NOTIFICATION = 'send_notification',
  ATTACH_FILE = 'attach_file',
  ADD_USER_TO_ROLE = 'add_user_to_role',
  ASSIGN_PROJECT_TO_ROLE = 'assign_project_to_role',
  ADD_PROJECT_LOG_MESSAGE = 'add_project_log_message',
}

export enum UserRoleType {
  USER = 'user',
  ROLE = 'role',
}

export enum FieldType {
  METADATA = 'metadata',
  USER = 'user',
  ROLE = 'role',
  SELECTION = 'selection',
  SUBJECT = 'subject',
  DAYS = 'days',
  TITLE = 'title',
  TEXTAREA_SINGLE = 'textarea_single',
  TEXTAREA_IN_APP = 'textarea_in_app',
  TEXTAREA_EMAIL = 'textarea_email',
  ATTACH_FILE = 'attach_file',
  SWITCH = 'switch',
}

export type Config = {
  action: AskUserActionType | AutomaticActionType;
  fields: Field[];
};

export type Field = {
  name: FieldType;
  path: string;
  label?: string;
  isMulty?: boolean;
  className?: string;
};

export type SettingsForm = {
  id?: number;
  name: string;
  workflow: string;
  conditions: Condition[];
  results: {
    tab: string;
    askUser: AskUserResult[];
    automatic: AutomaticResult[];
  };
};

export type Condition = {
  subject: string;
  operation: Option<string> | null;
  value: string | string[];
};

export type AskUserResult = {
  action: AskUserActionType | string;
  fillSomeMetadata: Option<string>[] | [];
  chooseUserToRole: Option<string>[] | [];
};

export type AutomaticResult = {
  action: AutomaticActionType | string;
  createApproval: {
    selection: Option<string> | null;
    user: Option<string>[] | [];
    role: Option<string> | null;
    textareaSingle: string;
  };
  createTask: {
    selection: Option<string> | null;
    user: Option<string>[] | [];
    role: Option<string> | null;
    title:string;
    days: string;
    textareaSingle: string;
  };
  sendNotification: {
    selection: Option<string> | null;
    user: Option<string>[] | [];
    role: Option<string> | null;
    subject: string;
    textareaInApp: string;
    textareaEmail: string;
  };
  attachFile: any;
  addUserToRole: {
    user: Option<string>[] | [];
    role: Option<string>[] | [];
  };
  assignProjectToRole: Option<string> | null;
  addProjectLogMessage: string;
};
