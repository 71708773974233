import React, {useState} from 'react';
import {MultiValueGenericProps} from 'react-select';
import AsyncSelect from 'react-select/async';

import {Icon} from 'components/Icon';
import {Option} from 'components/Dropdown';

type Props = {label: string; value: string};

const MultiselectDropdownRows: React.FC<{
    endpoint?: string;
    onChange: (result: Option<string>[]) => void;
    values: Props[];
}> = ({endpoint, onChange, values}) => {
    const [data, setData] = useState<Props[]>(values || []);

    const colourStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            border: state.isHover ? 'none' : 'none',
            borderColor: state.isHover ? 'white' : 'white',
            padding: '5px',
            borderRadius: '0',
            borderBottom: '1px solid grey',
            marginTop: '20px',
        }),

        menu: (provided: any) => ({
            ...provided,
            padding: '5px',
            borderRadius: '0',
            marginTop: '20px',
        }),

        indicatorsContainer: (provided: any) => ({
            ...provided,
            display: 'none',
        }),

        container: (provided: any) => ({
            ...provided,
            marginBottom: '300px',
        }),
    };

    const handleChange = (value: any) => {
        setData(value);
        onChange(value);
    };

    const handleRemove = (value: string) => {
        setData((prevState) => prevState.filter((state) => state.value !== value));
        const newValues = data.filter((storedValue: any) => storedValue.value !== value);
        onChange(newValues);
    };

    const loadOptions = (inputValue: any) =>
        fetch(endpoint + `?phrase=${inputValue}`, {
          credentials: 'include'
        }).then((res) => res.json());

    const MultiValueContainer = (_props: MultiValueGenericProps<any>) => {
        return <div></div>;
    };

    return (
        <>
            {data.map((value: any) => (
                <div
                    className="my-5 p-4 mx-1 border-b hover:bg-blue-050 text-gray-600 flex"
                    key={value.value}
                >
                    {value.label}{' '}
                    <div
                        onClick={() => handleRemove(value.value)}
                        style={{cursor: 'pointer', marginLeft: 'auto'}}
                    >
                        <Icon name="delete" size={6} />
                    </div>
                </div>
            ))}
            <AsyncSelect
                className="border-0"
                isMulti
                defaultOptions
                placeholder="Start typing ..."
                styles={colourStyles}
                value={data}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                loadOptions={loadOptions}
                onChange={handleChange}
                components={{MultiValueContainer}}
            />
        </>
    );
};

export default MultiselectDropdownRows;
