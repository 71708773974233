import React from 'react';

import { FilterTemplate } from '@contractool/schema';

import { Condition as ConditionType } from 'types/output/setting';
import { createConditionLabel } from 'utils/settings';

type Props = {
  condition: ConditionType;
  templates: FilterTemplate[];
};

const Condition: React.FC<Props> = ({ condition, templates }) => {
  const subject = templates.find(
    (template) => template.name === condition.subject,
  );

  return (
    <div>
      <span className="italic">{`${subject?.label} `}</span>
      {`is ${condition?.operation?.label}`}
      <span className="italic">{` '${createConditionLabel(
        condition,
        templates,
      )}'`}</span>
    </div>
  );
};

export default Condition;
