import React, {FC} from 'react';
import {Project, Approval} from '@contractool/schema';
import {Modal} from 'components/Modal';
import {Link, useParams, useHistory} from 'react-router-dom';
import {Button} from 'components/Button';
import {Field} from 'components/Field';
import {sign} from 'utils/auth';
import {translate} from 'utils/translations';
import {formatDeadline} from './ApprovalsPanel';
import {Icon} from 'components/Icon';
import {Menu, MenuItem} from 'components/Menu';

export function ApprovalDetail({project, onClose}: {onClose: () => void; project: Project}) {
    const {approvalId} = useParams<{approvalId?: string}>();

    const approval = project.approvals.find((t) => approvalId && t.id === parseInt(approvalId));
    if (!approval) return null;

    return (
        <Modal
            heading={
                <Link
                    to={`/projects/${project.id}/approvals/${approval.id}/resolve`}
                    className="flex items-center"
                >
                    {approval.state === 'PENDING' ? (
                        <>
                            <div
                                className="w-10 h-10 flex items-center justify-center border border-gray-200 text-gray-500 rounded-full"
                                title={translate('Resolve approval ') + approval.title}
                            >
                                <Icon name="done" size={5} />
                            </div>
                            <span
                                className="text-base leading-tighter text-gray-700 ml-4"
                                title={translate('Resolve approval ') + approval.title}
                            >
                                {translate('Resolve approval ') + approval.title}
                            </span>
                        </>
                    ) : (
                        <>
                            <div
                                className="w-10 h-10 flex items-center justify-center border border-teal-000 text-teal-600 bg-teal-000 rounded-full"
                                title={translate('Re-open approval')}
                            >
                                <Icon name="done" size={5} />
                            </div>
                            <span
                                className="text-base leading-tighter text-gray-700 ml-4"
                                title={translate('Re-open approval')}
                            >
                                {translate('Re-open approval')}
                            </span>
                        </>
                    )}
                </Link>
            }
            onClose={onClose}
            compact={true}
            corner={<StateMenu approval={approval} project={project} />}
        >
            <Link
                to={`/projects/${project.id}/approvals/${approval.id}/edit`}
                className="text-xl text-gray-700"
            >
                {approval.title}
            </Link>
            <div className="flex border-b border-gray-100 pb-12 mb-12 pt-12">
                <div className="w-1/3">
                    <Field name="deadline" label={translate('Due date')}>
                        <div className="mt-4 flex items-center">
                            <Icon name="date_range" size={5} className="text-gray-600 inline" />
                            <span className="ml-3">{formatDeadline(approval.deadline)}</span>
                        </div>
                    </Field>
                </div>
                <div className="w-1/3">
                    <Field name="assignee_id" label={translate('Approver')}>
                        <div className="mt-4">
                            <img
                                src={sign(approval.assignee.avatar_url)}
                                alt={approval.assignee.name}
                                className="w-8 h-8 rounded-full inline "
                            />
                            <span className="ml-3">{approval.assignee.name}</span>
                        </div>
                    </Field>
                </div>
                <div className="w-1/3">
                    <Field name="assignor_id" label={translate('Created by')}>
                        <div className="mt-4">
                            <img
                                src={sign(approval.assignor.avatar_url)}
                                alt={approval.assignor.name}
                                className="w-8 h-8 rounded-full inline "
                            />
                            <span className="ml-3">{approval.assignor.name}</span>
                        </div>
                    </Field>
                </div>
            </div>

            <div className="mb-12">
                <Field name="description" label={translate('Description')}>
                    <textarea
                        rows={6}
                        className="pb-5 mt-5 w-full focus:outline-none border-b leading-none text-gray-700 placeholder-gray-400 resize-none leading-tight border-gray-200 focus:border-blue-700"
                        disabled
                        value={approval.body}
                    />
                </Field>
            </div>

            {approval.comment && (
                <div>
                    <label className="text-gray-600 select-none">{translate('Comment')}</label>
                    <div className="mt-2 mb-2">{approval.comment}</div>
                </div>
            )}

            <Modal.Footer className="flex justify-end">
                <Button color="white" onClick={onClose}>
                    {translate('Close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const StateMenu: FC<{approval: Approval; project: Project}> = ({approval, project}) => {
    const history = useHistory();

    return (
        <Menu handle="more_vert">
            <MenuItem
                icon="edit"
                onClick={() => {
                    history.push(`/projects/${project.id}/approvals/${approval.id}/edit`);
                }}
            >
                {translate('Edit')}
            </MenuItem>
        </Menu>
    );
};
