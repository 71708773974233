import React, { useContext, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router';

import CategoriesOverlay from './CategoriesOverlay';

import MainPanel from 'components/layout/MainPanel';
import { Menu, MenuItem } from 'components/Menu';
import { UploadFile } from 'components/Attachment';
import { UploadedFiles } from 'components/Attachment';
import { DocumentDetail } from 'components/documents/DocumentDetail';

import { DocumentCategory, Document } from '@contractool/schema';

import { http } from 'utils/http';
import { translate } from 'utils/translations';
import { useRequest } from 'utils/hooks/useRequest';
import { userPermissionRequest } from 'utils/wildcard';

import { URL_API_CATEGORIES, URL_API_LIBRARY } from '@constants/attachment';

import { GuardContext } from 'contexts';

type Props = {
  values?: string[];
  disableSettings?: boolean;
  enableSelection?: boolean;
  onSelect?: (document: Document) => void;
};

const Attachment: React.FC<Props> = ({
  values,
  disableSettings,
  enableSelection,
  onSelect,
}) => {
  const { user } = useContext(GuardContext);
  const history = useHistory();

  const [isManageCategoriesOpened, openManageCategoriesModal] =
    useState<boolean>();
  const [categoryId, setCategory] = useState(0);

  const [categories, { refresh: refreshCategories }] = useRequest<
    DocumentCategory[]
  >(URL_API_CATEGORIES, []);
  const [documents, { refresh: refreshAttachments }] = useRequest<Document[]>(
    URL_API_LIBRARY,
    [],
  );

  const attachments = documents.filter(
    (attachment) => attachment.type !== 'ai',
  );

  const handleManageCategories = () =>
    openManageCategoriesModal(!isManageCategoriesOpened);

  const handleSelectTab = (id: string) => setCategory(Number(id));

  const handleCloseManageCategoriesModal = () =>
    openManageCategoriesModal(false);

  return (
    <>
      <MainPanel
        heading={translate('Upload new document')}
        className="p-5"
        right={
          !disableSettings && (
            <Menu handle="settings">
              <MenuItem icon="list" onClick={handleManageCategories} nowrap>
                {translate('Manage categories')}
              </MenuItem>
            </Menu>
          )
        }
      >
        <UploadFile
          onCompleted={async (response) => {
            const payload = {
              file: response,
              category_id: categoryId,
            };

            await http.post<Document>(URL_API_LIBRARY, payload);
            refreshAttachments();
          }}
          additionalData={{ categoryId }}
        />
      </MainPanel>
      <UploadedFiles
        categories={categories}
        attachments={attachments}
        values={values}
        hasDeletePermission={userPermissionRequest(user, 'library.delete')}
        enableSelection={enableSelection}
        onDelete={async (document) => {
          await http.delete(document.url);
          refreshAttachments();
        }}
        onSelectTab={handleSelectTab}
        onSelect={onSelect}
      />
      {isManageCategoriesOpened && (
        <CategoriesOverlay
          categories={categories}
          documents={attachments}
          refreshCategories={refreshCategories}
          onClose={handleCloseManageCategoriesModal}
        />
      )}
      <Switch>
        <Route path={'/library/documents/:documentId'}>
          {({ match }) => {
            const document = attachments.find(
              (attachment) =>
                attachment.id === Number(match?.params.documentId),
            );

            if (!document) return;

            return (
              <DocumentDetail
                document={document}
                onUpdate={refreshAttachments}
                onClose={history.goBack}
              />
            );
          }}
        </Route>
      </Switch>
    </>
  );
};

export default Attachment;
