import {FieldTemplate, User, UserGroup} from '@contractool/schema';
import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';

import {Form} from 'components/Form';
import {Modal} from 'components/Modal';
import {Button} from 'components/Button';
import CustomField from 'components/CustomField';
import {AppContext} from 'contexts';

import {convertArrayToObject} from 'utils/form';
import {useRequest} from 'utils/hooks/useRequest';
import {translate} from 'utils/translations';
import {timezones} from 'utils/timezones';
import {useQueryParams} from 'hooks/useParams';

import {http} from 'utils/http';
import {toQueryString} from 'utils/url';
import {TeamParams} from './TeamPage';

export function TeamNew({onCreate}: {onCreate: () => void}) {
    const history = useHistory();
    const {config} = useContext(AppContext);
    const [{user_groups}] = useRequest<{user_groups: UserGroup[]}>('/api/configuration', {
        user_groups: [],
    });
    const [params] = useQueryParams<TeamParams>({
        phrase: '',
        licence: '',
        page: 1,
        per_page: 30,
        groups_in: [],
        state: '',
    });

    const close = () => {
        history.replace({
            pathname: '/team',
            search: toQueryString(params),
        });
    };

    let fieldsDefaults = convertArrayToObject(config.user_fields, 'name');
    for (let key in fieldsDefaults) {
        fieldsDefaults[key] = '';
    }

    return (
        <Modal heading={translate('Add user')} onClose={close}>
            <Form
                initialValues={{
                    name: '',
                    email: '',
                    timezone: '',
                    groups: [],
                    fields: fieldsDefaults,
                }}
                onSubmit={(values) => http.post<User>('/api/users', values)}
                onSuccess={() => {
                    close();
                    onCreate();
                }}
            >
                <Form.TextInput
                    name="name"
                    label={translate('Name')}
                    className="mb-6"
                    autoFocus={true}
                />
                <Form.TextInput name="email" label={translate('Email')} className="mb-6" />
                <Form.Dropdown
                    name="timezone"
                    label={translate('Timezone')}
                    options={timezones}
                    className="mb-6"
                    autocomplete
                />
                <Form.Switch.Multiple
                    name="groups"
                    label={translate('Groups')}
                    items={user_groups}
                    toKey={(group) => group.key}
                    className="flex flex-wrap py-4"
                >
                    {(group, Switch) => (
                        <div key={group.key} className="w-1/2 p-2">
                            <Switch>{group.label}</Switch>
                        </div>
                    )}
                </Form.Switch.Multiple>
                {config.user_fields.map((field: FieldTemplate, index: number) => {
                    return (
                        <CustomField
                            key={field.name + index}
                            field={field}
                            option="fields."
                            className="mb-6"
                        />
                    );
                })}
                <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={close}>
                        {translate('Cancel')}
                    </Button>

                    <Form.Submit>{translate('Save Changes')}</Form.Submit>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
