import * as React from 'react';

import {CustomReportFilter} from '@contractool/schema/ReportTab';
import {TextInput} from "components/TextInput";
import {Dropdown, Option} from "components/Dropdown";
import {translate} from "utils/translations";

const DateRangeInputs: React.FC<{
    filter: CustomReportFilter;
    onUpdate: (updatedFilter: CustomReportFilter) => void;
}> = ({filter, onUpdate}) => {
    const options: Option<string>[] = [
        {label: 'Days', value: 'days'},
        {label: 'Weeks', value: 'weeks'},
        {label: 'Months', value: 'months'},
        {label: 'Quarters', value: 'quarters'},
        {label: 'Years', value: 'years'},
    ];

    const handleWhat = React.useCallback(
        (what: string) => {
            onUpdate({
                ...filter,
                how_many_what: what,
            });
        },
        [onUpdate, filter]
    );

    const handleHowMany = React.useCallback(
        (howMany: string) => {
            console.log('handleHowMany', howMany);
            onUpdate({
                ...filter,
                how_many: parseInt(howMany),
            });
        },
        [onUpdate, filter]
    );

    return <div className="flex">
        <div className="w-1/4">
            <TextInput
                onChange={handleHowMany}
                value={`${filter.how_many ?? ''}`}
                placeholder={translate('number')}
            />
        </div>
        <div className="w-3/4">
            <Dropdown
                value={filter.how_many_what ?? 'days'}
                onChange={handleWhat}
                options={options}
            />
        </div>
    </div>;
};

export default DateRangeInputs;
