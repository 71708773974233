import React from 'react';

import { Page } from 'components/layout/Page';
import Attachment from 'components/Attachment';

import { translate } from 'utils/translations';

const Library = () => {
  return (
    <Page heading={translate('Library')}>
      <Attachment />
    </Page>
  );
};

export { Library };
