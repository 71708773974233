import React, {FC, ReactNode, useState} from 'react';
import {Icon, Icons} from './Icon';
import {Color, IconCircle} from './IconCircle';

export const BigRadioBox: FC<{
    icon?: Icons;
    iconColor?: string;
    iconBgColor?: string;
    title: string;
    description: string;
    span?: number;
    selected?: boolean;
    onClick: () => void;
}> = ({icon, title, description, span = 1, onClick, selected = false}) => {
    return (
        <button
            type="button"
            data-id={title}
            className={`group cursor-pointer border rounded-lg p-8 col-span-${span} flex flex-col justify-between h-full hover:text-blue-700 hover:border-blue-700 focus:outline-none focus:text-blue-700 focus:border-blue-700 ${
                selected ? 'text-blue-700 border-blue-700' : ''
            }`}
            onClick={onClick}
        >
            <div>
                {icon && (
                    <IconCircle
                        name={icon}
                        color="lightgray"
                        className={`mb-8 group-hover:text-blue-700 group-hover:bg-blue-000 group-focus:text-blue-700 group-focus:bg-blue-000 ${
                            selected ? 'text-blue-700 bg-blue-000' : ''
                        }`}
                        circleSize={16}
                        iconSize={8}
                    />
                )}
                <div className="text-xl mb-6 text-left">{title}</div>
                <div className="text-gray-500 h-30 text-left">{description}</div>
            </div>
            <Icon
                name="arrow_forward"
                size={6}
                className={`text-gray-500 group-hover:text-blue-700 group-focus:text-blue-700 ${
                    selected ? 'text-blue-700' : ''
                }`}
            />
        </button>
    );
};

export const SmallRadioBox: FC<{
    icon: Icons;
    color: Color;
    title: string;
    valueLabel?: string;
    selected?: boolean;
    readOnly?: boolean;
    onClick: () => void;
}> = ({icon, color, title, valueLabel, readOnly, selected = false, onClick}) => {
    const buttonHoverClass = readOnly ? null : 'hover:text-blue-700 hover:border-blue-700';
    const buttonCursorClass = readOnly ? 'cursor-auto' : 'cursor-pointer';
    const iconHoverClass = readOnly ? null : 'group-hover:text-blue-700';
    const selectedClass = selected ? 'text-blue-700 border-blue-700 bg-blue-000' : '';

    return (
        <div>
            {valueLabel && (
                <div className={"mt-15 mb-5 ml-1 text-md text-gray-600 h-26"}>
                    {valueLabel}
                </div>
            )}
            <button
                data-id={title}
                type="button"
                className={`small-radio-box-value group ${buttonCursorClass} border rounded-lg p-5 flex flex justify-between items-center w-full ${buttonHoverClass} focus:outline-none focus:text-blue-700 focus:border-blue-700 ${selectedClass}`}
                disabled={readOnly}
                onClick={onClick}
            >
                <div className="flex items-center">
                    {icon && (
                        <IconCircle
                            name={icon}
                            color={color}
                            className={'mr-4'}
                            circleSize={10}
                            iconSize={5}
                        />
                    )}
                    <div className="text-l text-left">{title}</div>
                </div>
                <Icon
                    name="arrow_forward"
                    size={6}
                    className={`mr-2 text-gray-500 ${iconHoverClass} group-focus:text-blue-700 ${
                        selected ? 'text-blue-700' : ''
                    }`}
                />
            </button>
        </div>
    );
};

export const RadioBoxContainer: FC<{
    type: 'big' | 'small';
    label?: string;
    right?: ReactNode;
    legend?: string;
    helptext?: string;
    required?: boolean;
    colSize?: number;
    rowSize?: number;
    className?: string;
    errorMessage?: string;
}> = ({
    children,
    type,
    label,
    right,
    legend,
    helptext,
    required,
    colSize,
    rowSize,
    className,
    errorMessage,
}) => {
    const [helptTextVisible, setHelptTextVisible] = useState(false);
    const helpTextHtml = {
        dangerouslySetInnerHTML: {__html: helptext + ''},
    };

    if (type === 'big') {
        return (
            <div className={`w-full mb-6 p-2 ${className}`}>
                {label && (
                    <div className="text-md text-gray-600 flex items-center">
                        {label}
                        {required && <span className="ml-1 text-red-700">*</span>}
                        {helptext && (
                            <span
                                className="cursor-pointer ml-2"
                                onClick={() => {
                                    setHelptTextVisible(!helptTextVisible);
                                }}
                            >
                                <Icon name="question" size={5} />
                            </span>
                        )}
                        {right && <div className="leading-normal select-none">{right}</div>}
                    </div>
                )}
                {legend && <div className="text-sm text-gray-500">{legend}</div>}
                {helptext && helptTextVisible && (
                    <div className="text-gray-500" {...helpTextHtml} />
                )}
                <div className={`grid grid-cols-${colSize} mt-6 gap-4`}>{children}</div>
            </div>
        );
    }

    return (
        <div className={`w-full mb-6 p-2 ${className}`}>
            {label && (
                <div
                    className={`text-md  flex items-center ${
                        errorMessage ? 'text-red-700' : 'text-gray-600'
                    }`}
                >
                    {label}
                    {required && <span className="ml-1 text-red-700">*</span>}
                    {helptext && (
                        <span
                            className="cursor-pointer ml-2"
                            onClick={() => {
                                setHelptTextVisible(!helptTextVisible);
                            }}
                        >
                            <Icon name="question" size={5} />
                        </span>
                    )}
                </div>
            )}
            {legend && <div className="text-sm text-gray-500">{legend}</div>}
            {helptext && helptTextVisible && <div className="text-gray-500" {...helpTextHtml} />}
            <div
                className={`grid gap-4 mt-8 grid-cols-${colSize} grid-rows-${rowSize} ${
                    errorMessage ? 'border-b border-red-700' : ''
                }`}
            >
                {children}
            </div>
        </div>
    );
};
