import React from 'react';
import { TableBodyProps } from 'react-table';

type Props = {
  children: React.ReactNode;
  getTableBodyProps: () => TableBodyProps;
};

const TableBody: React.FC<Props> = ({ children, getTableBodyProps }) => (
  <tbody {...getTableBodyProps()}>{children}</tbody>
);

export default TableBody;
