import * as React from 'react';
import { Modal } from 'components/Modal';
import { translate } from 'utils/translations';
import { Option } from 'components/select';
import { FilterCondition, FilterTemplate } from '@contractool/schema';
import { Button } from 'components/Button';
import { Action } from 'utils/filterReducer';
import Search from 'components/Search/Search';
import { useEffect, useState } from 'react';

const ConditionsMap: React.FC<{
  setOpenModal: (value: boolean) => void;
  dispatch: React.Dispatch<Action>;
  filterTemplates: FilterTemplate[];
  idx: number;
  condition?: FilterCondition;
}> = ({ setOpenModal, dispatch, condition, filterTemplates, idx }) => {
  console.log('workflows', filterTemplates);

  let [search, setSearch] = useState('');
  //ParsedFilter are all the filters split up to basic fields, and the rest.
  let [parsedFilters, setParsedFilters] = useState(
    parseFilters({ groups: filterTemplates, search }),
  );

  useEffect(() => {
    setParsedFilters(parseFilters({ groups: filterTemplates, search }));
  }, [search]);

  //the rest of parsedFilters are split up to fields, roles and dates.
  const fields = getProperties(parsedFilters);
  console.log('ConditionsMap', fields, parsedFilters);

  const updateProperty = React.useCallback(
    (value: any) => {
      dispatch({
        type: 'UPDATE_CONDITION_NAME',
        id: idx,
        name: value,
        filterTemplates,
      });
      setOpenModal(false);
    },
    [setOpenModal, dispatch, filterTemplates, idx],
  );

  const searchField = (phrase: string) => {
    console.log('phrase', phrase);
    setSearch(phrase);
  };

  return (
    <Modal
      heading={translate('Select a property')}
      onClose={() => {
        setOpenModal(false);
      }}
      size="-"
      corner={
        <Search
          placeholder={translate('Filter properties...')}
          value={search}
          onChange={searchField}
        />
      }
    >
      <div className="flex items-stretch h-96">
        <div
          className="flex items-stretch h-96 overflow-y-auto overflow-x-hidden"
          id="workflow-properties-list"
        >
          <div className="border-r">
            <MapMenu
              label={translate('Basic')}
              selected={condition?.subject ?? ''}
              items={
                parsedFilters && parsedFilters.basic ? parsedFilters.basic : []
              }
              selectValue={updateProperty}
            />
          </div>
          <div className="border-r">
            <MapMenu
              label={translate('Fields')}
              selected={condition?.subject ?? ''}
              items={fields ? fields.fields ?? [] : []}
              selectValue={updateProperty}
            />
          </div>
          <div className="border-r">
            <MapMenu
              label={translate('Roles')}
              selected={condition?.subject ?? ''}
              items={fields ? fields.roles ?? [] : []}
              selectValue={updateProperty}
            />
          </div>
          <div className="border-r">
            <MapMenu
              label={translate('Dates')}
              selected={condition?.subject ?? ''}
              items={fields ? fields.dateFields ?? [] : []}
              selectValue={updateProperty}
            />
          </div>
        </div>
      </div>
      <Modal.Footer className="flex justify-between">
        <Button
          color="white"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          {translate('Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

interface IMapItem {
  label?: string;
  value: string;
  name?: string;
}

export const MapMenu: React.FC<{
  barName?: string;
  items: IMapItem[];
  selected: string;
  label?: string;
  selectValue: (value: any) => void;
}> = ({ barName, items, selected, label, selectValue }) => {
  return (
    <>
      {label && <div className="p-3">{label}</div>}
      <div className={'flex flex-col items-center shrink-0 h-full w-60'}>
        <ul className="w-full">
          {items.map((item, index) => (
            <MapItem
              barName={barName}
              selected={selected}
              selectValue={selectValue}
              key={index}
              {...item}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

const MapItem: React.FC<
  IMapItem & {
    barName?: string;
    selected: string;
    selectValue: (value: string) => void;
  }
> = ({ barName, label, value, name, selected, selectValue }) => {
  return (
    <li
      className={`py-3 cursor-pointer hover:bg-gray-150 ${
        selected === value || selected === name
          ? 'bg-gray-150 border-r-2 border-blue-700'
          : ''
      }`}
      onClick={() => {
        selectValue(value ?? name);
      }}
    >
      <div
        className={`flex items-center hover:text-blue-700 focus:text-blue-700 focus:outline-none ${
          selected === value || selected === name
            ? 'text-blue-700 '
            : 'text-gray-600'
        }`}
      >
        <div className="ml-3 leading-tighter select-none">
          {barName === 'workflow' && value !== 'basic' && 'Workflow - '}
          {label}
        </div>
      </div>
    </li>
  );
};

export const parseFilters = ({
  groups,
  search,
}: {
  groups: FilterTemplate[];
  search: string;
}) => {
  return groups
    .filter((group: FilterTemplate) => {
      let result = group.label.toLowerCase().match(search.toLowerCase());
      console.log('parseFilters search', group, search, result);
      return result;
    })
    .reduce((prev: any, group) => {
      if (group.type === 'DATE_RANGE') {
        group.values = [
          { label: 'Last Week', value: 'last_week' },
          { label: 'Last Month', value: 'last_month' },
          { label: 'Last Year', value: 'last_year' },
          { label: 'Current Month', value: 'current_month' },
          { label: 'Current year', value: 'current_year' },
          { label: 'Custom range', value: 'custom_range' },
        ];
      }

      if (!group.name.includes('fields->') && !group.name.includes('role=')) {
        prev['basic'] = prev['basic'] ? [...prev['basic'], group] : [group];
      } else {
        prev['default'] = prev['default']
          ? [...prev['default'], group]
          : [group];
      }

      return prev;
    }, {});
};

export const getProperties = (parsedFilters: any) => {
  if (Object.keys(parsedFilters).length === 0) {
    return false;
  }
  console.log('getProperties', parsedFilters);
  return parsedFilters?.default?.reduce((prev: any, group: any) => {
    if (group?.name?.includes('fields->') && group?.type === 'DATE_RANGE') {
      prev['dateFields'] = prev['dateFields']
        ? [...prev['dateFields'], group]
        : [group];
    } else if (group?.name?.includes('role=') && group?.type !== 'DATE_RANGE') {
      prev['roles'] = prev['roles'] ? [...prev['roles'], group] : [group];
    } else if (group?.name?.includes('fields->')) {
      prev['fields'] = prev['fields'] ? [...prev['fields'], group] : [group];
    }

    return prev;
  }, {});
};

export default ConditionsMap;
