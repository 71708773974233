import React from 'react';

import { Tab, Tabs } from 'components/Tabs';
import { DocumentComponent } from 'components/documents/Document';

import { Document, DocumentCategory } from '@contractool/schema';

import { translate } from 'utils/translations';

type Props = {
  categories: DocumentCategory[];
  attachments: Document[];
  hasDeletePermission: boolean;
  values?: string[];
  enableSelection?: boolean;
  onDelete: (document: Document) => void;
  onSelectTab: (tabId: string) => void;
  onSelect?: (document: Document) => void;
};

const UploadedFiles: React.FC<Props> = ({
  categories,
  attachments,
  hasDeletePermission,
  values,
  enableSelection,
  onDelete,
  onSelectTab,
  onSelect,
}) => {
  return (
    <Tabs
      selected={String(categories[0]?.id)}
      className="bg-white px-5"
      onSelect={onSelectTab}
    >
      {categories.map((category, index) => (
        <Tab
          key={'document-category' + index}
          name={`${category.id}`}
          heading={translate(category.title)}
          className="pt-4 bg-white p-5"
        >
          {attachments
            .filter((attachment) => attachment.category_id === category.id)
            .map((attachment, index) => (
              <DocumentComponent
                key={index}
                document={attachment}
                deletable={hasDeletePermission}
                values={values}
                enableSelection={enableSelection}
                onDelete={onDelete}
                onSelect={(document) => {
                  if (onSelect) {
                    onSelect(document);
                  }
                }}
              />
            ))}
        </Tab>
      ))}
    </Tabs>
  );
};

export default UploadedFiles;
