import { DayModifiers } from 'react-day-picker';
import { format } from 'date-fns';

const dayType = (
  modifiers: DayModifiers,
): 'normal' | 'selected' | 'outside' | 'today' | 'disabled' => {
  if (modifiers.selected) {
    return 'selected';
  }
  if (modifiers.outside || modifiers.disabled) {
    return 'outside';
  }
  if (modifiers.today) {
    return 'today';
  }

  return 'normal';
};

const getDate = (value: string) => {
  const date = new Date(value);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;

  return new Date(date.getTime() + userTimezoneOffset);
};

function formatValue(formatInput, value: null | string): string {
  if (!value) {
    return '';
  }

  if (!formatInput) {
    return value;
  }

  if (typeof formatInput === 'function') {
    return formatInput(value);
  }

  return format(getDate(value), formatInput);
}

export { dayType, getDate, formatValue };
