import React, { FC, useEffect, useState } from 'react';

import { Form, getValue } from 'components/Form';
import { CustomField } from 'components';
import { FormRole } from 'components';

import { getOptions } from 'utils/form';
import { translate } from 'utils/translations';
import { EventEmitter } from 'utils/eventEmitter';
import { useRequest } from 'hooks/useRequest';
import { getWorkflow } from 'hooks/workflows';

import { FieldTemplate, Project, ProjectType, Role } from '@contractool/schema';

import ProjectFormContext from 'views/project/ProjectFormContext';
import { permissionRequest } from "utils/wildcard";

type Props = {
  project: Project;
  tab?: string;
  titleEdited: boolean;
  onChange?: (field: FieldTemplate) => void;
};

export const DefaultForm: FC<Props> = ({
  project,
  tab = 'default',
  titleEdited,
  onChange,
}) => {
  const { create, supplierTitle } = React.useContext(ProjectFormContext);
  const [types] = useRequest<ProjectType[]>('/api/project-types', []);
  const typesAsOptions = getOptions(types);

  const workflow = getWorkflow(project.workflow);

  if (workflow?.settings.disabled) {
    if (
      (!create && !permissionRequest(project.can, 'project.disabled_workflow.edit'))
      ||
      (create && !permissionRequest(project.can, 'project.disabled_workflow.create'))
    ) {
      window.location.href = `/#/projects/new`;
      window.location.reload();
    }
  }

  const fields = workflow?.fields?.meta_data.filter(
    (field: FieldTemplate) =>
      field?.hidden === false &&
      field?.workflow === project.workflow &&
      field?.tab === tab,
  );

  const roles = workflow?.roles.filter(
    (role: Role) =>
      role.showInForm &&
      role.workflow === project.workflow &&
      role.key !== 'admin' &&
      role.key !== 'requestor' &&
      role.key !== 'assignee' &&
      role?.tab === tab,
  );

  let titleSeparator = workflow.settings.title_separator;

  let titlePartsTemp: any = {};
  for (let titlePart of workflow.settings.title) {
    if (project) {
      //we need to convert small_radio_box.fields.commodity_lone to fields.commodity_lone
      let splited = titlePart.split('.');
      if (splited.length > 1) {
        splited.shift();
      }
      let value = getValue(project, splited.join('.'));
      if (value) {
        titlePartsTemp[titlePart] = value;
      }
    }
  }

  const [titleParts, setTitlePars] = useState<any>(titlePartsTemp);

  for (let titlePart of workflow.settings.title) {
    EventEmitter.unsubscribe(titlePart);
    EventEmitter.subscribe(titlePart, (part: string) => {
      let newParts = JSON.parse(JSON.stringify(titleParts));
      newParts[titlePart] = part;
      setTitlePars(newParts);
    });
  }

  useEffect(() => {
    if (!create && workflow.settings.edit_title) {
      return;
    }
    if (titleEdited) {
      return;
    }
    let parts = [];
    for (let titlePart of workflow.settings.title) {
      let value = null;
      if (titlePart === 'supplier_title') {
        if (!supplierTitle) {
          value = '';
        } else {
          value = supplierTitle;
        }
      } else if (titlePart === 'year') {
        value = new Date().getFullYear();
      } else {
        value = titleParts[titlePart];
      }
      if (value) {
        parts.push(value);
      }
    }
    let title = parts.join(titleSeparator).trim();

    EventEmitter.dispatch(
      (create ? 'new-project' : 'edit-project') + '.set-form-values',
      {
        title: title,
      },
    );
  }, [
    titleParts,
    create,
    titleSeparator,
    supplierTitle,
    workflow.settings.title,
    workflow.settings.edit_title,
    titleEdited,
  ]);

  let groups: any = {};
  for (let f of fields) {
    if (f.group !== '') {
      groups[f.group] = f.group;
    }
  }

  return project ? (
    <>
      {roles && fields && (
        <>
          <div className="divide-y divide-gray-100">
            <div className="py-8 w-full">
              {types.length > 0 && (
                <Form.Multiselect
                  name="types"
                  label={translate('Project Types')}
                  options={typesAsOptions}
                  autocomplete
                  placeholder={translate('Project Types')}
                  dropdownClassName="justify-between border-b border-gray-200 focus:border-blue-700 py-6"
                  className="mt-6"
                  changeKeys={{ key1: 'id', key2: 'title' }}
                  required={true}
                />
              )}
              {/*<Form.DatePicker*/}
              {/*    name="finishes_at"*/}
              {/*    label={translate('Target Date for completion')}*/}
              {/*    className="mt-6"*/}
              {/*    required={true}*/}
              {/*/>*/}
              {fields
                .filter((f: FieldTemplate) => f.group === '')
                .filter((f: FieldTemplate) =>
                  create ? true : f.type !== 'ATTACHMENT',
                ) //lets not display attachment uploads on edit form
                .map((field: FieldTemplate) => {
                  const className = field.progressFormOnClick
                    ? 'mt-16 mb-16'
                    : 'mt-6 mb-4';
                  return (
                    <CustomField
                      key={`${field.name}`}
                      field={field}
                      option="fields."
                      className={className}
                      workflow={workflow}
                      onChange={onChange}
                    />
                  );
                })}
              {create &&
                roles.map((role: Role) => (
                  <FormRole role={role} key={role.key} />
                ))}
            </div>
            {Object.keys(groups).map((group: string) => {
              return (
                <div key={group}>
                  <h1 className="text-xl">{group}</h1>
                  <div className="py-8 w-full">
                    {fields
                      .filter((f: FieldTemplate) => f.group === group)
                      .map((field: FieldTemplate) => {
                        const className = field.progressFormOnClick
                          ? 'mt-16 mb-16'
                          : 'mt-6 mb-2';
                        return (
                          <CustomField
                            key={`${field.name}`}
                            field={field}
                            option="fields."
                            className={className}
                            onChange={onChange}
                          />
                        );
                      })}
                  </div>
                </div>
              );
            })}
            {/*{fields.map((arr:any, index:number) => (*/}
            {/*<div*/}
            {/*key={`rowdiv${index}`}*/}
            {/*className="flex flex-wrap divide-x divide-gray-100"*/}
            {/*>*/}
          </div>
          {/*))}*/}
          {/*</div>*/}
        </>
      )}
    </>
  ) : null;
  return project ? (
    <>
      {roles && fields && (
        <>
          <div className="divide-y divide-gray-100">
            <div className="py-8 w-full">
              {types.length > 0 && (
                <Form.Multiselect
                  name="types"
                  label={translate('Project Types')}
                  options={typesAsOptions}
                  autocomplete
                  placeholder={translate('Project Types')}
                  dropdownClassName="justify-between border-b border-gray-200 focus:border-blue-700 py-6"
                  className="mt-6"
                  changeKeys={{ key1: 'id', key2: 'title' }}
                  required={true}
                />
              )}
              {/*<Form.DatePicker*/}
              {/*    name="finishes_at"*/}
              {/*    label={translate('Target Date for completion')}*/}
              {/*    className="mt-6"*/}
              {/*    required={true}*/}
              {/*/>*/}
              {fields
                .filter((f: FieldTemplate) => f.group === '')
                .filter((f: FieldTemplate) =>
                  create ? true : f.type !== 'ATTACHMENT',
                ) //lets not display attachment uploads on edit form
                .map((field: FieldTemplate) => (
                  <CustomField
                    key={`${field.name}`}
                    field={field}
                    option="fields."
                    className="mt-6 mb-4"
                    workflow={workflow}
                  />
                ))}
              {create &&
                roles.map((role: Role) => (
                  <FormRole role={role} key={role.key} />
                ))}
            </div>
            {Object.keys(groups).map((group: string) => {
              return (
                <div key={group}>
                  <h1 className="text-xl">{group}</h1>
                  <div className="py-8 w-full">
                    {fields
                      .filter((f: FieldTemplate) => f.group === group)
                      .map((field: FieldTemplate) => (
                        <CustomField
                          key={`${field.name}`}
                          field={field}
                          option="fields."
                          className="mt-6 mb-2"
                        />
                      ))}
                  </div>
                </div>
              );
            })}
            {/*{fields.map((arr:any, index:number) => (*/}
            {/*<div*/}
            {/*key={`rowdiv${index}`}*/}
            {/*className="flex flex-wrap divide-x divide-gray-100"*/}
            {/*>*/}
          </div>
          {/*))}*/}
          {/*</div>*/}
        </>
      )}
    </>
  ) : null;
};
