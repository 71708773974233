import { CustomReport } from '@contractool/schema';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Report from 'components/reports/tab/Report';

import { loadFilterTemplates } from 'utils/filters';

import ProjectListOverlay from './ProjectListOverlay';
import EditOverlay from './EditOverlay';

import { Context as ReportTabContext } from 'contexts/reports/tab';
import { Context as ReportContext } from 'contexts/reports/tab/report';

const Provider: React.FC<{
  config: CustomReport;
  idx: number;
  onUpdate: (updatedConfig: CustomReport, idx: number) => Promise<CustomReport>;
  onDelete: (idx: number) => void;
  children: React.ReactNode;
}> = ({ config, onUpdate, onDelete, idx, children }) => {
  const handleUpdate = (updatedConfig: CustomReport) =>
    onUpdate(updatedConfig, idx);

  const { filterTemplates, groupedFilterTemplates } =
    loadFilterTemplates(config.workflow);
  const remove = async () => {
    await onDelete(idx);
  };

  const [configState, setConfigState] = useState<CustomReport>(config);
  const configRef = useRef<CustomReport>(config);
  // strongly depends on config prop
  useEffect(() => {
    setConfigState(config);
    configRef.current = config;
  }, [config]);

  const update = async (updatedConfig: CustomReport) => {
    const config = await handleUpdate(updatedConfig);
    setConfigState(config);
    configRef.current = config;
  };

  const value = {
    config: configState,
    filterTemplates,
    groupedFilterTemplates,
    update,
    remove,
    idx,
  };

  return (
    <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
  );
};

const Routes = () => {
  const { idx: tabIdx } = useContext(ReportTabContext);
  const { idx } = useContext(ReportContext);

  return (
    <>
      <Route path="/reports/:tabIdx">
        <Report />
      </Route>
      <Switch>
        <Route path={`/reports/${tabIdx}/${idx}/edit`}>
          <EditOverlay />
        </Route>
        <Route path={`/reports/${tabIdx}/${idx}/project-list`}>
          <ProjectListOverlay />
        </Route>
      </Switch>
    </>
  );
};

const View: React.FC<{
  config: CustomReport;
  idx: number;
  onUpdate: (updatedConfig: CustomReport, idx: number) => Promise<CustomReport>;
  onDelete: (idx: number) => void;
}> = ({ config, onUpdate, onDelete, idx }) => (
  <Provider config={config} onUpdate={onUpdate} onDelete={onDelete} idx={idx}>
    <Routes />
  </Provider>
);

export default View;
