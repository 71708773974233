import React, { FC, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Kind } from '@contractool/schema';
import { Page } from '../../components/layout/Page';
import { AppContext } from 'contexts';
import { Workflow } from '../../../../_common/schema/Workflow';

export function NewProject() {
  const { config } = useContext(AppContext);

  let kinds = config.workflows.map((w: Workflow) => w.kinds).flat();
  kinds = kinds.filter((kind: Kind) => {
    return kind.buttons.length > 0;
  });
  if (kinds.length === 0) {
    return <Redirect to="/projects/new/form" />;
  }
  if (kinds.length === 1 && kinds[0].buttons.length === 1) {
    return <Redirect to="/projects/new/form" />;
  }

  return (
    <Page heading="New Projects">
      {config.integration === 'dell_swp' && (
        <div className={'text-md'}>
          Do not submit requests that are <b>Microsoft</b> or{' '}
          <b>Internal Use</b> here.
          <ul>
            <li />
            For Microsoft requests, please contact Pratheeba Paneer Se
            (pratheeba_paneer_se@dell.com).
            <li />
            For Internal Use, please click this link -{' '}
            <a
              href="https://dell-ip.cequence.io"
              target="_blank"
              className={'text-blue-700'}
            >
              https://dell-ip.cequence.io
            </a>
            .
          </ul>
        </div>
      )}

      <div className="flex justify-around align-center mt-20">
        {kinds.map((kind: Kind, index: number) => {
          return <Card key={'card' + index} kind={kind} />;
        })}
      </div>
    </Page>
  );
}

const Card: FC<{ kind: Kind }> = ({ kind }) => {
  const serialize: any = function (obj: any, prefix: any) {
    let str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === 'object'
            ? serialize(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v),
        );
      }
    }

    return str.join('&');
  };

  return (
    <div className="w-1/3 mx-4 h-100 rounded border shadow">
      <div className="p-4 flex flex-col justify-between text-center h-full text-gray-800">
        <div className="text-lg font-medium">{kind.title}</div>
        {kind.description && (
          <div className="text-gray-600 pt-4 pb-4">{kind.description}</div>
        )}
        {kind.buttons.map((btn, index) => {
          let searchParams = serialize({ ...btn.extra, kind: btn.label });

          return (
            <Link
              key={`link${index}`}
              className={`cursor-pointer px-4 py-3 rounded leading-tight border truncate ${
                btn.type === 'secondary'
                  ? 'border-blue-600 bg-white hover:bg-gray-100 text-blue-600'
                  : 'border-blue-400 bg-blue-600 hover:bg-blue-700 text-white'
              }`}
              to={{
                pathname: '/projects/new/form',
                search: `?${searchParams}`,
                state: btn.extra.workflow,
              }}
            >
              {btn.label}
            </Link>
          );
        })}
      </div>
    </div>
  );
};
