import { CustomReport, FilterTemplate } from "@contractool/schema";
import { createContext } from "react";

export const Context = createContext<{
    config: CustomReport,
    filterTemplates: FilterTemplate[],
    groupedFilterTemplates: {
        [key: string]: any
    },
    update: (updatedConfig: CustomReport) => void
    remove: () => void
    idx: number
}>({
    config: null as unknown as CustomReport,
    filterTemplates: null as unknown as FilterTemplate[],
    groupedFilterTemplates: null as unknown as any,
    update: () => {
    },
    remove: () => {
    },
    idx: null as unknown as number
});
