import { FieldTemplate, Project, TeamMember } from '@contractool/schema';
import React, { FC, ReactNode, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router';

import FieldAtTop from 'components/FieldAtTop';
import { useRequest } from 'hooks/useRequest';
import { translate } from 'utils/translations';
import { permissionRequest } from 'utils/wildcard';
import { ApprovalsPanel } from 'views/projects/Approvals/ApprovalsPanel';
import { Tutorial } from 'views/projects/Widgets/Tutorial';
import { TaskPanel } from './TaskPanel';
import { ProjectDetailsPanel } from './ProjectDetailsPanel';
import { ContractPanel } from './ContractPanel';
import { DocumentsPanel } from './DocumentsPanel';
import { ProjectActions } from './ProjectActions';
import { ProgressPanel } from './ProgressPanel';
import { ActivityPanel } from './ActivityPanel';
import { ProjectTeam } from './ProjectTeam';
import { Milestones } from './Widgets/Milestone';
import { Esign } from './Widgets/Esign';
import { WetSign } from './Widgets/WetSign';
import { Hierarchy } from './Widgets/Hierarchy';
import { Workload } from './Widgets/Workload';
import Group from './Widgets/Group';
import ProjectContext from './ProjectContext';
import { ProjectLabels } from 'views/projects/ProjectLabels';
import { ProjectCreatedPopup } from 'views/ProjectCreatedPopup';
import { AppContext } from 'contexts';
import { Workflow } from '../../../../_common/schema/Workflow';
import { RelatedProjects } from 'views/projects/Widgets/RelatedProjects';
import { http } from 'utils/http';
import HighlightedField from 'components/HighlightedField';

export function ProjectDetail() {
  const { id } = useParams<{ id?: string }>();
  const { config } = useContext(AppContext);
  if (!config) {
    return null;
  }
  const [project, { refresh }] = useRequest<Project | undefined>(
    `/api/projects/${id}`,
    undefined,
    undefined,
    () => {
      setProjectLoading(false);
    },
  );
  const workflow = project
    ? config.workflows.find(
        (workflow: Workflow) => workflow.key === project.workflow,
      )
    : null;
  const history = useHistory();
  const [projectLoading, setProjectLoading] = useState<boolean>(false);
  const topProjectFields = project
    ? workflow.fields.meta_data.filter((f: FieldTemplate) => f.isAtTop)
    : [];

  const highlightedFields = project
    ? workflow.fields.meta_data.filter((f: FieldTemplate) => f.isHighlighted)
    : [];

  const projectContext = React.useMemo(
    () => ({
      resource: project as Project,
      workflow: workflow as Workflow,
      teamMembers:
        project?.team.memberships.filter(
          (member: TeamMember) =>
            member.role !== 'assignee' &&
            member.role !== 'requestor' &&
            !member.deleted,
        ) ?? [],
      refresh,
    }),
    [project, refresh, workflow],
  );
  if (!project) return null;
  const close = () => {
    history.push(`/projects/${project.id}`);
  };

  const CancelTag: FC<{ project: Project }> = ({ project }) => {
    return ['denied', 'canceled', 'onhold'].some((item) =>
      project.state.key.includes(item),
    ) ? (
      <div
        className="px-2 py-1 self-center bg-red-100 text-red-700 rounded-lg"
        data-cy="canceled-tag"
      >
        {translate(project.state.label)}
      </div>
    ) : null;
  };

  const widgetPermission = (name: string) => {
    return permissionRequest(project.can, 'project.widget.' + name);
  };

  const handleSendFiles = (
    file: { path: string; name: string },
    type?: string,
  ) =>
    http.post<Document>(project.attachments_url, {
      file,
      type,
    });

  const rightWidgets = [];
  const topWidgets = [];
  const leftWidgets = [];
  for (const position in workflow?.settings.widgets_layout) {
    const rows = workflow?.settings.widgets_layout[position];
    for (const widgets of rows) {
      if (position === 'left') {
        leftWidgets.push(widgets);
      }
      if (position === 'right') {
        rightWidgets.push(widgets);
      }
      if (position === 'top') {
        topWidgets.push(widgets);
      }
    }
  }

  const renderWidget = (widgetName: string) => {
    if (!project.state.extra.components?.includes(widgetName)) {
      return null;
    }
    let widget = <></>;
    switch (widgetName) {
      case 'milestone':
        widget = (
          <>
            {permissionRequest(
              project.can,
              'project.widget.milestone.view',
            ) && (
              <div className="p-10 border-b border-gray-200 bg-gray-000">
                <Milestones project={project} onUpdate={refresh} />
              </div>
            )}
          </>
        );
        break;
      case 'workload':
        widget = (
          <div className="p-10 border-b border-gray-200 ">
            <Workload project={project} />
          </div>
        );
        break;
      case 'log':
        widget = (
          <div className="p-8 border-b border-gray-200">
            <ActivityPanel project={project} onUpdate={refresh} />
          </div>
        );
        break;
      case 'related_projects':
        widget = <RelatedProjects project={project} onUpdate={refresh} />;
        break;
      case 'top_fields':
        widget = (
          <>
            {topProjectFields.map((field: FieldTemplate) => (
              <FieldAtTop key={field.name} field={field} project={project} />
            ))}
          </>
        );
        break;
      case 'highlighted_fields':
        widget = highlightedFields.length > 0 && (
          <div className="p-8 border-b border-gray-200 bg-gray-000 grid gap-4 grid-cols-4">
            {highlightedFields.map((field: FieldTemplate) => (
              <HighlightedField
                key={field.name}
                field={field}
                project={project}
              />
            ))}
          </div>
        );
        break;
      case 'contract':
        widget = (
          <>
            {widgetPermission('contract.view') && (
              <ContractPanel
                project={project}
                refresh={refresh}
                onSelectedFiles={refresh}
                onClose={close}
              />
            )}
          </>
        );
        break;
      case 'documents':
        widget = (
          <DocumentsPanel
            heading={translate('Attachments')}
            fileAddLabel={translate('Add files')}
            modalUrl={`/projects/${project.id}/documents/browse/}`}
            project={project}
            onCompletedUpload={async (file) => {
              await handleSendFiles(file);
              close();
              refresh();
            }}
            onSelectedFiles={refresh}
            onDeleted={refresh}
            onClose={close}
          />
        );
        break;
      case 'esign':
        widget = (
          <>
            {project.signatures?.length > 0 && (
              <Esign project={project} onUpdate={refresh} />
            )}
          </>
        );
        break;
      case 'wet_sign':
        widget = <WetSign project={project} />;
        break;
      case 'rfp':
        widget = (
          <DocumentsPanel
            heading={translate(':type Files', { type: 'RFP' })}
            fileAddLabel={translate('Add :type file', { type: 'RFP' })}
            modalUrl={`/projects/${project.id}/documents/browse/RFP}`}
            type="RFP"
            project={project}
            onCompletedUpload={async (file) => {
              await handleSendFiles(file, 'RFP');
              close();
              refresh();
            }}
            onSelectedFiles={refresh}
            onDeleted={refresh}
            onClose={close}
          />
        );
        break;
      case 'competitive_bidding':
        widget = (
          <DocumentsPanel
            heading="Bid Files"
            fileAddLabel="Add Bid Files"
            modalUrl={`/projects/${project.id}/documents/browse/RFP}`}
            type="RFP"
            project={project}
            onCompletedUpload={async (file) => {
              await handleSendFiles(file, 'RFP');
              close();
              refresh();
            }}
            onSelectedFiles={refresh}
            onDeleted={refresh}
            onClose={close}
          />
        );
        break;
      case 'tasks':
        widget = (
          <TaskPanel project={project} onUpdate={refresh} onClose={close} />
        );
        break;
      case 'approvals':
        widget = (
          <>
            {permissionRequest(project.can, 'approval.view') && (
              <ApprovalsPanel
                project={project}
                onUpdate={refresh}
                onClose={close}
              />
            )}
          </>
        );
        break;
      case 'details':
        widget = (
          <ProjectDetailsPanel
            project={project}
            onUpdate={refresh}
            onClose={close}
          />
        );
        break;
      case 'widget_fields':
        widget = (
          <ProjectContext.Provider value={projectContext}>
            <Group />
          </ProjectContext.Provider>
        );
        break;
      case 'hierarchy':
        widget = <Hierarchy project={project} />;
    }
    return (
      <div key={widgetName} className="flex-1">
        {widget}
      </div>
    );
  };

  return (
    <ProjectContext.Provider value={projectContext}>
      <div className="flex flex-col h-full">
        <ProjectCreatedPopup />
        <div className="p-16 border-b border-gray-200" data-cy-section="header">
          <div className="mb-6 flex justify-between">
            <div className="flex items-center">
              <ProjectLabels project={project} />
              <ProjectActions project={project} onRefresh={refresh} />
            </div>
            <ProjectTeam onUpdate={refresh} onClose={close} />
          </div>
          <div className="flex">
            <h1 className="text-xl mr-4">{project.title}</h1>
            <CancelTag project={project} />
          </div>
          <ProgressPanel
            project={project}
            onRefresh={() => {
              refresh();
            }}
            projectLoading={projectLoading}
            setProjectLoading={setProjectLoading}
          />
        </div>
        {project.extra.sign_url && (
          <div className="mb-8" style={{ height: '800px' }}>
            <iframe
              title={project.title}
              src={project.extra.sign_url}
              width="100%"
              height="100%"
              style={{
                border: 0,
                overflow: 'hidden',
                minHeight: '600px',
                minWidth: '600px',
              }}
            />
          </div>
        )}
        {topWidgets.map((cols) => {
          return <div className="flex">{cols.map(renderWidget)}</div>;
        })}
        {project.state.extra.tutorial && (
          <Tutorial text={project.state.extra.tutorial} />
        )}
        <div className="flex flex-col-reverse md:flex-row md:flex-1 ">
          <div className="w-full md:w-7/12 bg-gray-000">
            {leftWidgets.map((cols) => {
              return <div className="flex">{cols.map(renderWidget)}</div>;
            })}
          </div>
          <div className="w-full md:w-5/12 border-l border-gray-200">
            {rightWidgets.map((cols) => {
              return <div className="flex">{cols.map(renderWidget)}</div>;
            })}
          </div>
        </div>
      </div>
    </ProjectContext.Provider>
  );
}

export const SidePanel: FC<{
  heading: ReactNode;
  right?: ReactNode;
  className?: string;
}> = ({ heading, right, className, children }) => {
  return (
    <div
      className={
        className ? className : 'hover:bg-gray-001 p-8 border-b border-gray-200'
      }
      data-cy-section={heading}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="text-lg">{heading}</div>
        {right}
      </div>
      {children}
    </div>
  );
};
