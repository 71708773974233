import {
  AskUserActionType,
  AutomaticActionType,
  UserRoleType,
} from 'types/output/setting';
import { translate } from 'utils/translations';

export const URL_RULE = '/settings';
export const URL_NEW_RULE = `${URL_RULE}/new`;
export const URL_EDIT_RULE = `${URL_RULE}/:id/edit`;
export const URL_ATTACHMENTS = `${URL_NEW_RULE}/attachments`;

export const URL_API_RULES = '/api/rules';

export const URL_TAB = '/settings/:tab';

export const askUserOptions = [
  {
    label: translate('Fill some metadata'),
    value: AskUserActionType.FILL_SOME_METADATA,
  },
  {
    label: translate('Choose user to role'),
    value: AskUserActionType.CHOOSE_USER_TO_ROLE,
  },
  {
    label: translate('Attach document'),
    value: AskUserActionType.ATTACH_DOCUMENT,
  },
  {
    label: translate('Sign document'),
    value: AskUserActionType.SIGN_DOCUMENT,
  },
];

export const automaticOptions = [
  {
    label: translate('Create approval'),
    value: AutomaticActionType.CREATE_APPROVAL,
  },
  {
    label: translate('Create task'),
    value: AutomaticActionType.CREATE_TASK,
  },
  {
    label: translate('Send notification'),
    value: AutomaticActionType.SEND_NOTIFICATION,
  },
  {
    label: translate('Attach file'),
    value: AutomaticActionType.ATTACH_FILE,
  },
  {
    label: translate('Add user to role'),
    value: AutomaticActionType.ADD_USER_TO_ROLE,
  },
  {
    label: translate('Assign project to role'),
    value: AutomaticActionType.ASSIGN_PROJECT_TO_ROLE,
  },
  {
    label: translate('Add project log message'),
    value: AutomaticActionType.ADD_PROJECT_LOG_MESSAGE,
  },
];

export const userRoleOptions = [
  {
    label: translate('Select user'),
    value: UserRoleType.USER,
  },
  {
    label: translate('Select role'),
    value: UserRoleType.ROLE,
  },
];

export const condition = {
  subject: '',
  operation: null,
  value: [],
};

export const askUser = {
  action: '',
  fillSomeMetadata: [],
  chooseUserToRole: [],
};

export const automatic = {
  action: '',
  createApproval: {
    selection: null,
    user: [],
    role: null,
    textareaSingle: '',
  },
  createTask: {
    selection: null,
    user: [],
    role: null,
    title: '',
    days: '',
    mandatory: false,
    textareaSingle: '',
  },
  sendNotification: {
    selection: null,
    user: [],
    role: null,
    subject: '',
    textareaInApp: '',
    textareaEmail: '',
  },
  attachFile: [],
  addUserToRole: {
    user: [],
    role: [],
  },
  assignProjectToRole: null,
  addProjectLogMessage: '',
};
