import { Notification } from '@contractool/schema';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { Page } from 'components/layout/Page';
import { IconCircle } from 'components/IconCircle';
import { useInfiniteRequest } from 'hooks/useInfiniteRequest';
import { http } from 'utils/http';
import { byNewestUp } from 'utils/sort';
import { translate } from 'utils/translations';

export function NotificationsPage() {
  const [notifications, dispatch, { hasMore, loadMore }] = useNotifications();

  return (
    <InfiniteScroll
      pageStart={1}
      initialLoad={false}
      loadMore={loadMore}
      loader={
        // TODO: design for loading ...
        <div className="w-full text-center text-gray-600 mb-12" key="loader">
          {translate('Loading')} ...
        </div>
      }
      useWindow={false}
      threshold={10}
      hasMore={hasMore}
    >
      <Page heading={translate('Notifications')}>
        <div className="-mx-6">
          <table className="table">
            <tbody>
              {notifications
                .sort((a: Notification, b: Notification) =>
                  byNewestUp(a.created_at, b.created_at),
                )
                .map((notification) => (
                  <tr
                    key={notification.id}
                    onClick={() => {
                      http
                        .post<Notification>(notification.read_url)
                        .then(({ data }) =>
                          dispatch({
                            type: 'MARK_AS_READ',
                            payload: data,
                          }),
                        );
                      window.location.href = notification.url;
                    }}
                    className={`cursor-pointer text-gray-600 ${
                      !notification.read && 'bg-blue-050'
                    } hover:bg-blue-000 hover:border-blue-000`}
                  >
                    <td className="w-6">
                      <IconCircle
                        name="alarm"
                        circleSize={10}
                        iconSize={5}
                        color="orange"
                      ></IconCircle>
                    </td>

                    <td>
                      <div className="mb-2" data-cy-read={notification.read}>
                        {notification.message.title}
                      </div>
                      <div className="text-sm">{notification.created_ago}</div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Page>
    </InfiniteScroll>
  );
}

type Reducer = {
  state: Notification[];
  actions:
    | { type: 'LOAD'; payload: Notification[] }
    | { type: 'MARK_AS_READ'; payload: Notification };
};

function useNotifications(): [
  Notification[],
  React.Dispatch<Reducer['actions']>,
  { hasMore: boolean; loadMore: () => void },
] {
  const [notificationsResponse, { hasMore, loadMore }] =
    useInfiniteRequest<Notification[]>('/api/notifications');

  React.useEffect(() => {
    dispatch({ type: 'LOAD', payload: notificationsResponse });
  }, [notificationsResponse]);

  const [notifications, dispatch] = React.useReducer<
    (state: Reducer['state'], action: Reducer['actions']) => Notification[]
  >((state, action) => {
    switch (action.type) {
      case 'LOAD':
        return action.payload;

      case 'MARK_AS_READ':
        return [
          ...state.filter((n) => n.id !== action.payload.id),
          action.payload,
        ];

      default:
        return state;
    }
  }, []);

  return [notifications, dispatch, { hasMore, loadMore }];
}
