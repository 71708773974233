import * as React from 'react';
import ReactECharts, { EChartsOption } from 'echarts-for-react';

import { Context as ReportContext } from 'contexts/reports/tab/report';
import { TableBasic } from 'components/Table';

const wrapperStyle = {
  height: '100%',
  overflow: 'hidden',
};

const Chart: React.FC<{
  options: EChartsOption;
  onReportList: (data: any) => void;
}> = ({ options, onReportList }) => {
  const {
    config: { title },
  } = React.useContext(ReportContext);

  const eChartRef = React.useRef(null);
  const onChartReadyCallback = React.useCallback(() => {
    console.log('onChartReadyCallback');
  }, []);

  const onEvents = {
    click: onReportList,
  };

  const style = React.useMemo(
    () => ({
      height: '100%',
    }),
    [],
  );

  if (options?.columns && options?.data) {
    const columns = options?.columns.map((column: any) => {
      if (column?.accessor === 'numberofprojects') {
        return {
          ...column,
          width: 165,
        };
      }

      return {
        ...column,
        width: '100%',
      };
    });

    return <TableBasic stickyHeader columns={columns} data={options?.data} />;
  }

  if ('value' in options) {
    // render simple box
    return (
      <div
        className="cursor-pointer hover:text-blue-500"
        onClick={() =>
          onReportList({
            data: {
              name: 'all',
            },
          })
        }
      >
        <div className="text-xl mt-5 mr-5">{options.value}</div>
        <div className="mt-3 text-sm">{title}</div>
      </div>
    );
  }

  if (Array.isArray(options)) {
    // render number cards
    return (
      <div className="flex-auto flex flex-wrap">
        {options.map((option: any, idx: number) => (
          <div
            className="grow border m-1 p-6 rounded-lg cursor-pointer hover:text-blue-500"
            key={option.value.toString() + idx}
            style={{ minHeight: 142 }}
            onClick={() =>
              onReportList({
                data: {
                  name: option.label,
                },
              })
            }
          >
            <div className="text-xl mr-5 ">{option.value}</div>
            <div className="mt-3 text-sm" style={{ width: 80 }}>
              {option.label}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div style={wrapperStyle}>
      <ReactECharts
        ref={eChartRef}
        option={options}
        notMerge={true}
        theme="default"
        onChartReady={onChartReadyCallback}
        onEvents={onEvents}
        style={style}
      />
    </div>
  );
};

const memoizedChart = React.memo(Chart);

export default memoizedChart;
