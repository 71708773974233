import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import { Icon } from 'components/Icon';

import { useToasts } from 'hooks';

import { sign } from 'utils/auth';
import { upload } from 'utils/upload';
import { translate } from 'utils/translations';

import 'react-circular-progressbar/dist/styles.css';

type Props = {
  additionalData?: {
    categoryId: number;
  };
  hasOnlineTemplate?: boolean;
  singleFile?: boolean;
  onCompleted?: (response: { path: string; name: string }) => void;
  onError?: (error: any) => void;
};

const UploadFile: React.FC<Props> = ({
  additionalData,
  hasOnlineTemplate,
  singleFile,
  onCompleted,
  onError,
}) => {
  const { success, error: errorToast } = useToasts();
  const [state, setState] = useState({
    isUploading: false,
    progress: 0,
  });

  const startUpload = async (file: File) => {
    setState((prevState) => ({
      ...prevState,
      isUploading: true,
    }));

    try {
      const response = await upload(file, {
        onUploadProgress: (progress) =>
          setState((prevState) => ({
            ...prevState,
            progress,
          })),
      });

      if (onCompleted) {
        await onCompleted(response);
      }

      setState((prevState) => ({
        ...prevState,
        isUploading: false,
      }));

      success(translate('File has been uploaded'));
    } catch (error) {
      errorToast(translate(`Something went wrong. ${error}`));

      if (onError) {
        onError(error);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      if (singleFile) {
        return startUpload(files[0]);
      }

      return files.forEach((file) => startUpload(file));
    },
  });

  return (
    <>
      {state.isUploading ? (
        <div className="w-full flex justify-center">
          <CircularProgressbar
            value={state.progress}
            text={`${state.progress}%`}
            circleRatio={0.75}
            styles={buildStyles({
              rotation: 1 / 2 + 1 / 8,
              strokeLinecap: 'butt',
              pathColor: '#2979ff',
              trailColor: '#e9ecef',
              textSize: 22,
              textColor: '#2979ff',
            })}
            className="w-40 h-40"
          />
        </div>
      ) : (
        <>
          {hasOnlineTemplate && (
            <div>
              <a
                href={sign(
                  `/document/new-library/${additionalData?.categoryId}`,
                )}
                className="flex items-center mt-5 text-blue-700"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="add" size={5} />
                <span className="ml-2">
                  {translate('Create new template online')}
                </span>
              </a>
              <div className="my-5">{translate('Or')}</div>
            </div>
          )}
          <div {...getRootProps()} className={'focus:outline-none mt-5'}>
            <input {...getInputProps()} type="file" className="hidden" />
            <div className="bg-gray-000 text-gray-600 p-7 rounded-xl flex items-center justify-center">
              <Icon name="cloud_upload" size={6} />
              <div className="pl-3">
                {translate('Drop file here or')}{' '}
                <span className="text-blue-700 cursor-pointer focus:outline-none">
                  {translate('browse it')}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UploadFile;
