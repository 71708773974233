import React, {useContext} from 'react';
import {Tab, Tabs} from 'components/Tabs';
import ProjectFieldList from './ProjectFieldList';
import {Tab as TabInterface} from '../../../interfaces';
import ProjectContext from 'views/projects/ProjectContext';

const MemberModalTabs: React.FC<{tabs: TabInterface[], readOnly?: boolean }> = ({tabs, readOnly}) => {
    const {resource: project} = useContext(ProjectContext);

    return (
        <Tabs className="h-27">
            {tabs
                .filter((tab: TabInterface) => {
                    //TODO react. This is hack for SWP, to display new brd fields only for new projects
                    if (
                        project.fields.brd_version !== '2' &&
                        tab.name === 'Basic Contracting Deal Info v2'
                    ) {
                        return false;
                    }
                    if (
                        project.fields.brd_version === '2' &&
                        tab.name !== 'Basic Contracting Deal Info v2'
                    ) {
                        return false;
                    }

                    return true;
                })
                .map((tab: TabInterface, index: number) => (
                    <Tab key={index} name={tab.name} heading={tab.name}>
                        <ProjectFieldList fields={tab.fields} readOnly={readOnly} />
                    </Tab>
                ))}
        </Tabs>
    );
};

const PureMemberModalTabs = React.memo(MemberModalTabs);

export default PureMemberModalTabs;
