import {FilterCondition, FilterTemplate} from '@contractool/schema';

export type Action =
    | {type: 'LOAD_CONDITIONS'; conditions?: FilterCondition[]}
    | {type: 'ADD_CONDITION'}
    | {type: 'CLEAR'}
    | {type: 'REMOVE_CONDITION'; id: number}
    | {
          type: 'UPDATE_CONDITION_NAME';
          id: number;
          filterTemplates: FilterTemplate[];
          name: string;
      }
    | {type: 'UPDATE_CONDITION_OPERATION'; id: number; operation: string}
    | {type: 'UPDATE_CONDITION_VALUES'; id: number; value: string | number};

export const filterReducer = (state: FilterCondition[], action: Action): FilterCondition[] => {
    switch (action.type) {
        case 'LOAD_CONDITIONS': {
            if (action.conditions) return [...action.conditions];

            return state;
        }
        case 'ADD_CONDITION': {
            return [
                ...state,
                {
                    id: state.length > 0 ? state[state.length - 1].id + 1 : 1,
                    operation: '',
                    subject: '',
                    value: [],
                },
            ];
        }
        case 'CLEAR': {
            return [{id: 1, operation: '', subject: '', value: []}];
        }
        case 'REMOVE_CONDITION': {
            return state.filter((condition: FilterCondition) => condition.id !== action.id);
        }
        case 'UPDATE_CONDITION_NAME': {
            return state.map((condition: FilterCondition) => {
                if (condition.id !== action.id || action.filterTemplates === undefined)
                    return condition;

                return {
                    ...condition,
                    subject: action.name,
                    value: [],
                    operation: '',
                };
            });
        }
        case 'UPDATE_CONDITION_OPERATION': {
            return state.map((condition: FilterCondition) => {
                if (condition.id !== action.id) return condition;

                return {
                    ...condition,
                    operation: action.operation,
                };
            });
        }

        case 'UPDATE_CONDITION_VALUES': {
            return state.map((condition: FilterCondition) => {
                if (condition.id !== action.id) return condition;

                return {
                    ...condition,
                    value: action.value,
                };
            });
        }
        default:
            return state;
    }
};
