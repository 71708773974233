import { FieldTemplate } from "@contractool/schema";
import React, { FC, useCallback, useRef } from "react";
import { Popover } from "react-tiny-popover";
import _ from "lodash";

import { Icon } from "components/Icon";
import { getOptions } from "utils/form";
import { Dropdown, Option } from "components/Dropdown";
import { Field } from "components/Field";
import { MultiselectDropdown } from "components/MultiselectDropdown";
import FetchSelect from "components/select/FetchSelect";
import { useToggle } from "hooks";

export const ReportFilter: FC<{
    reportSlug: string;
    filterSettings: FieldTemplate[];
    selectedFilters: any;
    onFilter: (filter: any) => void;
    usersAsOptions: Option<any>[];
    suppliersAsOptions: Option<any>[];
}> = ({ reportSlug, onFilter, filterSettings = [], selectedFilters = {} }) => {
    const [filterVisible, filterVisibilityToggle] = useToggle(false);
    const omitedFilters = _.omit(selectedFilters, "year", "month", "quarter", "dateFrom", "dateTo");

    // visibility manage section
    const shouldToggle = useRef(false);
    const shouldKeep = useRef(false);

    React.useEffect(() => {
        const toggleOrKeepOrHide = () => {
            if (shouldToggle.current) {
                shouldToggle.current = false;
                filterVisibilityToggle.invert();
            } else if (shouldKeep.current) {
                shouldKeep.current = false;
            } else {
                filterVisibilityToggle.off();
            }
        };
        document.addEventListener("click", toggleOrKeepOrHide);

        return () => {
            document.removeEventListener("click", toggleOrKeepOrHide);
        };
        // eslint-disable-next-line
    }, []);

    const handlePanelClick = useCallback(() => {
        shouldKeep.current = true;
    }, []);

    const handleToggleClick = useCallback(() => {
        shouldToggle.current = true;
    }, []);
    // visibility manage section

    console.log("filterSettings", filterSettings);

    return (
        <Popover
            isOpen={filterVisible}
            positions={["bottom", "left"]} // preferred positions by priority
            content={
                <div
                    className="p-5 rounded-xl shadow-lg bg-white"
                    style={{ width: "300px" }}
                    onClick={handlePanelClick}
                >
                    <div
                        className="cursor-pointer text-gray-500 border-b border-gray-300 mb-3 pt-3 hover:bg-gray-000"
                        onClick={() => {
                            onFilter({});
                            filterVisibilityToggle.off();
                        }}
                    >
                        <div className="m-3 mt-0 text-center">clear filters</div>
                    </div>
                    {filterSettings.map((filterSetting: any, index: number) => {
                        let key = `filter-${reportSlug}${index}`;
                        let input = <div>{filterSetting.placeholder}</div>;
                        switch (filterSetting.type) {
                            case "SELECT":
                                if (filterSetting.values[0] !== "") {
                                    filterSetting.values.unshift("");
                                }
                                input = (
                                    <Dropdown
                                        name={filterSetting.name}
                                        value={selectedFilters[filterSetting.name]}
                                        onChange={(value) => {
                                            let newFilters = {
                                                ...selectedFilters,
                                                [filterSetting.name]: value
                                            };
                                            onFilter(newFilters);
                                        }}
                                        options={getOptions(filterSetting.values)}
                                    />
                                );
                                break;
                            case "TYPEAHEAD":
                                let choices = getOptions(filterSetting.options);
                                console.log("TYPEAHEADchoices", filterSetting);
                                input = (
                                    <MultiselectDropdown
                                        name={filterSetting.name}
                                        values={selectedFilters[filterSetting.name]}
                                        placeholder="Quarter"
                                        options={choices}
                                        onChange={(selections) => {
                                            let newFilters = {
                                                ...selectedFilters,
                                                [filterSetting.name]: selections.map(
                                                    (selection) => selection.value
                                                )
                                            };
                                            onFilter(newFilters);
                                        }}
                                    />
                                );
                                break;
                            case "TYPEAHEAD_API":
                                let value = selectedFilters[filterSetting.name]
                                    ? Number(selectedFilters[filterSetting.name][0])
                                    : null;
                                input = (
                                    <FetchSelect
                                        name={filterSetting.name}
                                        value={value}
                                        onChange={(value: any) => {
                                            let newFilters = {
                                                ...selectedFilters,
                                                [filterSetting.name]: [value]
                                            };
                                            onFilter(newFilters);
                                        }}
                                        api={filterSetting.url}
                                        apiParams={
                                            filterSetting.apiParams ? filterSetting.apiParams : {}
                                        }
                                    />
                                );
                                break;
                        }

                        return (
                            <Field
                                key={key}
                                className="mb-5"
                                name={filterSetting.name}
                                label={filterSetting.placeholder}
                            >
                                {input}
                            </Field>
                        );
                    })}
                </div>
            }
        >
            <div
                onClick={handleToggleClick}
                data-cy={`filter-toggle-${reportSlug}`}
                className={`cursor-pointer p-2 flex items-center rounded-xl m-3 ${
                    Object.keys(omitedFilters).length > 0
                        ? "bg-gray-000 text-blue-500"
                        : "text-gray-500"
                }`}
            >
                <Icon name="filter_list" size={5} className={"mr-3"} />
                {Object.keys(omitedFilters).length > 0
                    ? Object.keys(omitedFilters).length
                    : "Filter"}
            </div>
        </Popover>
    );
};
