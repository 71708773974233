import React from "react";
import { translate } from "utils/translations";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { Link } from "react-router-dom";

export function WelcomePopup({ onClose }: { onClose: (value: string) => void }) {
    const continueHandler = () => {
        onClose("Y");
    };

    return (
        <Modal heading={translate("Hello, welcome to Cequence.")} onClose={continueHandler}>
            <p>
                You can also review the detailed training video on how to use Cequence or short tutorial on how to enter
                a Cost Saving in the News section or"
                <Link
                    to="/news"
                    className="text-blue-700 hover:underline focus:text-blue-700 focus:outline-none"
                >
                    {" "}
                    by clicking here...
                </Link>
                <br />
                <br />
                <br />
            </p>
            <p>
                <Button onClick={continueHandler}>Continue</Button>
            </p>
        </Modal>
    );
}
