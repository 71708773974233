import * as React from 'react';

import {FormContext} from 'components/Form';

const useInteractions = () => {
    const {values} = React.useContext(FormContext);

    const groupByFieldVisible = values.chartType !== 'simple-box';

    return React.useMemo(
        () => ({
            groupByFieldVisible,
        }),
        [groupByFieldVisible],
    );
};

export default useInteractions;
