import React from 'react';
import { Page } from 'components/layout/Page';
import { translate } from 'utils/translations';

export function Guest() {
  return (
    <Page heading={translate('Welcome to Cequence!')}>
      <p>
        Your user access request has been registered. Once the system admin adds
        you to the relevant user group you can enjoy full Cequence experience.
        <br />
        <br />
        <br />
      </p>
      <p>Thank you for your patience.</p>
    </Page>
  );
}
