import React, { FC } from "react";
import { Project } from "../../../../../_common/schema";
import { Link } from "react-router-dom";
import { Modal } from "components/Modal";
import { Route, Switch, useHistory } from "react-router";
import { ProjectDetailsEdit } from "views/projects/ProjectDetailsEdit";

export const ImportLog: FC<{ project: Project }> = ({ project }) => {
    const history = useHistory();
    const modalUrl = `/projects/${project.id}/import`;

    return (
        <div>
            <Link to={modalUrl}>Show imported data</Link>
            <Switch>
                <Route
                    path={modalUrl}
                    render={() => (
                        <Modal onClose={() => {
                            history.push(`/projects/${project.id}`);
                        }}>
                            <div className="font-bold">Problems while importing</div>
                            {project.import_log.problems.map((line: string) => {
                                return <div className="border-b">{line}</div>;
                            })}
                            <div className="font-bold mt-4">Raw data:</div>
                            {project.import_log.raw.map((line: string) => {
                                return <div className="border-b">{line}</div>;
                            })}
                        </Modal>
                    )}
                />
            </Switch>
        </div>
    );
};
