import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Dropdown } from 'components/Dropdown';

import { translate } from 'utils/translations';

import { AppContext } from 'contexts';
import { useQueryParams } from 'hooks/useParams';

const WORKFLOW_PARAM = 'workflow';
const Workflow: React.FC<{
  onChange?: (value: string) => void;
}> = ({ onChange }) => {
  const { config, setAssessmentWorkflow, assessmentWorkflow } =
    useContext(AppContext);

  const [_params, { setParam }] = useQueryParams({ workflow: 'default' });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const workflows =
    config?.workflows
      .filter((workflow: any) => workflow?.settings.visible_in_dropdown)
      .map((workflow: any) => ({
        label: workflow?.label,
        value: workflow?.key,
      })) || [];

  const handleChangeWorkflow = (value: string) => {
    setAssessmentWorkflow(value);
    setParam('workflow', value);
    onChange && onChange(value);
  };

  useEffect(() => {
    const value = searchParams.get(WORKFLOW_PARAM);
    if (value) {
      setAssessmentWorkflow(value);
    }
  });

  return (
    <>
      {workflows.length > 1 && (
        <div className="flex" style={{ alignItems: 'baseline' }}>
          <Dropdown
            name="workflow-selector"
            data-test-id="workflow-selector"
            value={assessmentWorkflow}
            onChange={handleChangeWorkflow}
            options={workflows}
            placeholder={translate('Select an option')}
          />
        </div>
      )}
    </>
  );
};

export default Workflow;
