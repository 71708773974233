import React from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Cell } from 'react-table';

import { TableWithPagination } from 'components/Table';
import { Menu, MenuItem } from 'components/Menu';
import { Icon } from 'components/Icon';

import Condition from './Label/Condition';
import Result from './Label/Result';

import { FilterTemplate } from '@contractool/schema';

import { translate } from 'utils/translations';
import {
  AutomaticResult,
  Condition as ConditionType,
  SettingsForm,
} from 'types/output/setting';
import { URL_EDIT_RULE } from '@constants/settings';

type Props = {
  rules: SettingsForm[];
  templates: FilterTemplate[];
  onDelete: (id: number | undefined) => void;
};

const ApprovalRulesList: React.FC<Props> = ({ rules, templates, onDelete }) => {
  const history = useHistory();

  const columns = [
    {
      Header: translate('Name'),
      accessor: 'name',
    },
    {
      Header: translate('Condition'),
      accessor: 'conditions',
      Cell: ({ value }: { value: ConditionType[] }) =>
        value
          .filter(
            (condition) =>
              // Exceptions: Display only
              condition.subject == 'projects.state' ||
              condition.subject === 'projects.fields->region' ||
              condition.subject === 'projects.fields->cost_center_location' ||
              condition.subject === 'projects.fields->commodity_lone' ||
              condition.subject === 'projects.fields->commodity_ltwo' ||
              condition.subject === 'projects.fields->commodity_lthree' ||
              condition.subject === 'projects.fields->approved_budget',
          )
          .map((condition) => (
            <Condition condition={condition} templates={templates} />
          )),
    },
    {
      Header: translate('Result'),
      accessor: 'results.automatic',
      Cell: ({ value }: { value: AutomaticResult[] }) =>
        value.map((automatic) => <Result automatic={automatic} />),
    },
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ value }: { value: number }) => (
        <Menu
          handle={({ toggleMenu }) => (
            <div
              onClick={toggleMenu}
              className="flex items-center cursor-pointer"
            >
              <Icon
                name="more_horiz"
                className="text-gray-600 cursor-pointer"
                size={6}
              />
            </div>
          )}
        >
          <MenuItem icon="remove_circle" onClick={() => onDelete(value)}>
            {translate('Delete')}
          </MenuItem>
        </Menu>
      ),
    },
  ];

  const openModal = (id: number | undefined) =>
    history.push(
      generatePath(URL_EDIT_RULE, {
        id: String(id),
      }),
    );

  const handleClick = ({ row, column }: Cell<object, any>) => {
    if (column.id === 'id') return;

    const original = row.original as SettingsForm;
    openModal(original.id);
  };

  return (
    <TableWithPagination columns={columns} data={rules} onClick={handleClick} />
  );
};

export default ApprovalRulesList;
