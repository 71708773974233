import React, { useEffect, useState } from 'react';

import { Modal as ComponentModal } from 'components/Modal';
import { Button } from 'components/Button';
import Search from 'components/Search/Search';

import { FilterCondition, FilterTemplate } from '@contractool/schema';

import {
  getProperties,
  MapMenu,
  parseFilters,
} from 'views/projects/ConditionsMap';

import { translate } from 'utils/translations';

type Props = {
  setOpenModal: (value: boolean) => void;
  editCondition: (index: number, value: any) => void;
  templates: FilterTemplate[];
  index: number;
  condition?: FilterCondition;
};

const Modal: React.FC<Props> = ({
  setOpenModal,
  editCondition,
  condition,
  templates,
  index,
}) => {
  let [search, setSearch] = useState('');
  //ParsedFilter are all the filters split up to basic fields, and the rest.
  let [parsedFilters, setParsedFilters] = useState(
    parseFilters({ groups: templates, search }),
  );

  useEffect(() => {
    setParsedFilters(parseFilters({ groups: templates, search }));
  }, [search]);

  const fields = getProperties(parsedFilters);

  const updateProperty = (value: any) => {
    editCondition(index, {
      ...condition,
      subject: value,
      operation: '',
      value: [],
    });
    setOpenModal(false);
  };

  const searchField = (phrase: string) => {
    console.log('phrase', phrase);
    setSearch(phrase);
  };

  return (
    <ComponentModal
      heading={translate('Select a property')}
      onClose={() => {
        setOpenModal(false);
      }}
      size="-"
      corner={
        <Search
          placeholder={translate('Filter properties...')}
          value={search}
          onChange={searchField}
        />
      }
    >
      <div className="flex items-stretch h-96">
        <div
          className="flex items-stretch h-96 overflow-y-auto overflow-x-hidden"
          id="workflow-properties-list"
        >
          <div className="border-r">
            <MapMenu
              label={translate('Basic')}
              selected={condition?.subject ?? ''}
              items={
                parsedFilters && parsedFilters.basic ? parsedFilters.basic : []
              }
              selectValue={updateProperty}
            />
          </div>
          <div className="border-r">
            <MapMenu
              label={translate('Fields')}
              selected={condition?.subject ?? ''}
              items={fields ? fields.fields ?? [] : []}
              selectValue={updateProperty}
            />
          </div>
          <div className="border-r">
            <MapMenu
              label={translate('Roles')}
              selected={condition?.subject ?? ''}
              items={fields ? fields.roles ?? [] : []}
              selectValue={updateProperty}
            />
          </div>
          <div className="border-r">
            <MapMenu
              label={translate('Dates')}
              selected={condition?.subject ?? ''}
              items={fields ? fields.dateFields ?? [] : []}
              selectValue={updateProperty}
            />
          </div>
        </div>
      </div>
      <ComponentModal.Footer className="flex justify-between">
        <Button
          color="white"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          {translate('Close')}
        </Button>
      </ComponentModal.Footer>
    </ComponentModal>
  );
};

export default Modal;
