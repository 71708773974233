import React from "react";
import { FieldTemplate } from "@contractool/schema";
import { GuardContext } from "contexts";
import { CustomField } from "components";
import { isAllowedForUser } from "utils/form";

const ProjectFieldList: React.FC<{ fields: FieldTemplate[], readOnly?: boolean }> = ({ fields, readOnly }) => {
    const { user } = React.useContext(GuardContext);

    const projectFields = fields.filter((field: FieldTemplate) => {
        return (
            isAllowedForUser(field, user) && !field.hidden
        );
    });

    return (
        <>
            {projectFields.map((field: FieldTemplate) => (
                <CustomField key={`${field.name}`} field={field} option="" className="mt-6" readOnly={readOnly} />
            ))}
        </>
    );
};

const PureProjectFieldList = React.memo(ProjectFieldList);

export default PureProjectFieldList;
