import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Field, Formik } from 'formik';

import { Modal as ComponentModal } from 'components/Modal';
import { Button } from 'components/Button';
import { Tab, Tabs } from 'components/Tabs';
import { Menu, MenuItem } from 'components/Menu';
import { Field as FieldWrapper } from 'components/Field';
import { Icon } from 'components/Icon';
import { Message } from '../../../components/Message';

import { FilterTemplate } from '@contractool/schema';

import When from './When';

import AskUser from './Tab/AskUser';
import Automatic from './Tab/Automatic';

import { useToasts } from 'hooks';
import { translate } from 'utils/translations';
import { Tab as TabType, SettingsForm } from 'types/output/setting';
import { schema } from 'validation/settings';
import { condition, askUser, automatic, URL_RULE } from '@constants/settings';
import { sanitizePayload } from 'utils/settings';
import { TextInput } from 'components/TextInput';

import { AppContext } from 'contexts';

type Props = {
  onSubmit: (payload: any, id?: number | null) => void;
  onDelete?: (id: number | undefined) => void;
  rules?: SettingsForm[];
  isEditMode?: boolean;
  templates: FilterTemplate[];
};

const Modal: React.FC<Props> = ({
  onSubmit,
  onDelete,
  rules,
  isEditMode,
  templates,
}) => {
  const { assessmentWorkflow } = useContext(AppContext);

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { success } = useToasts();

  const rule = rules?.find((rule) => rule?.id === Number(id));
  const notFound = rule === undefined && isEditMode ? true : false;

  const closeModal = () => history.push(URL_RULE);
  const handleDelete = () => {
    onDelete && onDelete(rule?.id);
    success(translate('The rule has been deleted.'), {
      position: 'top-center',
    });
    closeModal();
  };

  return (
    <ComponentModal
      heading={isEditMode ? translate('Edit Rule') : translate('New Rule')}
      onClose={closeModal}
      corner={
        isEditMode && (
          <Menu
            handle={({ toggleMenu }) => (
              <div
                onClick={toggleMenu}
                className="flex items-center cursor-pointer"
              >
                <Icon
                  name="more_horiz"
                  className="text-gray-600 cursor-pointer"
                  size={6}
                />
              </div>
            )}
          >
            <MenuItem icon="remove_circle" onClick={handleDelete}>
              {translate('Delete')}
            </MenuItem>
          </Menu>
        )
      }
      size="large"
    >
      <Formik<SettingsForm>
        initialValues={{
          id: rule?.id,
          name: rule?.name || '',
          workflow: rule?.workflow || assessmentWorkflow,
          conditions: rule?.conditions || [condition],
          results: {
            tab: rule?.results?.tab || TabType.ASK_USER,
            askUser: isEmpty(rule?.results?.askUser)
              ? [askUser]
              : rule?.results?.askUser || [askUser],
            automatic: isEmpty(rule?.results?.automatic)
              ? [automatic]
              : rule?.results?.automatic || [automatic],
          },
        }}
        validationSchema={schema}
        onSubmit={async (values: SettingsForm) => {
          const payload = sanitizePayload(values);

          onSubmit(payload, values?.id);
          closeModal();
          success(translate('Successful created'));
        }}
      >
        {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
          <Form>
            {notFound ? (
              <div className="mb-8">
                <Message.Error>
                  {translate(`This rule ID doesn't exist.`)}
                </Message.Error>
              </div>
            ) : (
              <>
                <Field name="name">
                  {({ field, meta }: any) => (
                    <FieldWrapper
                      {...field}
                      className="mb-12"
                      errorMessage={meta.touched && meta.error}
                    >
                      <TextInput
                        {...field}
                        onChange={(value) => {
                          setFieldValue(field.name, value);
                        }}
                        placeholder={translate('Name of rule')}
                        hasError={meta.touched && meta.error}
                      />
                    </FieldWrapper>
                  )}
                </Field>
                <h2 className="text-gray-700 text-lg">{translate('When')}</h2>
                <When templates={templates} />
                {values.conditions.length > 0 && (
                  <>
                    <h2 className="text-gray-700 text-lg">
                      {translate('Then')}
                    </h2>
                    <Tabs
                      onSelect={(name: string) =>
                        setFieldValue('results.tab', name)
                      }
                      selected={values.results.tab}
                    >
                      <Tab
                        name={TabType.ASK_USER}
                        heading={translate('Ask user to')}
                        className="py-8"
                      >
                        <AskUser />
                      </Tab>
                      <Tab
                        name={TabType.AUTOMATIC}
                        heading={translate('Do automatic action')}
                        className="py-8"
                      >
                        <Automatic />
                      </Tab>
                    </Tabs>
                  </>
                )}
              </>
            )}
            <ComponentModal.Footer className="flex justify-between">
              <Button color="white" onClick={closeModal}>
                {translate('Cancel')}
              </Button>
              <Button
                onClick={() => handleSubmit()}
                disabled={notFound || isSubmitting}
              >
                {translate('Save')}
              </Button>
            </ComponentModal.Footer>
          </Form>
        )}
      </Formik>
    </ComponentModal>
  );
};

export default Modal;
