import React, { useState } from "react";
import { Icon } from "../Icon";
import { sign } from "utils/auth";
import { Modal } from "components/Modal";
import "./PreviewModal.css";
export function PreviewIcon({ fileUrl, modal }: { fileUrl: string; modal: boolean }) {
    let [modalOpen, setModalOpen] = useState(false);

    if (!modal) {
        return (
            <a href={sign(fileUrl)} target="_blank" rel="noopener noreferrer">
                <Icon name="remove_red_eye" size={6} className="ml-3 text-gray-500" />
            </a>
        );
    }


    return <>
        {modalOpen && <Modal
            corner={
                <span className="text-blue-700 cursor-pointer" onClick={() => {
                    setModalOpen(false);
                }}>Close</span>
            }
            onClose={() => {
            }
            }
            size="w-full w-max-1400"
        >
            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}&embedded=true`}
                    style={{ width: "100%", height: "95vh" }} frameBorder="0"></iframe>
        </Modal>}
        <span onClick={() => {
            setModalOpen(true);
        }}
              className="flex">
            <Icon name="remove_red_eye" size={6} className="ml-3 text-gray-500 cursor-pointer" />
        </span></>;
}
