import React, { FC, useContext } from 'react';
import { Button } from 'components/Button';
import { Form } from 'components/Form';
import { http } from 'utils/http';
import { FieldTemplate, User, UserGroup } from '@contractool/schema';
import { Tab, RouteTabs } from 'components/Tabs';
import { useToasts } from 'hooks';
import { Modal } from 'components/Modal';
import { useParams, useHistory } from 'react-router-dom';
import { timezones } from 'utils/timezones';
import { Field } from 'components/Field';
import { Switch } from 'components/Switch';
import { useRequest } from 'utils/hooks/useRequest';
import { translate } from 'utils/translations';
import { Menu, MenuItem } from 'components/Menu';
import { UserStateLabel } from './UserStateLabel';
import { AvatarUpload } from 'components/Avatar';
import { Confirmation } from 'components/Confirmation';
import { AppContext, AuthContext } from 'contexts';
import CustomField from 'components/CustomField';
import { TeamParams } from './TeamPage';
import { useQueryParams } from 'hooks/useParams';
import { toQueryString } from 'utils/url';

export function TeamEdit({ onUpdate }: { onUpdate: () => void }) {
  const history = useHistory();
  const { success } = useToasts();
  const { id } = useParams<{ id?: string; params?: string }>();
  const [user, { refresh }] = useRequest<User | undefined>(
    `/api/users/${id}`,
    undefined,
  );
  const [params] = useQueryParams<TeamParams>({
    phrase: '',
    licence: '',
    page: 1,
    per_page: 30,
    groups_in: [],
    state: '',
  });

  const close = () => {
    history.replace({
      pathname: '/team',
      search: toQueryString(params),
    });
  };

  if (typeof user === 'undefined') {
    return null;
  }

  return (
    <Modal
      heading={translate('User detail')}
      compact={true}
      onClose={close}
      contentClassName="h-149"
      corner={
        <StateMenu
          user={user}
          onRefresh={() => {
            refresh();
            onUpdate();
          }}
          onClose={close}
        />
      }
    >
      <div className="flex items-center mb-11">
        <div>
          <AvatarUpload
            user={user}
            onUpload={() => {
              refresh();
              success(
                `${translate(":name's avatar was successfully changed", {
                  name: user.name,
                })}`,
              );
            }}
            className="w-32 h-32"
          />
        </div>

        <div className="ml-11">
          <div className="flex items-center">
            <h2 className="text-xl inline">{user.name}</h2>
            <UserStateLabel user={user} className="mx-4" />
          </div>
          <p className="text-gray-600">{user.licence}</p>
        </div>
      </div>

      <RouteTabs match={`/team/${user.id}/edit/:tab`}>
        <Tab name="general" heading={translate('General')} className="pt-8">
          <GeneralTab
            user={user}
            onSuccess={() => {
              success(
                `${translate(":name's details were successfully updated", {
                  name: user.name,
                })}`,
              );
              onUpdate();
              close();
            }}
            onClose={close}
          />
        </Tab>
        <Tab name="password" heading={translate('Password')} className="pt-8">
          <PasswordTab
            user={user}
            onSuccess={() => {
              success(
                `${translate(":name's password was successfully updated", {
                  name: user.name,
                })}`,
              );
              onUpdate();
              close();
            }}
            onClose={() => close()}
          />
        </Tab>
        <Tab name="groups" heading={translate('Groups')} className="pt-8">
          <GroupsTab
            user={user}
            onSuccess={() => {
              success(
                `${translate(":name's groups were successfully updated", {
                  name: user.name,
                })}`,
              );
              onUpdate();
              close();
            }}
            onClose={close}
          />
        </Tab>
      </RouteTabs>
    </Modal>
  );
}

const GeneralTab: FC<{
  user: User;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ user, onSuccess, onClose }) => {
  const { config } = useContext(AppContext);

  return (
    <Form
      initialValues={user}
      onSubmit={(values) => http.put<User>(user.url, values)}
      onSuccess={onSuccess}
    >
      <Form.TextInput
        name="name"
        label={translate('Name')}
        className="mb-6"
        autoFocus={true}
      />
      <Form.TextInput
        name="email"
        label={translate('Email')}
        className="mb-6"
      />
      <Form.Dropdown
        name="timezone"
        label={translate('Timezone')}
        options={timezones}
        autocomplete
      />
      {config.user_fields.map((field: FieldTemplate, index: number) => {
        return (
          <CustomField
            key={field.name + index}
            field={field}
            option="fields."
            className="mb-6"
          />
        );
      })}

      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Cancel')}
        </Button>

        <Form.Submit>{translate('Save Changes')}</Form.Submit>
      </Modal.Footer>
    </Form>
  );
};

const PasswordTab: FC<{
  user: User;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ user, onSuccess, onClose }) => {
  return (
    <Form
      initialValues={{ password: '', password_confirmation: '' }}
      onSubmit={(values) => http.put(user.password_url, values)}
      onSuccess={onSuccess}
    >
      <Form.TextInput
        name="password"
        type="password"
        label={translate('New password')}
        className="mb-6"
        autoFocus={true}
      />
      <Form.TextInput
        name="password_confirmation"
        type="password"
        label={translate('Confirm new password')}
      />

      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Cancel')}
        </Button>

        <Form.Submit>{translate('Save Changes')}</Form.Submit>
      </Modal.Footer>
    </Form>
  );
};

const GroupsTab: FC<{
  user: User;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ user, onSuccess, onClose }) => {
  const { config } = useContext(AppContext);
  const user_groups: UserGroup[] = config.user_groups;

  //TODO roles vypocitavat a zobrazit licenciu na zaklade zaskrtnutych grup
  return (
    <Form
      initialValues={{ groups: user.groups }}
      onSubmit={(values) => http.put(user.roles_url, values)}
      onSuccess={onSuccess}
    >
      <Field name="groups" label={translate('Groups')}>
        <Form.Context>
          {({ values, handleChange }) => {
            return (
              <Switch.Multiple
                name="groups"
                value={values.groups}
                items={user_groups}
                toKey={(group) => group.key}
                onChange={(values) => handleChange('groups', values)}
                className="flex flex-wrap py-4"
              >
                {(group, Switch) => (
                  <div key={group.key} className="w-1/2 p-2">
                    <Switch>{group.label}</Switch>
                  </div>
                )}
              </Switch.Multiple>
            );
          }}
        </Form.Context>
      </Field>

      <Modal.Footer className="flex justify-between">
        <Button color="white" onClick={onClose}>
          {translate('Cancel')}
        </Button>

        <Form.Submit>{translate('Save Changes')}</Form.Submit>
      </Modal.Footer>
    </Form>
  );
};

const StateMenu: FC<{
  user: User;
  onRefresh: () => void;
  onClose: () => void;
}> = ({ user, onRefresh, onClose }) => {
  const { success } = useToasts();
  const { login, user: sessionUser } = useContext(AuthContext);
  const history = useHistory();

  return (
    <Menu handle="more_vert">
      {sessionUser?.groups.some((role) => role.key === 'admin') && (
        <Confirmation
          onConfirm={() => {
            http.post(`/api/auth/loginUser/${user.id}`).then((user: any) => {
              onRefresh();
              success(
                `${translate('You logged in as :name', {
                  name: user.name,
                })}.`,
              );
              login(user);
              history.push('/');
            });
          }}
          trigger={({ onClick }) => (
            <MenuItem
              icon="person"
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              {`${translate('Login as')} ${user.name}`}
            </MenuItem>
          )}
          heading={translate('Login as user')}
          buttonText={translate('Yes, switch')}
          color="yellow"
        >
          {translate('Are you sure you want to switch to user :name?', {
            name: user.name,
          })}
        </Confirmation>
      )}
      {user.state === 'active' && (
        <Confirmation
          onConfirm={() => {
            http.put(user.state_url, { state: 'deactivated' }).then(() => {
              onRefresh();
              success(
                `${translate('User :name was successfully deactivated', {
                  name: user.name,
                })}.`,
              );
            });
          }}
          trigger={({ onClick }) => (
            <MenuItem
              icon="lock"
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              {translate('Deactivate')}
            </MenuItem>
          )}
          heading={translate('Deactivate user')}
          buttonText={translate('Yes, deactivate')}
          color="yellow"
        >
          {translate('Are you sure you want to deactivate user :name?', {
            name: user.name,
          })}
        </Confirmation>
      )}
      {user.state === 'deactivated' && (
        <Confirmation
          onConfirm={() => {
            http.put(user.state_url, { state: 'active' }).then(() => {
              onRefresh();
              success(
                `${translate('User :name was successfully activated', {
                  name: user.name,
                })}.`,
              );
            });
          }}
          trigger={({ onClick }) => (
            <MenuItem
              icon="lock_open"
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              {translate('Activate')}
            </MenuItem>
          )}
          heading={translate('Activate user')}
          buttonText={translate('Yes, activate')}
          color="yellow"
        >
          {translate('Are you sure you want to activate user :name?', {
            name: user.name,
          })}
        </Confirmation>
      )}
      {user.state === 'deleted' ? (
        <Confirmation
          onConfirm={() => {
            http.put(user.state_url, { state: 'active' }).then(() => {
              onClose();
              onRefresh();
              success(
                `${translate('User :name was successfully undeleted', {
                  name: user.name,
                })}.`,
              );
            });
          }}
          trigger={({ onClick }) => (
            <MenuItem
              icon="settings_restore"
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              {translate('Undelete')}
            </MenuItem>
          )}
          heading={translate('Undelete user')}
          buttonText={translate('Yes, undelete')}
          color="red"
        >
          {translate('Are you sure you want to undelete user :name?', {
            name: user.name,
          })}
        </Confirmation>
      ) : (
        <Confirmation
          onConfirm={() => {
            http.put(user.state_url, { state: 'deleted' }).then(() => {
              onClose();
              onRefresh();
              success(
                `${translate('User :name was successfully deleted', {
                  name: user.name,
                })}.`,
              );
            });
          }}
          trigger={({ onClick }) => (
            <MenuItem
              icon="remove_circle"
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              {translate('Delete')}
            </MenuItem>
          )}
          heading={translate('Delete user')}
          buttonText={translate('Yes, delete')}
          color="red"
        >
          {translate('Are you sure you want to delete user :name?', {
            name: user.name,
          })}
        </Confirmation>
      )}
    </Menu>
  );
};
