import React from 'react';
import { Cell, Row } from 'react-table';

type Props = {
  row: Row;
  prepareRow: (row: Row) => void;
  onClick?: (e: Cell<object, any>) => void;
};

const TableRow: React.FC<Props> = ({ row, prepareRow, onClick }) => {
  prepareRow(row);
  const cursorClass = onClick ? 'cursor-pointer' : '';

  return (
    <tr
      {...row.getRowProps({
        className: 'hover:bg-gray-000',
      })}
    >
      {row.cells.map((cell) => (
        <td
          {...cell.getCellProps({
            style: {
              width: cell.column.width,
              minWidth: cell.column.minWidth,
              maxWidth: cell.column.maxWidth,
            },
            className: `${cursorClass}`,
          })}
          onClick={() => onClick && onClick(cell)}
        >
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
