import React, { FC } from 'react';
import {
  ActivityContainer,
  ActivityHeader,
  ActivityBody,
} from '../ActivityPanel';
import { Approval, LogFull, Project } from '@contractool/schema';
import { translate } from 'utils/translations';
import { Link } from 'react-router-dom';
import { Icon } from 'components/Icon';
import { sign } from 'utils/auth';
import ReactTooltip from 'react-tooltip';
import { formatDeadline } from '../Approvals/ApprovalsPanel';
import { useRequest } from 'hooks/useRequest';

export const ApprovalLog: FC<{
  desc: string;
  log: LogFull;
  project: Project;
}> = ({ desc, log, project }) => {
  const [approval] = useRequest<Approval | undefined>(
    `/api/projects/${project.id}/approvals/${log.meta.data}`,
    undefined,
    undefined,
  );

  return (
    <ActivityContainer>
      <ActivityHeader
        causer={log.causer}
        desc={desc}
        created_at={log.created_at}
      />
      <ActivityBody>
        {approval ? (
          <table className="w-full">
            <tbody className="">
              <tr>
                <td className="w-14 py-4 pr-4">
                  <Link
                    to={`/projects/${project.id}/approvals/${approval.id}/resolve`}
                  >
                    {(log.meta.type === 'APPROVAL_PENDING' ||
                      log.meta.type === 'APPROVAL_CREATED' ||
                      log.meta.type === 'APPROVAL_OPENED') && (
                      <div
                        className="w-10 h-10 flex items-center justify-center border border-gray-200 text-gray-500 bg-white rounded-full"
                        title={translate('Approve') + ' ' + approval.title}
                      >
                        <Icon name="done" size={5} />
                      </div>
                    )}

                    {log.meta.type === 'APPROVAL_APPROVED' && (
                      <div
                        className="w-10 h-10 flex items-center justify-center border border-teal-000 text-teal-600 bg-teal-000 rounded-full"
                        title={translate('Approved')}
                      >
                        <Icon name="done" size={5} />
                      </div>
                    )}

                    {log.meta.type === 'APPROVAL_DENIED' && (
                      <div
                        className="w-10 h-10 flex items-center justify-center border border-red-000 text-red-700 bg-red-000 rounded-full"
                        title={translate('Approval Denied')}
                      >
                        <Icon name="close" size={5} />
                      </div>
                    )}

                    {log.meta.type === 'APPROVAL_CANCELED' && (
                      <div
                        className="w-10 h-10 flex items-center justify-center border border-red-000 text-red-700 bg-red-000 rounded-full"
                        title={translate('Approval Canceled')}
                      >
                        <Icon name="remove_circle" size={5} />
                      </div>
                    )}
                  </Link>
                </td>
                <td className="py-4">
                  <Link
                    to={`/projects/${project.id}/approvals/${approval.id}/resolve`}
                  >
                    {approval.title}
                  </Link>
                </td>
                <td className="w-31 py-4 pl-10 pr-13">
                  <img
                    data-tip={approval.assignee.name}
                    src={sign(approval.assignee.avatar_url)}
                    alt={approval.assignee.name}
                    className="w-8 h-8 rounded-full"
                  />
                  <ReactTooltip />
                </td>
                <td className="w-24 py-4">
                  <Icon
                    name="date_range"
                    size={6}
                    className="mr-3 inline text-gray-500"
                  />
                  <span className="w-15 text-gray-600">
                    {formatDeadline(approval.deadline)}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </ActivityBody>
    </ActivityContainer>
  );
};
