import { FormTab, WorklfowTab } from '@contractool/schema/FormTab';

type Props = {
  formTabs: FormTab[];
  activeTab: WorklfowTab | undefined;
  setWorkflowTabs: React.Dispatch<React.SetStateAction<WorklfowTab[]>>;
};

const goToNextTab = ({ formTabs, activeTab, setWorkflowTabs }: Props) => {
  const activeFormTabIndex: number = formTabs.findIndex(
    (tab: FormTab) => tab?.id === activeTab?.id,
  );
  const nextActiveFormTabIndex = activeFormTabIndex + 1;

  setWorkflowTabs((workflowTabs: WorklfowTab[]): WorklfowTab[] =>
    workflowTabs.map((worklfowTab, index) => {
      if (index === activeFormTabIndex) {
        return {
          ...worklfowTab,
          active: false,
          validated: true,
          valid: true,
        };
      }
      if (index === nextActiveFormTabIndex) {
        return {
          ...worklfowTab,
          active: true,
          valid: true,
        };
      }
      return worklfowTab;
    }),
  );
};

const disableTab = (workflowTabs: WorklfowTab[], tab: FormTab): boolean =>
  workflowTabs
    .filter((tab) => tab?.valid === false && tab?.validated === false)
    .flatMap((tab) => tab?.id)
    .indexOf(tab?.id) >= 0;

export { goToNextTab, disableTab };
