import React, {useCallback} from 'react'
import {Modal} from 'components/Modal'
import {translate} from 'utils/translations'
import {useHistory, useParams} from 'react-router'
import {useToasts} from 'hooks'
import {http} from 'utils/http'
import {Form} from 'components/Form'
import CollectionForm from './Form'
import {Button} from 'components/Button'
import {useRequest} from 'hooks/useRequest'
import {AiCollection} from './New'


const EditCollection = ({
  onUpdate,
  closeUrl
}: {
  onUpdate: (collection: AiCollection) => void;
  closeUrl: string;
}) => {
  const history = useHistory()
  const {id} = useParams<{id?: string}>()
  const close = useCallback(() => {
    history.push(closeUrl)
  }, [history, closeUrl])
  const [collection] = useRequest<AiCollection | undefined>(
    `/api/ai/collections/${id}`,
    undefined
  )
  const handleSubmit = useCallback((values) => http.put<AiCollection>(`/api/ai/collections/${id}`, values), [id])
  const {success} = useToasts()
  const handleSuccess = useCallback(
    (collection) => {
      onUpdate(collection)
      success(
        `${translate(`Ai : ${collection.name} was added updated`, {
          title: collection.name
        })}`
      )
      close()
    },
    [onUpdate, close, success]
  )

  return (
    collection ?
      <Modal heading={translate('Edit Collection')} onClose={close}>
        <Form
          initialValues={{
            name: collection.name,
            description: collection.description,
            tasks: collection.tasks?.map((v) => v.id)
          }}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
        >
          <CollectionForm />
          <Modal.Footer className="flex justify-between">
            <Button color="white" onClick={close}>
              {translate('Cancel')}
            </Button>

            <Form.Submit>{translate('Confirm')}</Form.Submit>
          </Modal.Footer>
        </Form>
      </Modal>
      : null
  )
}

export default EditCollection
