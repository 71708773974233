import { Approval, Project } from '@contractool/schema';
import React, { FC, useContext } from 'react';
import { Route, Switch } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { Icon } from 'components/Icon';
import { Tabs, Tab } from 'components/Tabs';
import { Menu, MenuItem } from 'components/Menu';
import { Confirmation } from 'components/Confirmation';
import { sign } from 'utils/auth';
import { Link, useParams } from 'react-router-dom';
import { Modal } from 'components/Modal';
import { Form } from 'components/Form';
import { http } from 'utils/http';
import { translate } from 'utils/translations';
import { Button } from 'components/Button';
import { SidePanel } from './../ProjectDetail';
import { ApprovalNew } from './ApprovalNew';
import { ApprovalDetail } from './ApprovalDetail';
import { format, parseISO } from 'date-fns';
import { useToasts } from 'hooks';
import { GuardContext } from 'contexts';
import { permissionRequest, userPermissionRequest } from 'utils/wildcard';
import { useSmallLoader } from 'components/Loader';
import { Toast } from 'components/Toast';
import { Message } from 'components/Message';

export const ApprovalsPanel: FC<{
  project: Project;
  onUpdate: () => void;
  onClose: () => void;
}> = ({ project, onUpdate, onClose }) => {
  // const history = useHistory();

  // history.push(`/projects/${project.id}/approvals/74/resolve`);

  const approvedCount = project.approvals.filter(
    (a) => a.state === 'APPROVED',
  ).length;
  const pendingCount = project.approvals.filter(
    (a) => a.state === 'PENDING',
  ).length;
  const deniedCount = project.approvals.filter(
    (a) => a.state === 'DENIED',
  ).length;
  const canceledCount = project.approvals.filter(
    (a) => a.state === 'CANCELED',
  ).length;
  const { user } = useContext(GuardContext);
  const canApprove = (approval: Approval) => {
    if (userPermissionRequest(user, 'approval.superpower')) {
      return true;
    }

    return approval.assignee_id === user.id;
  };

  return (
    <SidePanel
      heading={translate('Approvals')}
      right={
        permissionRequest(project.can, 'approval.create') ? (
          <Link
            to={`/projects/${project.id}/approvals/new`}
            className="flex items-center text-blue-700"
          >
            <Icon name="add" size={5} />
            <span className="ml-2">{translate('Create new')}</span>
          </Link>
        ) : null
      }
    >
      {project.approvals.length ? (
        <Tabs>
          <Tab
            name="pending"
            heading={translate('Pending') + ' ' + pendingCount}
            className="pt-4"
          >
            <ApprovalTab
              project={project}
              state="PENDING"
              canApprove={canApprove}
            />
          </Tab>
          <Tab
            name="approved"
            heading={translate('Approved') + ' ' + approvedCount}
            className="pt-4"
          >
            <ApprovalTab
              project={project}
              state="APPROVED"
              canApprove={canApprove}
            />
          </Tab>
          <Tab
            name="denied"
            heading={translate('Denied') + ' ' + deniedCount}
            className="pt-4"
          >
            <ApprovalTab
              project={project}
              state="DENIED"
              canApprove={canApprove}
            />
          </Tab>
          <Tab
            name="canceled"
            heading={translate('Canceled') + ' ' + canceledCount}
            className="pt-4"
          >
            <ApprovalTab
              project={project}
              state="CANCELED"
              canApprove={canApprove}
            />
          </Tab>
        </Tabs>
      ) : (
        <div className="py-4">
          <div className="w-12 h-12 flex items-center justify-center border border-gray-000 text-gray-600 bg-gray-000 rounded-full m-auto">
            <Icon name="list" size={5} />
          </div>

          <div className="text-gray-600 text-center pt-6">
            {`${translate('There are no approvals yet')}...`}
          </div>
        </div>
      )}
      <Switch>
        <Route path={`/projects/${project.id}/approvals/new`}>
          <ApprovalNew onCreate={onUpdate} project={project} />
        </Route>

        <Route
          exact
          path={`/projects/${project.id}/approvals/:approvalId/:what`}
        >
          <ResolveApprovalModal
            onCancel={onClose}
            onSuccess={() => {
              onUpdate();
              onClose();
            }}
            project={project}
            canApprove={canApprove}
          />
        </Route>
        <Route exact path={`/projects/${project.id}/approvals/:approvalId`}>
          <ApprovalDetail onClose={onClose} project={project} />
        </Route>
      </Switch>
    </SidePanel>
  );
};

const ApprovalTab: FC<{
  project: Project;
  state: 'PENDING' | 'APPROVED' | 'DENIED' | 'CANCELED';
  canApprove: (approval: Approval) => boolean;
}> = ({ project, state, canApprove }) => {
  let approvals = project.approvals.filter(
    (approval) => approval.state === state,
  );
  if (approvals.length === 0) {
    return (
      <div className="pt-6 pb-4">
        <div className="w-12 h-12 flex items-center justify-center border border-gray-000 text-gray-600 bg-gray-000 rounded-full m-auto">
          <Icon name="list" size={5} />
        </div>

        <div className="text-gray-600 text-center pt-6">
          {`${translate('There are no :state approvals', {
            state: state.toLowerCase(),
          })}...`}
        </div>
      </div>
    );
  }

  return (
    <table className="w-full">
      <tbody className="">
        {approvals.map((approval) => (
          <tr key={approval.id}>
            <td className="w-14 py-4 pr-4">
              <Link
                to={`/projects/${project.id}/approvals/${approval.id}/resolve`}
              >
                {approval.state === 'PENDING' && (
                  <div
                    className="w-10 h-10 flex items-center justify-center border border-gray-200 text-gray-500 bg-white rounded-full"
                    title={translate('Approve') + ' ' + approval.title}
                  >
                    <Icon
                      name={canApprove(approval) ? 'done' : 'visibility'}
                      size={5}
                    />
                  </div>
                )}

                {approval.state === 'APPROVED' && (
                  <div
                    className="w-10 h-10 flex items-center justify-center border border-teal-000 text-teal-600 bg-teal-000 rounded-full"
                    title={translate('Approved')}
                  >
                    <Icon name="done" size={5} />
                  </div>
                )}

                {approval.state === 'DENIED' && (
                  <div
                    className="w-10 h-10 flex items-center justify-center border border-red-000 text-red-700 bg-red-000 rounded-full"
                    title={translate('Approval Denied')}
                  >
                    <Icon name="close" size={5} />
                  </div>
                )}

                {approval.state === 'CANCELED' && (
                  <div
                    className="w-10 h-10 flex items-center justify-center border border-red-000 text-red-700 bg-red-000 rounded-full"
                    title={translate('Approval Canceled')}
                  >
                    <Icon name="remove_circle" size={5} />
                  </div>
                )}
              </Link>
            </td>
            <td className="py-4">
              <Link
                to={`/projects/${project.id}/approvals/${approval.id}/resolve`}
              >
                {approval.title}
              </Link>
            </td>
            <td className="w-31 py-4 pl-10 pr-13">
              <img
                data-tip={approval.assignee.name}
                src={sign(approval.assignee.avatar_url)}
                alt={approval.assignee.name}
                className="w-8 h-8 rounded-full"
              />
              <ReactTooltip />
            </td>
            <td className="w-40 py-4">
              <Icon
                name="date_range"
                size={6}
                className="mr-3 inline text-gray-500"
              />
              <span className="w-15 text-gray-600">
                {formatDeadline(approval.deadline)}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ResolveApprovalModal: FC<{
  project: Project;
  onCancel: () => void;
  onSuccess: () => void;
  canApprove: (approval: Approval) => boolean;
}> = ({ project, onSuccess, onCancel, canApprove }) => {
  const { approvalId } = useParams<{ approvalId?: string }>();
  const { what } = useParams<{ what?: string }>();
  const approval = project.approvals.find(
    (t) => approvalId && t.id === parseInt(approvalId),
  );
  const { error } = useToasts();
  const smallLoader = useSmallLoader();
  if (!approval) return null;
  const isResolved = ['APPROVED', 'DENIED'].includes(approval.state);
  const isDenied = approval.state === 'DENIED';

  const handleSubmit = (values: any, mode?: string) => {
    smallLoader.start(translate('Changing the approval state...'));
    switch (mode) {
      case 'deny':
        values.state = 'DENIED';
        break;
      case 'cancel':
        values.state = 'CANCELED';
        break;
    }

    return http.put(approval.state_url, values);
  };
  const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  let actionButtonText = translate(isResolved ? 'Re-open' : 'Approve');

  return approval.state ? (
    <Modal
      heading={`${capitalize(approval.state.toLowerCase())} - ${
        approval.title
      }`}
      size="regular"
      onClose={onCancel}
      corner={
        approval.state === 'PENDING' &&
        permissionRequest(project.can, 'approval.cancel') && (
          <Menu handle="more_vert">
            <Form
              initialValues={{ state: 'CANCELED' }}
              onSubmit={handleSubmit}
              onSuccess={() => {
                smallLoader.stop();
                onSuccess();
              }}
              onError={(errors: any) => {
                smallLoader.stop();
                error(translate(errors.state[0]));
              }}
              allowPristineSubmission={true}
              supportsSubmitMode
            >
              <Confirmation
                onConfirm={() => {}}
                heading={translate('Cancel this approval')}
                buttonText={translate('Yes, Cancel')}
                color="yellow"
                button={
                  <Form.Submit color="yellow" mode="cancel">
                    {translate('Yes, Cancel')}
                  </Form.Submit>
                }
                content={
                  <Form.TextArea
                    name="cancel_reason"
                    className="w-full mt-8"
                    label="Reason of cancelation"
                    placeholder="Add reason here..."
                  />
                }
                trigger={({ onClick }) => (
                  <MenuItem
                    icon="remove_circle"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick();
                    }}
                  >
                    {translate('Cancel')}
                  </MenuItem>
                )}
              >
                {translate('Are you sure you want to Cancel this approval?')}
              </Confirmation>
            </Form>
          </Menu>
        )
      }
    >
      <Form
        initialValues={{
          state: isResolved ? 'PENDING' : 'APPROVED',
          comment: approval.comment,
          cancel_reason: approval.cancel_reason,
        }}
        onSubmit={handleSubmit}
        onSuccess={() => {
          smallLoader.stop();
          onSuccess();
        }}
        onError={(errors: any) => {
          smallLoader.stop();
          error(translate(errors.state[0]));
        }}
        allowPristineSubmission={true}
        supportsSubmitMode
      >
        {what === 'resolved' && (
          <div className="pb-12">
            {approval.state === 'APPROVED' && (
              <Message.Success>The approval is now Approved</Message.Success>
            )}
            {isDenied && (
              <Message.Error>The approval is now Denied</Message.Error>
            )}
          </div>
        )}
        {what === 'resolved' && (
          <div className="flex">
            <Form.TextInput
              name="comment"
              className="w-full mb-8"
              label={translate(isDenied ? 'Reason' : 'Note')}
              placeholder={translate('Add comment here...')}
            />
            <div className="flex items-center ml-8">
              <Form.Submit mode="deny" color="white">
                {translate('Send')}
              </Form.Submit>
            </div>
          </div>
        )}
        <div className="w-full bg-gray-000 p-4 items-center justify-center flex">
          <div className="flex-initial">
            {approval.state === 'PENDING' && (
              <div className="w-10 h-10 flex items-center justify-center border border-gray-200 text-gray-500 rounded-full">
                <Icon name="done" size={5} />
              </div>
            )}

            {approval.state === 'APPROVED' && (
              <div className="w-10 h-10 flex items-center justify-center border border-teal-000 text-teal-600 bg-teal-000 rounded-full">
                <Icon name="done" size={5} />
              </div>
            )}

            {isDenied && (
              <div className="w-10 h-10 flex items-center justify-center border border-red-000 text-red-600 bg-red-000 rounded-full">
                <Icon name="close" size={5} />
              </div>
            )}

            {approval.state === 'CANCELED' && (
              <div className="w-10 h-10 flex items-center justify-center border border-red-000 text-red-600 bg-red-000 rounded-full">
                <Icon name="remove_circle" size={5} />
              </div>
            )}
          </div>
          <div className="flex-1 ml-4">{approval.title}</div>
          <div className="flex-none ml-4 mr-15">
            <div className="flex items-center justify-center">
              <img
                data-tip={approval.assignee.name}
                src={sign(approval.assignee.avatar_url)}
                alt={approval.assignee.name}
                className="w-8 h-8 rounded-full"
              />
              <ReactTooltip />
            </div>
          </div>
          <div className="flex-initial text-gray-600 whitespace-nowrap flex items-center">
            <Icon
              name="date_range"
              size={6}
              className="text-gray-600 inline mr-3"
            />
            {formatDeadline(approval.deadline)}
          </div>
        </div>
        <div className="mb-12">
          <textarea
            rows={10}
            className="pb-5 mt-5 w-full focus:outline-none border-b leading-none text-gray-700 placeholder-gray-400 resize-none leading-tight border-gray-200 focus:border-blue-700"
            disabled
            value={approval.body ? approval.body : ''}
          />
        </div>
        {approval.state === 'CANCELED' && (
          <>
            <Form.TextArea
              name="cancel_reason"
              className="w-full mt-8"
              label="Reason of cancelation"
              disabled
            />
            <Modal.Footer className="flex justify-between">
              <Button color="white" onClick={onCancel}>
                {translate('Close')}
              </Button>
            </Modal.Footer>
          </>
        )}
        {approval.state !== 'CANCELED' && (
          <>
            {what !== 'resolved' && (
              <Form.TextInput
                name="comment"
                className="w-full mb-8"
                label={translate(isDenied ? 'Reason' : 'Note')}
                placeholder={translate('Add comment here...')}
              />
            )}
            <Modal.Footer className="flex justify-between">
              <Button color="white" onClick={onCancel}>
                {translate('Close')}
              </Button>

              <div>
                {!isResolved && canApprove(approval) && (
                  <Confirmation
                    onConfirm={() => {}}
                    trigger={({ onClick }) => (
                      <Button
                        className="mr-3"
                        color="white"
                        onClick={(e) => {
                          e.stopPropagation();
                          onClick();
                        }}
                      >
                        {translate('Deny')}
                      </Button>
                    )}
                    heading={translate('Are you sure?')}
                    buttonText={translate('Deny')}
                    color="yellow"
                    button={
                      <Form.Submit mode="deny">
                        {translate('Yes, deny')}
                      </Form.Submit>
                    }
                  />
                )}
                {canApprove(approval) && (
                  <Confirmation
                    onConfirm={() => {}}
                    trigger={({ onClick }) => (
                      <Button
                        color={'blue'}
                        onClick={(e) => {
                          e.stopPropagation();
                          onClick();
                        }}
                      >
                        {actionButtonText}
                      </Button>
                    )}
                    heading={translate('Are you sure?')}
                    buttonText={actionButtonText}
                    color="yellow"
                    button={
                      <Form.Submit mode="apply">{actionButtonText}</Form.Submit>
                    }
                  />
                )}
              </div>
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  ) : (
    <></>
  );
};

export const formatDeadline = (date: string) => {
  return format(parseISO(date), 'dd MMM yyyy');
};
