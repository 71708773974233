import React from 'react';
import {Section, Variation} from './DesignSystem';
import {Form} from '../../components/Form';
import {Button} from '../../components/Button';
import {useHistory} from 'react-router-dom';

export function FormGuards() {
    const history = useHistory();

    return (
        <>
            <Section title="Form guard">
                <Variation hint="Modify the form and try to leave">
                    <Form
                        initialValues={{material: 'plastic'}}
                        onSubmit={() => Promise.resolve({data: null})}
                    >
                        <Form.TextInput name="material" label="Material" />

                        <div className="flex justify-between mt-8">
                            <Button onClick={() => history.push('/projects')} color="white">
                                Leave
                            </Button>

                            <Form.Submit>Submit</Form.Submit>
                        </div>
                    </Form>
                </Variation>
            </Section>
        </>
    );
}
