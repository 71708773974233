import React, { useContext, useCallback } from "react";
import { Supplier } from "@contractool/schema";
import { Form } from "../../components/Form";
import { http } from "../../utils/http";
import { translate } from "utils/translations";
import { Modal } from "../../components/Modal";
import { Button } from "../../components/Button";
import { useHistory } from "react-router-dom";
import { useToasts } from "hooks";
import SupplierForm from "./SupplierForm";
import { convertArrayToObject } from "../../utils/form";
import { AppContext } from "contexts";

export function SupplierNew({
                                onCreate,
                                setModalOpened
                            }: {
    onCreate: (supplier: Supplier) => void;
    setModalOpened: (opened: boolean) => void
}) {
    const { success } = useToasts();
    const { config } = useContext(AppContext);

    const close = () => {
        setModalOpened(false);
    };

    const handleSubmit = useCallback((values) => http.post<Supplier>("/api/suppliers", values), []);
    const handleSuccess = useCallback(
        (supplier) => {
            onCreate(supplier);
            success(
                `${translate(config.config.supplier_label_singular + " :title was added successfully", {
                    title: supplier.title
                })}`
            );
            close();
        },
        [onCreate, close, success]
    );

    let fieldsDefaults = convertArrayToObject(config.supplier_fields, "name");
    for (let key in fieldsDefaults) {
        fieldsDefaults[key] = "";
    }

    return (
        <Modal heading={translate("Add " + config.config.supplier_label_singular)} onClose={close}>
            <Form
                initialValues={{
                    title: "",
                    address_street: "",
                    address_city: "",
                    address_zip: "",
                    address_state: "",
                    contacts: [],
                    fields: fieldsDefaults
                }}
                onSubmit={handleSubmit}
                onSuccess={handleSuccess}
                name="new-supplier"
            >
                <SupplierForm create={true} />
                <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={close}>
                        {translate("Cancel")}
                    </Button>

                    <Form.Submit
                        data-testid="submit_supplier_button">{translate("Add " + config.config.supplier_label_singular)}</Form.Submit>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
