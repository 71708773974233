import React, { useContext, useCallback, memo, useEffect, useState } from "react";
import { Form } from "components/Form";
import { Tab, Group } from "../../../interfaces";
import ProjectContext from "../../../../../../../ProjectContext";
import { http } from "utils/http";
import { translate } from "utils/translations";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { useToasts } from "hooks";
import { EventEmitter } from "utils/eventEmitter";
import { Project } from "../../../../../../../../../../../_common/schema";
import { useLocalStorage } from "@rehooks/local-storage";

const MemberModalForm: React.FC<{
    memberKey: string;
    formName: string;
    children: React.ReactElement;
    group: Tab | Group;
    closeModal: () => void;
    setDraft: (draft: any) => void;
    clearDraft: () => void;
    draft: any;
    readOnly?: boolean;
}> = ({ memberKey, formName, children, group, closeModal, setDraft, clearDraft, draft, readOnly }) => {
    const { resource: project, refresh } = useContext(ProjectContext);


    // let hasDraft = draft && Object.keys(draft).length > 0;
    let hasDraft = false;

    let initialValues = {};
    if (hasDraft) {
        initialValues = draft;
    } else if (project.meta && project.meta.widget_fields && project.meta.widget_fields[memberKey]) {
        initialValues = project.meta.widget_fields[memberKey].values;
    }

    const handleSubmit = useCallback(
        (memberValues) => {
            project.meta = project.meta || {};
            project.meta.widget_fields = {
                ...project.meta.widget_fields,
                [memberKey]: {
                    export_url:
                        ((project.meta.widget_fields || {})[memberKey] || {}).export_url || "",

                    values: memberValues
                }
            };
            let saveUrl = "/api/projects/" + project.id + "/fields-group/update/" + memberKey;
            let payload = JSON.parse(JSON.stringify(project));
            delete payload.logs_full;
            delete payload.parent;
            delete payload.children;
            delete payload.child;
            delete payload.team;

            return http.put(saveUrl, memberValues);
        },
        [project, memberKey]
    );

    const { success } = useToasts();
    const handleSuccess = useCallback(() => {
        clearDraft();
        success(`${translate("Group :name was updated successfully", { name: group.name })}`);
        refresh();
        closeModal();
    }, [closeModal, group.name, success, refresh]);

    console.log("WidgetFieldsgroup", group);


    EventEmitter.subscribe("form.setValues." + formName, (values: any) => {
        console.log("WidgetFieldsgroup.setValues", values);
        setDraft(values);
    });

    return (
        <Form name={formName} initialValues={initialValues} onSubmit={handleSubmit} onSuccess={handleSuccess}>
            {children}
            {readOnly ? (
                <Modal.Footer className="flex justify-end">
                    <Button color="white" onClick={closeModal}>
                        {translate("Close")}
                    </Button>
                </Modal.Footer>
            ) : (
                <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={closeModal}>
                        {translate("Cancel")}
                    </Button>
                    <Form.Submit>{translate("Save Changes")}</Form.Submit>
                </Modal.Footer>
            )}
        </Form>
    );
};

const PureMemberModalForm = memo(MemberModalForm);

export default PureMemberModalForm;
