import { Role } from "@contractool/schema";
import React from "react";

import { fieldDependsOnOtherThan } from "features/form/field";
import { Link, useHistory } from "react-router-dom";
import { TeamRoleSelect } from "utils/form";

function FormRole({ role }: { role: Role }) {
    const history = useHistory();

    return (
        <TeamRoleSelect
            key={role.key}
            role={role}
            deleted={false}
            right={
                role.side_link ? (
                    <Link
                        data-cy={`role-link-` + role.key}
                        className="text-blue-600 hover:text-blue-300"
                        to={`${role.side_link.url}${history.location.search}`}
                    >
                        {role.side_link.text}
                    </Link>
                ) : null
            }
            className="mt-6"
            groups={role.groups}
        />
    );
}

const DependentFormRoledOnCard = fieldDependsOnOtherThan(FormRole);

export default DependentFormRoledOnCard;
