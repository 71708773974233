import React, {FC, useCallback, useContext} from 'react';
import {Project} from '@contractool/schema';
import {useHistory} from 'react-router-dom';
import {AppContext} from 'contexts';
import {DummyList} from 'components/DummyList';
import {IconCircle} from 'components/IconCircle';
import {Icon} from 'components/Icon';
import {translate} from 'utils/translations';

export function RepositoryList({projects, loaded}: {projects: Project[]; loaded: boolean}) {
    const history = useHistory();
    const {config} = useContext(AppContext);

    const formattedDateString = useCallback(
        (dateInput: any) => {
            if (
                typeof dateInput === 'string' &&
                dateInput.match(/-\d+-/) &&
                new Date(dateInput) instanceof Date &&
                !isNaN(new Date(dateInput).valueOf())
            ) {
                const date = new Date(dateInput);
                const {
                    date_format: {skeleton, day, month, year},
                } = config;

                return skeleton
                    .replace('day', date.toLocaleDateString('default', {day}))
                    .replace('month', date.toLocaleDateString('default', {month}))
                    .replace('year', date.toLocaleDateString('default', {year}));
            } else {
                return null;
            }
        },
        [config],
    );

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th className="w-10"></th>
                        <th>{translate('Project')}</th>
                        <th>{translate('Ammendments')}</th>
                        <th>{translate('Auto-renewal')}</th>
                        <th>{translate('Risk Profile')}</th>
                        <th>{translate('Temp. match')}</th>
                        <th>{translate('Effective date')}</th>
                    </tr>
                </thead>
                <tbody>
                    {!loaded ? (
                        <DummyList rowsCount={10} colsCount={7} />
                    ) : projects.length === 0 ? (
                        <tr>
                            <td colSpan={8} className="text-center">
                                {translate('No projects found')}
                            </td>
                        </tr>
                    ) : (
                        projects.map((project) => {
                            return (
                                <tr
                                    key={project.id}
                                    className="hover:bg-gray-000 cursor-pointer rounded text-gray-500"
                                    onClick={(event) => {
                                        if (event.ctrlKey) {
                                            window.open(`/#/projects/${project.id}`, '_blank');

                                            return;
                                        }
                                        history.push(`/projects/${project.id}`);
                                    }}
                                >
                                    <td>
                                        <IconCircle
                                            name={'category'}
                                            iconSize={6}
                                            circleSize={12}
                                            className={'text-blue-700'}
                                        />
                                    </td>
                                    <td className="text-gray-700" title={project.title}>
                                        {project.title.length > 120
                                            ? `${project.title.substr(0, 120)}...`
                                            : project.title}
                                    </td>
                                    <td>
                                        <Ammendments ammendmentsCount={project.children.length} />
                                    </td>
                                    <td>
                                        <AutoRenewal
                                            autoRenewalDate={formattedDateString(
                                                project.fields['auto_renewal'],
                                            )}
                                        />
                                    </td>
                                    <td>
                                        <RiskProfile riskProfile={project.fields['risk_profile']} />
                                    </td>
                                    <td>
                                        <TemplateMatch
                                            templateMatch={project.fields['template_match']}
                                        />
                                    </td>
                                    <td>
                                        <EffectiveDate
                                            effectiveDate={formattedDateString(
                                                project.fields['effective_date'],
                                            )}
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
        </>
    );
}

const Ammendments: FC<{ammendmentsCount: any}> = ({ammendmentsCount}) =>
    ammendmentsCount > 0 ? (
        <div className="w-15">
            <div className="p-1 bg-gray-200 rounded-full flex justify-around items-center text-gray-600">
                <Icon size={4} name="edit" />
                {ammendmentsCount}
            </div>
        </div>
    ) : (
        <>-</>
    );

const AutoRenewal: FC<{autoRenewalDate: any}> = ({autoRenewalDate}) =>
    autoRenewalDate ? (
        <div className="flex items-center">
            <Icon size={5} name="sync" className="mr-4 text-teal-700" />
            {autoRenewalDate}
        </div>
    ) : (
        <>-</>
    );

const RiskProfile: FC<{riskProfile: any}> = ({riskProfile}) => {
    if (!riskProfile) {
        return <>-</>;
    }

    let type;
    switch (riskProfile.toLocaleLowerCase()) {
        case 'high':
            type = 'text-red-700';
            break;
        case 'medium':
            type = 'text-yellow-600';
            break;
        case 'low':
            type = 'text-lime-500';
            break;
        default:
            type = 'text-gray-500';
    }

    return riskProfile ? (
        <div className="flex items-center">
            <Icon size={3} name="dot" className={`mr-4 ${type}`} />
            {riskProfile}
        </div>
    ) : (
        <>-</>
    );
};

const TemplateMatch: FC<{templateMatch: any}> = ({templateMatch}) => {
    if (!templateMatch) {
        return <>-</>;
    }

    let type;
    if (templateMatch >= 70) {
        type = 'text-lime-500';
    } else if (templateMatch >= 35) {
        type = 'text-yellow-600';
    } else {
        type = 'text-red-700';
    }

    return (
        <div className="flex items-center">
            <Icon size={3} name="dot" className={`mr-4 ${type}`} />
            {`${templateMatch}%`}
        </div>
    );
};

const EffectiveDate: FC<{effectiveDate: any}> = ({effectiveDate}) =>
    effectiveDate ? (
        <div className="flex items-center">
            <Icon size={4} name="calendar_today" className="mr-4 text-gray-400" />
            {effectiveDate}
        </div>
    ) : (
        <>-</>
    );
