import React from 'react';
import { translate } from 'utils/translations';

const NoResults = () => {
  return (
    <tr>
      <td>{translate('No results')}</td>
    </tr>
  );
};

export default NoResults;
