import React, { useEffect, useState } from "react";
import { Modal } from "components/Modal";
import { Tab, Group } from "../../interfaces";
import { MemberModalForm, MemberModalTabs, ProjectFieldList } from "./components";
import { Button } from "components/Button";
import { translate } from "utils/translations";
import { EventEmitter } from "utils/eventEmitter";
import { useLocalStorage } from "@rehooks/local-storage";

const MemberModalContainerStyle = {
    height: "80vh"
};

const MemberModalStyle = {
    content: {
        width: "90%",
        maxWidth: "1500px"
    }
};

const MemberModal: React.FC<{
    memberKey: string;
    group: Tab | Group;
    isOpen: boolean;
    heading: string;
    readOnly?: boolean;
    closeModal: () => void;
}> = ({ memberKey, group, isOpen, heading, readOnly, closeModal }) => {
    const hasTabs = "tabs" in group;
    const tabGroup = group as Group;
    const fieldGroup = group as Tab;
    let formName = "widget_fields" + group.name.replaceAll(" ", "_");
    const [draft, setDraft, clearDraft] = useLocalStorage<any>(formName);
    // const [draftState, setDraftState] = useState<any>(draft);
    // console.log("MemberModal draft", draft);
    // console.log("MemberModal draftState", draftState);
    // useEffect(() => {
    //     console.log("draftUseEffect", draft);
    //     setDraftState(draft);
    // }, [draft]);

    return (
        <Modal
            isOpen={isOpen}
            heading={heading}
            onClose={closeModal}
            containerStyle={MemberModalContainerStyle}
            style={MemberModalStyle}
            /*corner={
                <Button color="white" onClick={clearDraft}>{translate("Clear")}</Button>
            }*/

        >
            <MemberModalForm memberKey={memberKey} group={group} closeModal={closeModal} formName={formName}
                             setDraft={setDraft}
                             clearDraft={clearDraft}
                             draft={draft}
                             readOnly={readOnly}>
                {hasTabs ? (
                    <MemberModalTabs tabs={tabGroup.tabs} readOnly={readOnly} />
                ) : (
                    <ProjectFieldList fields={fieldGroup.fields} readOnly={readOnly} />
                )}
            </MemberModalForm>
        </Modal>
    );
};

const PureMemberModal = React.memo(MemberModal);

export default PureMemberModal;
