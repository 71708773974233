import * as React from "react";
import {FC, useContext} from "react";
import {AppContext} from "contexts";
import {User, UserGroup} from "../../../../_common/schema";
import {useToasts} from "hooks";
import {useSmallLoader} from "components/Loader";
import {Modal} from "components/Modal";
import {translate} from "utils/translations";
import {Form} from "components/Form";
import {http} from "utils/http";
import {Button} from "components/Button";
import {useParams} from "react-router-dom";

export const InviteUserModal: FC<{ onClose: () => void, onSuccess?: (user: User) => void }> = ({
                                                                                                   onClose,
                                                                                                   onSuccess
                                                                                               }) => {
    const {userGroup: userGroupKey} = useParams<{ userGroup?: string }>();
    const {config} = useContext(AppContext);
    let group = config.user_groups.find((group: UserGroup) => group.key === userGroupKey);

    const initialValues = {
        name: "",
        email: "",
        timezone: "UTC",
        groups: [group]
    };
    const {success} = useToasts();
    const smallLoader = useSmallLoader();
    return (
        <Modal heading={translate("Add new user")} onClose={onClose}>
            <Form
                initialValues={initialValues}
                onSubmit={(values) => {
                    smallLoader.start("Loading...");
                    return http.post<User>("api/users", values);
                }}
                onSuccess={(data: User) => {
                    success(translate("User was successfully created"));
                    if (onSuccess) {
                        onSuccess(data)
                    }
                    onClose();
                    smallLoader.stop();
                }}
            >
                <Form.TextInput
                    name="name"
                    data-cy="invite-name"
                    label={translate("Name")}
                    placeholder={translate("Name")}
                />
                <Form.TextInput
                    data-cy="invite-email"
                    name="email"
                    label="Email"
                    placeholder="Email"
                    className="mt-6"/>
                <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={onClose}>
                        {translate("Cancel")}
                    </Button>
                    <Form.Submit className="submit-invite">{translate("Save Changes")}</Form.Submit>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
