import React, {useCallback, useContext} from 'react';
import {useHistory, useParams} from 'react-router';
import {FilterTemplate} from '@contractool/schema';

import {Modal} from 'components/Modal';
import {Form} from 'components/Form';
import {Button} from 'components/Button';
import TaskForm from './Form';
import {AiTask} from './New';

import {translate} from 'utils/translations';
import {http} from 'utils/http';

import {useToasts} from 'hooks';
import {useRequest} from 'hooks/useRequest';

import {AppContext} from 'contexts';

const EditTask = ({onUpdate, closeUrl}: {onUpdate: (task: AiTask) => void; closeUrl: string}) => {
    const {assessmentWorkflow} = useContext(AppContext);
    const history = useHistory();
    const {id} = useParams<{id?: string}>();
    const [filterTemplates] = useRequest<FilterTemplate[]>(
        `/api/project-filter-templates?workflow=${assessmentWorkflow}`,
        [],
        {},
    );
    const metaDataFields = filterTemplates.map((template: FilterTemplate) => ({
        value: template.name,
        label: template.label,
    }));

    const close = useCallback(() => {
        history.push(closeUrl);
    }, [history, closeUrl]);
    const [task] = useRequest<AiTask | undefined>(`/api/ai/tasks/${id}`, undefined);
    const handleSubmit = useCallback(
        (values) => http.put<AiTask>(`/api/ai/tasks/${id}`, values),
        [id],
    );
    const {success} = useToasts();
    const handleSuccess = useCallback(
        (task) => {
            onUpdate(task);
            success(
                `${translate(`Ai : ${task.name} was added updated`, {
                    title: task.name,
                })}`,
            );
            close();
        },
        [onUpdate, close, success],
    );

    return task ? (
        <Modal heading={translate('Edit Task')} onClose={close}>
            <Form
                initialValues={{
                    name: task?.name,
                    description: task.description,
                    collections: task.collections?.map((v) => v.id),
                    type: task.type,
                    fields: task?.fields,
                }}
                onSubmit={handleSubmit}
                onSuccess={handleSuccess}
            >
                <TaskForm fields={metaDataFields} />
                <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={close}>
                        {translate('Cancel')}
                    </Button>

                    <Form.Submit>{translate('Confirm')}</Form.Submit>
                </Modal.Footer>
            </Form>
        </Modal>
    ) : null;
};

export default EditTask;
