import React from 'react';
import { translate } from 'utils/translations';
import { AiDocument } from '../documents/New';
import { http } from 'utils/http';
import { DocumentComponent } from 'components/documents/Document';

const DocumentsList = ({
  documents,
  onDelete,
}: {
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  documents: AiDocument[];
  onSort?: (column: string, direction: 'asc' | 'desc') => void;
  onDelete: () => void;
}) => {
  const deleteDocument = (id: string) => {
    http.delete(`/api/ai/documents/${id}`).then(() => {
      onDelete();
    });
  };

  return (
    <>
      {documents && documents.length ? (
        documents.map((document, index) => {
          return (
            document?.document && (
              <DocumentComponent
                document={document?.document}
                key={index}
                onDelete={() => {
                  deleteDocument(document.id);
                }}
                change={false}
              />
            )
          );
        })
      ) : (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={8} className="text-center">
                {translate('No Documents found')}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};

export default DocumentsList;
