import React from 'react';
import { TableProps } from 'react-table';

type props = {
  children: React.ReactNode;
  getTableProps: (props: TableProps) => TableProps;
};

const Table: React.FC<props> = ({ children, getTableProps }) => {
  return (
    <table
      {...getTableProps({
        className: 'table',
      })}
    >
      {children}
    </table>
  );
};

export default Table;
