import { Filter } from "@contractool/schema";
import React, { FC, ReactNode } from "react";
import { Link, Switch, Route } from "react-router-dom";

import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { Modal } from "components/Modal";
import { useToasts } from "hooks";
import { http } from "utils/http";
import { translate } from "utils/translations";

import { FilterEdit } from "./FilterEdit";
import { toQueryString } from "utils/url";
import { Confirmation } from "components/Confirmation";
import { ReactSortable } from "react-sortablejs";

export function Categories({
                               filters,
                               onRefresh,
                               onClose,
                               children,
                               params,
                               handleReorderList,
                               getSortableFiltersList
                           }: {
    filters: Filter[];
    onRefresh: (id?: number) => void;
    onClose: () => void;
    children: ReactNode;
    params?: {};
    handleReorderList: (event: any) => Promise<void>;
    getSortableFiltersList: (filters: Filter[]) => Filter[];
}) {
    const [sortableList, setSortableList] = React.useState(getSortableFiltersList(filters));
    React.useEffect(() => {
        setSortableList(getSortableFiltersList(filters));
    }, [filters]);
    return (
        <Modal
            heading={translate("Manage filters")}
            isOpen={true}
            onClose={onClose}
            size="small"
            corner={
                <Link
                    to={
                        params
                            ? `/projects/categories/new?${toQueryString(params)}`
                            : "/projects/categories/new"
                    }
                    className="flex text-blue-600 items-center"
                >
                    <Icon name="add" size={5} className="mr-2" />
                    {translate("Create new")}
                </Link>
            }
            compact={true}
        >
            <ul>

                {filters?.filter((category: Filter) => category.id < 0)
                    .map((category: Filter) => {
                        return <li key={category.title} className="flex items-center py-7 border-b text-gray-700">
                            <Icon name="lock_outlined" size={6} className="mr-6 text-gray-500" />
                            {translate(category.title)}
                        </li>;
                    })
                }
                <ReactSortable
                    tag="ul"
                    list={sortableList}
                    setList={setSortableList}
                    onUpdate={handleReorderList}
                    handle=".drag-handle"
                >
                    {sortableList?.map((category: Filter) => {
                            return <Category key={"filter" + category.id} category={category} onRefresh={onRefresh} />;
                        })
                    }
                </ReactSortable>
            </ul>
            <Modal.Footer className="flex justify-end">
                <Button color="blue" onClick={onClose}>
                    {translate("Done")}
                </Button>
            </Modal.Footer>
            <Switch>
                <Route path="/projects/categories/new">{children}</Route>
                <Route path={"/projects/categories/:id/edit"}>
                    <FilterEdit
                        filters={filters}
                        heading={translate("Edit filter")}
                        onSubmit={onRefresh}
                    />
                </Route>
            </Switch>
        </Modal>
    );
}

const Category: FC<{ category: Filter; onRefresh: (id?: number) => void }> = ({
                                                                                  category,
                                                                                  onRefresh
                                                                              }) => {
    const { success } = useToasts();

    const remove = () => {
        http.delete(`/api/project-filters/${category.id}`).then(() => {
            onRefresh(category.id);
            success(`${translate("Filter successfully removed")}.`);
        });
    };
    const update = () => {
        http.put(`/api/project-filters/${category.id}`, {
            ...category,
            is_visible: !category.is_visible
        }).then(() => {
            onRefresh(category.id);
            success(`${translate("Filter visibility successfully changed")}.`);
        });
    };

    return (
        <li
            className="flex items-center justify-between py-7 border-b text-gray-700"
            data-cy={category.title}
        >
            <div className="flex">
                <Icon name="reorder" size={6} className="drag-handle mr-6 text-gray-500" />
                {category.title}
            </div>
            <div className="flex space-x-3">
                <Confirmation
                    onConfirm={remove}
                    trigger={({ onClick }) => (
                        <div onClick={onClick} title="Delete filter">
                            <Icon name="delete" size={6} className="text-gray-500 cursor-pointer" />
                        </div>
                    )}
                    heading={`${translate("Delete filter")}?`}
                    buttonText={translate("Yes, delete")}
                    color="red"
                >
                    {translate("Are you sure you want to delete this filter?")}
                </Confirmation>
                <div>
                    <Link to={`/projects/categories/${category.id}/edit`} title="Edit filter">
                        <Icon name="edit" size={6} className="text-gray-500 cursor-pointer" />
                    </Link>
                </div>
                <div onClick={update} title={translate("Toggle visibility")}>
                    <Icon
                        name="visibility"
                        size={6}
                        className={` cursor-pointer ${
                            category.is_visible ? "text-blue-700" : "text-gray-500"
                        }`}
                    />
                </div>
            </div>
        </li>
    );
};
