import React, { useEffect, useState } from 'react';
import * as qs from 'qs';
import { isEmpty, set } from 'lodash';
import Select, {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  GroupBase,
  OptionsOrGroups,
  PropsValue,
} from 'react-select';
import AsyncSelect from 'react-select/async';

import { Option } from 'components/Dropdown';
import { Icon } from 'components/Icon';

import { FormContext } from './Form';

type Props = {
  onChange: (result: Option<string>[]) => void;
  onBlur?: (e: any) => void;
  value: PropsValue<string | Option<string>> | undefined;
  options: OptionsOrGroups<string | Option<string>, GroupBase<any>> | undefined;
  isMulti?: boolean;
  endpoint?: string;
  params?: object | null;
  placeholder?: string;
  readOnly?: boolean;
  isClearable?: boolean;
  hasError?: boolean;
  name?: string;
  isSearchable?: boolean;
  menuPortalTarget?: boolean;
};

const MultiselectDropdown2: React.FC<Props> = ({
  onChange,
  onBlur,
  value,
  options,
  isMulti = true,
  endpoint,
  params,
  placeholder,
  readOnly,
  isClearable = true,
  hasError,
  name,
  isSearchable,
  menuPortalTarget,
}) => {
  const styles = {
    control: (base: any, state: any) => ({
      ...base,
      border: 'none',
      padding: '9px 0',
      borderRadius: '0',
      borderBottom: state.isFocused
        ? '1px solid #2684FF'
        : hasError
        ? '1px solid #f03e3e'
        : '1px solid #dee2e6',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',
      ':hover': {
        ...base[':hover'],
        borderBottom: state.isFocused
          ? '1px solid #2684FF'
          : hasError
          ? '1px solid #f03e3e'
          : '1px solid #dee2e6',
      },
    }),
    menu: (base: any) => ({
      ...base,
      padding: '6px',
      margin: '0',
      borderRadius: '5px',
      boxShadow:
        '0 0 0 0px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)',
    }),
    menuList: (base: any) => ({
      ...base,
      padding: '0',
    }),
    option: (base: any, state: any) => ({
      ...base,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? '#f1f3f5' : 'white',
      borderRadius: '3px',
      color: state.isSelected ? '#2684FF' : 'inherit',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      '> div': {
        ...base['> div'],
        padding: '0 8px',
        color: 'inherit',
        ':hover': {
          ...base['> div:hover'],
          color: 'inherit',
        },
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: '0',
    }),
  };

  const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => (
    <components.DropdownIndicator {...props}>
      <Icon
        name="triangle"
        className={`text-gray-600 ${props.isFocused ? '' : 'rotate-180'}`}
        size={2}
      />
    </components.DropdownIndicator>
  );

  const ClearIndicator = (props: ClearIndicatorProps<any, true>) => (
    <components.ClearIndicator {...props}>
      <Icon name="close" size={6} />
    </components.ClearIndicator>
  );

  if (endpoint) {
    const formContext = React.useContext(FormContext);
    const [state, setState] = useState({
      isLoading: false,
      options: [],
      value,
    });

    const handleChange = (value: any) => {
      onChange(value);
      setState((prevState) => ({
        ...prevState,
        value,
      }));
    };

    const loadOptions = async (inputValue: string) => {
      const queryParams = isEmpty(params) ? '' : `&${qs.stringify(params)}`;

      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const options = await fetch(endpoint + `?phrase=${inputValue}${queryParams}`,{
          credentials: 'include'
      }).then((res) => res.json());

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        options,
      }));

      // Remove selected value
      // if doesn't exist in returned options
      const valueExists = options.find((option: Option<string>) => {
        const { value: id } = value as Option<string>;
        return option.value === id;
      });

      if (!valueExists && name) {
        set(formContext.values, name, null);
        setState((prevState) => ({
          ...prevState,
          value: null,
        }));
      }

      return options;
    };

    const handleFocus = async (e: any) => {
      const value = e.currentTarget.value;
      loadOptions(value);
    };

    useEffect(() => {
      if (!state.isLoading) {
        loadOptions('');
      }
    }, [params]);

    return (
      <AsyncSelect
        className="border-0"
        loadOptions={loadOptions}
        isMulti={isMulti === true ? true : undefined}
        defaultOptions={state.options}
        value={state.value}
        onChange={handleChange}
        onBlur={onBlur}
        isClearable={isClearable}
        placeholder={placeholder || 'Start typing ...'}
        isDisabled={readOnly}
        styles={styles}
        components={{ DropdownIndicator, ClearIndicator }}
        menuPlacement="auto"
        inputId={name}
        menuPortalTarget={menuPortalTarget ? document.body : null}
        isSearchable={isSearchable}
        isLoading={state.isLoading}
        onFocus={handleFocus}
      />
    );
  }

  return (
    <Select
      isMulti={isMulti === true ? true : undefined}
      options={options}
      value={value}
      onChange={(value: any) => onChange(value)}
      onBlur={onBlur}
      isClearable={isClearable}
      placeholder={placeholder}
      isDisabled={readOnly}
      styles={styles}
      components={{ DropdownIndicator, ClearIndicator }}
      menuPlacement="auto"
      inputId={name}
      menuPortalTarget={menuPortalTarget ? document.body : null}
      isSearchable={isSearchable}
    />
  );
};

export default MultiselectDropdown2;
