import React from 'react';

import {AutomaticResult} from 'types/output/setting';

type Props = {
    automatic?: AutomaticResult;
};

const Result: React.FC<Props> = ({automatic}) => {
    const name = automatic?.addUserToRole?.user.flatMap<string>(
        (user: any) => user?.label,
    );
    const role = automatic?.addUserToRole?.role.flatMap<string>(
        (role: any) => role?.label,
    );
    console.log('automatic', automatic);
    const sanitize = (text: string[] | undefined) => {
        if (!text) return;
        return String(text).replaceAll(',', ', ');
    };
    let action = <></>;
    if (automatic?.action === 'add_user_to_role') {
        action = <>
            {`Add `}
            <span className="italic">{`'${sanitize(name)} to the ${sanitize(
                role,
            )}'`}</span>{' '}
            {` role`}
        </>
    }
    if (automatic?.action === 'create_task') {
        let users = automatic.createTask.user.map((user: any) => {
            return user.label;
        }).join(', ')
        action = <>
            create task
            <span className="italic">'{automatic?.createTask.title}</span> for  the <span className="italic">{automatic.createTask.role ? automatic.createTask.role.label : users}'</span>
        </>
    }
    return <div>{action}</div>;
};

export default Result;
