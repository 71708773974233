import React from 'react';
import { Field, useFormikContext, FormikProps } from 'formik';

import { Button } from 'components/Button';
import { Dropdown, Option } from 'components/Dropdown';
import { Field as FieldWrapper } from 'components/Field';
import { Icon } from 'components/Icon';

import { Dynamic as DynamicField } from './Field/Dynamic';

import { translate } from 'utils/translations';
import { Tab, Config, SettingsForm } from 'types/output/setting';
import { askUser, automatic } from '@constants/settings';

type Props = {
  path: string;
  replace: (index: number, value: any) => void;
  remove: <T>(index: number) => T | undefined;
  push: (obj: any) => void;
  options: Option<string>[];
  config: Config[];
  values: any[];
};

const Then: React.FC<Props> = ({
  path,
  replace,
  remove,
  push,
  options,
  config,
  values,
}) => {
  const { values: formValues }: FormikProps<SettingsForm> = useFormikContext();

  const handleClick = async () => {
    if (formValues.results.tab === Tab.ASK_USER) {
      push(askUser);
    }

    if (formValues.results.tab === Tab.AUTOMATIC) {
      push(automatic);
    }
  };

  return (
    <>
      {values.map((value: any, index: number) => {
        const name = `${path}.${index}`;
        const firstItem = index === 0;

        return (
          <>
            {!firstItem && (
              <div className="text-gray-700 mb-5">{translate('and')}</div>
            )}
            <div className="flex mb-6">
              <div className="flex items-center items-baseline w-full flex-wrap">
                <div className="w-80 mr-9">
                  <Field name={`${name}.action`}>
                    {({ field, meta }: any) => (
                      <FieldWrapper
                        {...field}
                        errorMessage={meta.touched && meta.error}
                      >
                        <Dropdown
                          {...field}
                          onChange={(action) =>
                            replace(index, {
                              ...value,
                              action,
                            })
                          }
                          options={options}
                          placeholder={translate('Select an action')}
                          hasError={meta.touched && meta.error}
                        />
                      </FieldWrapper>
                    )}
                  </Field>
                </div>
                <DynamicField
                  path={name}
                  value={value}
                  onChange={(value) => replace(index, value)}
                  dependencies={config}
                />
              </div>
              {!firstItem && (
                <div
                  className="flex items-center ml-11 cursor-pointer"
                  onClick={() => remove(index)}
                >
                  <Icon name="close" size={6} />
                </div>
              )}
            </div>
          </>
        );
      })}
      <Button
        color="white"
        size="small"
        radius="full"
        icon="add"
        onClick={handleClick}
      >
        {translate('Add result')}
      </Button>
    </>
  );
};

export default Then;
