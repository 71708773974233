import React, {FC, useState} from 'react'

import {Form} from 'components/Form'
import {translate} from 'utils/translations'
import {AiCollection} from '../collections/New'
import {useRequest} from 'hooks/useRequest'
import {getOptions} from 'utils/form'

enum AiTasksType {
  DATA_EXTRACTION = 'Metadata Extraction',
  CLAUSE_COMPLIENCE = 'Clause complience',
  DCUMENT_COMPLIENCE = 'Document complience'
}

const TaskForm: FC<{fields?: any}> = ({fields}) => {

  const [fieldToggle, setFieldToggle] = useState(true)
  const [collections] = useRequest<AiCollection[]>('/api/ai/collections', [])
  const collectionsAsOptions = getOptions(collections)
  const types = getOptions(Object.values(AiTasksType))

  const init = {
    content_style: '.mce-content-body {padding : 7px}',
    height: 200,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
            'undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help'
  }

  return (
    <>
      <Form.TextInput
        name="name"
        label={translate('Task name')}
        className="mb-6"
        autoFocus={true}
      />
      <Form.TextTinyMce
        name="description"
        label={translate('Description')}
        className="mb-6"
        init={init}
        required
      />
      <Form.DynamicMultiSelect
        name="collections"
        label={translate('Collections')}
        placeholder={translate('Select Collections..')}
        options={collectionsAsOptions}
        autocomplete
      />
      <Form.Dropdown
        name="type"
        label={translate('Type')}
        options={types}
        onChange={(value) => setFieldToggle(value === 'Metadata Extraction')}
      />
      {fieldToggle && (
        <Form.Dropdown
          name="fields"
          label={translate('Meta Field')}
          options={fields}
          autocomplete
        />
      )}
    </>
  )
}

export default TaskForm
