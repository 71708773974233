import React, { FC, useContext, useState } from 'react';

import { AppContext } from 'contexts';
import { Form } from 'components/Form';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { FormTabs, Tab } from 'components/Tabs';
import ProjectTitle from 'components/form/ProjectTitle/Mutable';

import { useToasts } from 'hooks';
import { getWorkflow } from 'hooks/workflows';

import { Project } from '@contractool/schema';
import { FormTab } from '@contractool/schema/FormTab';

import { EventEmitter } from 'utils/eventEmitter';
import { http } from 'utils/http';
import { translate } from 'utils/translations';

import { DefaultForm } from '../newproject/DefaultForm';
import { Form as DellJIForm } from 'integrations/dell_ji/components/views/project/components';
import ProjectFormProvider from 'views/project/ProjectFormProvider';

type Props = {
  project: Project;
  tab?: string;
  titleEdited: boolean;
};

const FormContainer: FC<Props> = ({ project, tab, titleEdited }) => {
  const { config } = useContext(AppContext);

  return config.integration === 'dell_ji' ? (
    <ProjectFormProvider>
      <DellJIForm project={project} />
    </ProjectFormProvider>
  ) : (
    <ProjectFormProvider>
      <DefaultForm project={project} tab={tab} titleEdited={titleEdited} />
    </ProjectFormProvider>
  );
};

export const ProjectDetailsEdit = ({
  project,
  onUpdate,
  onClose,
}: {
  project: Project;
  onUpdate: () => void;
  onClose: () => void;
}) => {
  const { success } = useToasts();
  const { config } = useContext(AppContext);
  const [nextTab, goNextTab] = useState<string>();

  const workflow: string = project?.workflow || 'default';
  const [initValues, setInitValues] = useState({ ...project });
  const formTabs = getWorkflow(workflow).form_tabs;
  const settings = getWorkflow(workflow).settings;
  const [titleEdited, setTitleEdited] = useState<boolean>(false);

  const projectChange = (project: Project) => {
    setInitValues({ ...project });
  };
  EventEmitter.subscribe('project-changed', projectChange);

  const handleSelectTab = (name: string) => {
    const titleElement = document.getElementById('modal-title');
    if (titleElement) {
      titleElement.scrollIntoView({ behavior: 'smooth' });
    }
    goNextTab(name);
  };

  return (
    <Modal
      heading={translate('Edit Project Details')}
      onClose={onClose}
      size="w-4/5"
    >
      <Form
        initialValues={initValues}
        onSubmit={(values) => {
          const payload = JSON.parse(JSON.stringify(values));

          delete payload.logs_full;
          delete payload.parent;
          delete payload.children;
          delete payload.child;
          delete payload.team;
          payload.editing = true;
          payload.tab = nextTab;

          return http.put<Project>('/api/projects/' + project.id, payload);
        }}
        name="edit-project"
        onSuccess={() => {
          onClose();
          onUpdate();
          success(
            translate('Project Details of :title were updated successfully', {
              title: project.title,
            }),
          ); // TODO: remove this (already in backend)
        }}
        loader={'big'}
      >
        {formTabs.length > 0 ? (
          <>
            <ProjectTitle
              className="mb-8 mt-8"
              canEdit={settings.edit_title}
              titleEdited={() => {
                setTitleEdited(true);
              }}
              legend={settings.title_legend}
            />
            <FormTabs selected={nextTab} onSelect={handleSelectTab}>
              {formTabs.map((tab: FormTab, index: number, array: FormTab[]) => {
                let nextTabIndex = index;
                nextTabIndex++;

                const showNextButton = nextTabIndex !== array.length;

                return (
                  project && (
                    <Tab
                      key={tab?.id}
                      name={tab?.title}
                      heading={tab?.title}
                      className="w-full h-auto overflow-auto mb-20"
                    >
                      {config.integration && (
                        <FormContainer
                          project={project}
                          tab={tab?.id}
                          titleEdited={titleEdited}
                        />
                      )}
                      <Modal.Footer className="flex justify-between">
                        <Button color="white" onClick={onClose}>
                          {translate('Cancel')}
                        </Button>

                        {showNextButton && (
                          <Button
                            onClick={() =>
                              handleSelectTab(array[nextTabIndex]?.title)
                            }
                          >
                            {translate('Next Step')}
                          </Button>
                        )}

                        <Form.Submit>{translate('Save Changes')}</Form.Submit>
                      </Modal.Footer>
                    </Tab>
                  )
                );
              })}
            </FormTabs>
          </>
        ) : (
          <>
            <ProjectTitle
              className="mb-8 mt-8"
              canEdit={settings.edit_title}
              titleEdited={() => {
                setTitleEdited(true);
              }}
              legend={settings.title_legend}
            />
            {config.integration && (
              <FormContainer project={project} titleEdited={titleEdited} />
            )}
            <Modal.Footer className="flex justify-between">
              <Button color="white" onClick={onClose}>
                {translate('Cancel')}
              </Button>

              <Form.Submit>{translate('Save Changes')}</Form.Submit>
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  );
};
