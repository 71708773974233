import React, { FC, useContext, useEffect, useReducer, useState } from 'react';
import { Filter, FilterCondition, FilterTemplate } from '@contractool/schema';
import { useHistory } from 'react-router-dom';

import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { Dropdown, Option } from 'components/Dropdown';
import { Field } from 'components/Field';
import { TextInput } from 'components/TextInput';
import { DatePicker } from 'components/DatePicker';
import MultiselectDropdown2 from 'components/MultiselectDropdown2';
import FetchMultiSelect from 'components/select/FetchMultiSelect';
import { Icon } from 'components/Icon';

import { useToasts } from 'hooks';
import { useRequest } from 'hooks/useRequest';

import { Action, filterReducer } from 'utils/filterReducer';
import { http } from 'utils/http';
import { translate } from 'utils/translations';
import ConditionsMap from './ConditionsMap';
import { AppContext } from 'contexts';

export function FilterNew({
  heading,
  temporary = false,
  onSubmit,
}: {
  heading: string;
  temporary?: boolean;

  onSubmit: (id: Filter) => void;
}) {
  const { assessmentWorkflow } = useContext(AppContext);
  const history = useHistory();
  const { error, success } = useToasts();

  const [errors, setErrors] = useState<{ index: number; field: string }[]>([]);
  const [filterTemplates] = useRequest<FilterTemplate[]>(
    `/api/project-filter-templates?workflow=${assessmentWorkflow}`,
    [],
  );
  const [mapMenu, setMapMenu] = React.useState(false);

  let fc = localStorage.getItem('filterConditions');

  let defaultConditions = [{ id: 1, operation: '', subject: '', value: [] }];
  if (fc) {
    defaultConditions = JSON.parse(fc);
  }

  const [state, dispatch] = useReducer(filterReducer, defaultConditions);
  const [modal, setModal] = useState<{
    visible: boolean;
    value: string;
    error: boolean;
  }>({
    visible: false,
    value: '',
    error: false,
  });
  const listOfConditions = filterTemplates.map((template: FilterTemplate) => {
    return { label: template.label, value: template.name };
  });

  const close = () => {
    history.goBack();
  };
  const handelError = (err: any) => {
    const errorsToObject = () => {
      const split = Object.keys(err.response.data.errors)
        .map((item) => {
          return item.split('.');
        })
        .filter((item) => !item.includes('title'));

      return split.map((item) => {
        return { index: Number(item[1]), field: item[2] };
      });
    };
    setErrors(errorsToObject());
    setModal({
      ...modal,
      visible: false,
    });
    error(`${translate('The given data was invalid')}.`);
  };
  const submit = (message: string) => {
    if (!temporary && modal.value.length === 0) {
      setModal({ ...modal, error: true });
      error(`${translate('The given data was invalid')}.`);

      return;
    }

    let conditions = state.map((item: FilterCondition, index: number) => {
      return {
        id: index,
        operation: item.operation,
        subject: item.subject,
        value: item.value,
      };
    });

    localStorage.setItem('filterConditions', JSON.stringify(conditions));

    http
      .post('/api/project-filters?workflow=' + assessmentWorkflow, {
        title: modal.value,
        conditions,
      })
      .then((res: any) => {
        onSubmit(res.data);
        success(message);
      })
      .catch(handelError);
  };

  return (
    <Modal
      heading={heading}
      isOpen={true}
      onClose={close}
      size="regular"
      corner={
        <Button
          color="white"
          onClick={() => {
            localStorage.setItem('filterConditions', '');
            dispatch({ type: 'CLEAR' });
          }}
        >
          {translate('Clear filters')}
        </Button>
      }
    >
      <div>
        {!temporary && (
          <Field
            name="category_name"
            label={translate('Filter name')}
            className="mb-6"
            errorMessage={
              modal.error ? translate('Filter name is required') : undefined
            }
          >
            <TextInput
              name="category_name"
              placeholder={translate('Enter filter name')}
              value={modal.value}
              onChange={(e) => setModal({ ...modal, value: e, error: false })}
              hasError={modal.error}
              maxLength={32}
            />
          </Field>
        )}
        {state &&
          state.map((condition: FilterCondition, index) => {
            return (
              <div key={condition.id + condition.subject}>
                <div className="text-gray-600">
                  {index ? translate('and') : translate('Condition')}
                </div>
                <Condition
                  errors={errors.filter((error) => error.index === index)}
                  onChange={(field) => {
                    setErrors(
                      errors.filter(
                        (error) =>
                          !(error.field === field && error.index === index),
                      ),
                    );
                  }}
                  listOfConditions={listOfConditions}
                  condition={condition}
                  filterTemplates={filterTemplates}
                  dispatch={dispatch}
                />
              </div>
            );
          })}
        <div className="flex items-center mt-8 text-gray-600">
          <Button
            onClick={() => {
              dispatch({ type: 'ADD_CONDITION' });
              setMapMenu(true);
            }}
            color="white"
            size="small"
            radius="full"
            icon="add"
            className="mr-4"
          />
          {translate('Add condition')}
        </div>
        {mapMenu && (
          <ConditionsMap
            filterTemplates={filterTemplates}
            dispatch={dispatch}
            idx={state.length > 0 ? state[state.length - 1].id : 1}
            setOpenModal={setMapMenu}
          />
        )}
      </div>
      <div className="flex mt-16 justify-between">
        <Button color="white" onClick={close}>
          {translate('Cancel')}
        </Button>
        <div>
          {temporary ? (
            <>
              <Button
                className="mr-4"
                color="white"
                onClick={() => {
                  setModal({ ...modal, visible: true });
                }}
              >
                {translate('Save as filter')}
              </Button>
              <Button
                className="bg-blue-700"
                onClick={() =>
                  submit(`${translate('Filter successfully applied')}.`)
                }
              >
                {translate('Apply')}
              </Button>
            </>
          ) : (
            <Button
              className="bg-blue-700"
              onClick={() =>
                submit(`${translate('Filter successfully created')}.`)
              }
            >
              {translate('Create filter')}
            </Button>
          )}
        </div>
      </div>
      <Modal
        heading={translate('Save as filter')}
        isOpen={modal.visible}
        onClose={() => {
          setModal({ ...modal, visible: false });
        }}
        size="small"
      >
        <Field
          name="category_name"
          label={translate('Filter name')}
          errorMessage={modal.error ? 'Filter name is required' : undefined}
        >
          <TextInput
            name="category_name"
            placeholder={translate('Enter filter name')}
            value={modal.value}
            onChange={(e) => setModal({ ...modal, value: e, error: false })}
            hasError={modal.error}
            maxLength={32}
          />
        </Field>
        <div className="flex mt-16 justify-between">
          <Button
            color="white"
            onClick={() => {
              setModal({ ...modal, visible: false });
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (modal.value.length) {
                submit(`${translate('Filter successfully created')}.`);
              } else {
                setModal({ ...modal, error: true });
                error(`${translate('The given data was invalid')}.`);
              }
            }}
            className="bg-blue-700"
          >
            {translate('Save filter')}
          </Button>
        </div>
      </Modal>
    </Modal>
  );
}

export const Condition: FC<{
  condition: FilterCondition;
  listOfConditions: Option<string>[];
  filterTemplates: FilterTemplate[];
  onChange: (field: string) => void;
  dispatch: ({ type }: Action) => void;
  errors: { index: number; field: string }[];
}> = ({
  condition,
  listOfConditions,
  filterTemplates,
  onChange,
  dispatch,
  errors,
}) => {
  const filterTemplate = filterTemplates.find(
    (template) => template.name === condition.subject,
  );
  console.log('filterTemplate ', filterTemplate, condition);
  const { operations, values, type, props } = filterTemplate || {};
  const [mapMenu, setMapMenu] = React.useState(false);

  const getError = (name: string) => {
    return Object.values(errors)
      .map((err) => err.field)
      .includes(name);
  };

  return (
    <div className="flex mb-6">
      <div className="w-2/5 mr-4">
        {listOfConditions.length > 0 && (
          <div
            id={`condition_property_${condition.id}`}
            onClick={() => {
              setMapMenu(true);
              onChange('subject');
            }}
            className={`truncate h-16 py-5 w-full leading-tighter outline-none focus:outline-none focus:border-blue-700 text-gray-700 border-b cursor-pointer ${
              condition.subject && condition.subject.length
                ? 'text-gray-600'
                : ''
            }`}
          >
            {condition.subject && condition.subject.length
              ? listOfConditions.find((c) => c.value === condition.subject)
                  ?.label
              : 'Condition...'}
          </div>
        )}
      </div>
      {mapMenu && (
        <ConditionsMap
          filterTemplates={filterTemplates}
          dispatch={dispatch}
          condition={condition}
          idx={condition.id}
          setOpenModal={setMapMenu}
        />
      )}
      <div className="w-1/5 mr-4">
        {operations && (
          <Dropdown
            name={`operations_${condition.id}`}
            value={condition.operation}
            options={operations}
            placeholder={translate('Operation...')}
            onChange={(operation) => {
              dispatch({
                type: 'UPDATE_CONDITION_OPERATION',
                id: condition.id,
                operation,
              });
              onChange('operation');
            }}
            autocomplete={true}
            hasError={getError('operation')}
          />
        )}
      </div>

      <div className="w-2/5">
        {type && condition.operation !== 'EMPTY' && condition.operation !== 'NOT_EMPTY' && (
          <InputTypeSwitch
            name={`values_${condition.id}`}
            value={condition.value}
            options={values}
            props={props}
            type={type}
            onChange={(value) => {
              dispatch({
                type: 'UPDATE_CONDITION_VALUES',
                id: condition.id,
                value,
              });
              onChange('value');
            }}
            hasError={getError('value')}
          />
        )}
      </div>
      <div className="w-1/6 flex flex-row-reverse items-center">
        <div
          onClick={() => {
            dispatch({ type: 'REMOVE_CONDITION', id: condition.id });
          }}
          className="cursor-pointer	"
        >
          <Icon name="close" size={4} />
        </div>
      </div>
    </div>
  );
};

export const InputTypeSwitch: FC<{
  name: string;
  value: any;
  options: any;
  type: string;
  onChange: (res: any) => void;
  onBlur?: () => void;
  hasError?: boolean;
  props?: object;
}> = ({ name, value, options, type, onChange, onBlur, hasError, props }) => {
  const [text, setText] = useState<string>();
  const [number, setNumber] = useState<number | string>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  useEffect(() => {
    switch (type) {
      case 'TEXT': {
        setText(value);
        break;
      }
      case 'NUMBER': {
        setNumber(value);
        break;
      }
      case 'DATE_RANGE': {
        setStartDate(value[0]);
        setEndDate(value[1]);
      }
    }
  }, [type, value]);

  switch (type) {
    case 'SELECT': {
      return (
        <MultiselectDropdown2
          value={options.filter((option: any) => value.includes(option.value))}
          name={name}
          options={options}
          placeholder={`${translate('Values')} ...`}
          onChange={(res: Option<any>[]) => {
            onChange(res.map((res) => res.value));
          }}
          onBlur={onBlur}
          menuPortalTarget
          hasError={hasError}
        />
      );
    }
    case 'FETCH_MULTI_SELECT': {
      return (
        <FetchMultiSelect
          values={value}
          name={name}
          placeholder={`${translate('Values')} ...`}
          onChange={(res: Option<any>[]) => {
            onChange(res.map((res) => res.value));
          }}
          onBlur={onBlur}
          usePortal={true}
          hasError={hasError}
          {...props}
        />
      );
    }
    case 'BOOLEAN_SELECT': {
      return (
        <Dropdown
          name={name}
          value={value}
          options={options}
          placeholder={`${translate('Value')} ...`}
          onChange={onChange}
          autocomplete={true}
          hasError={hasError}
        />
      );
    }
    case 'BIG_ADVANCED_RADIO': {
      return (
        <MultiselectDropdown2
          value={options.filter((option: any) => value.includes(option.value))}
          name={name}
          options={options}
          placeholder={`${translate('Values')} ...`}
          onChange={(res: Option<any>[]) => {
            onChange(res.map((res) => res.value));
          }}
          onBlur={onBlur}
          menuPortalTarget
          hasError={hasError}
        />
      );
    }
    case 'TEXT': {
      return (
        <TextInput
          name={name}
          placeholder={`${translate('Value')} ...`}
          value={text}
          onChange={(input) => {
            onChange(input);
            setText(input);
          }}
          onBlur={onBlur}
          hasError={hasError}
        />
      );
    }
    case 'DATE_RANGE': {
      return (
        <div className="flex">
          <DatePicker
            id={name}
            name={`${name}_start_date`}
            value={startDate || null}
            onChange={(date) => {
              date && setStartDate(date);
              onChange([date, endDate]);
            }}
            onBlur={onBlur}
            hasError={hasError}
          />
          <DatePicker
            id={name}
            name={`${name}_end_date`}
            value={endDate || null}
            onChange={(date) => {
              date && setEndDate(date);
              onChange([startDate, date]);
            }}
            onBlur={onBlur}
            hasError={hasError}
          />
        </div>
      );
    }
    case 'NUMBER': {
      return number ? (
        <div className="relative">
          <input
            name={name}
            type="number"
            placeholder={`${translate('Number')}...`}
            value={number}
            onChange={(e) => {
              const value = e.target.valueAsNumber;
              if (isNaN(value)) {
                setNumber('');
                onChange(null);
              } else {
                onChange(value);
                setNumber(value);
              }
            }}
            onBlur={onBlur}
            className={`bg-transparent-000 py-5 w-full focus:outline-none border-b leading-none text-gray-700 placeholder-gray-400 ${
              hasError
                ? 'border-red-700'
                : 'border-gray-200 focus:border-blue-700'
            } `}
          />
        </div>
      ) : null;
    }
    default:
      return null;
  }
};
