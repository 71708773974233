import React, { FC } from 'react';
import {
  ActivityBody,
  ActivityContainer,
  ActivityHeader,
} from '../ActivityPanel';
import { LogFull } from '@contractool/schema';
import { Icon } from 'components/Icon';
import { translate } from 'utils/translations';

export const EmailLog: FC<{
  desc: string;
  log: LogFull;
}> = ({ desc, log }) => {
  return (
    <ActivityContainer>
      <ActivityHeader
        causer={log.data?.receiver}
        extraName={` (${log.data?.receiver.email})`}
        desc={desc}
        created_at={log.created_at}
      />
      <ActivityBody>
        <div className="text-gray-700">
          {translate('Subject')}: {log.data?.subject}
        </div>
        <div
          className="text-gray-700 leading-8 my-6"
          dangerouslySetInnerHTML={{
            __html: log.data?.body,
          }}
        ></div>
      </ActivityBody>
      {log.data?.attachments?.map((attachment: any) => (
        <ActivityBody className="bg-gray-100 rounded-lg p-5 inline-block">
          <div className="flex items-center">
            <span className="mr-4">
              <Icon name="word" size={6} className="text-blue-600" />
            </span>
            {attachment.title}
          </div>
        </ActivityBody>
      ))}
    </ActivityContainer>
  );
};
