import { LogFull, Comment } from '@contractool/schema';
import React, { FC, FormEvent, useState } from 'react';

import { CommentForm } from 'components/CommentForm';
import { useToasts } from 'hooks';
import {
  ActivityBody,
  ActivityContainer,
  ActivityHeader,
} from 'views/projects/ActivityPanel';
import { http, Response } from 'utils/http';
import { byOldestUp } from 'utils/sort';
import { useSmallLoader } from 'components/Loader';
import { translate } from 'utils/translations';

export const ConversationLog: FC<{
  log: LogFull;
  onUpdate: () => void;
  onSendFile: (file: { path: string; name: string }) => Promise<Response<any>>;
}> = ({ log, onUpdate, onSendFile }) => {
  return (
    <ActivityContainer>
      <>
        <ActivityHeader causer={log.causer} created_at={log.created_at} />
        <ActivityBody className="mb-8 mr-14">
          <div className="whitespace-pre-wrap">
            {log.message.body && bodyParser(log.message.body)}
          </div>
        </ActivityBody>

        {(log.comments ?? [])
          .sort((a: Comment, b: Comment) =>
            byOldestUp(a.created_at, b.created_at),
          )
          .map((comment, index) => (
            <ReplyComment
              key={'reply-messages' + log.id + index}
              comment={comment}
            />
          ))}

        <ReplyMessage log={log} onUpdate={onUpdate} onSendFile={onSendFile} />
      </>
    </ActivityContainer>
  );
};

const ReplyComment: FC<{
  comment: Comment;
}> = ({ comment }) => {
  return (
    <div className="ml-12">
      <ActivityHeader
        causer={comment.commenter}
        created_at={comment.created_at}
      />
      <ActivityBody className="mb-8 pr-3">
        <div className="whitespace-pre-wrap">{bodyParser(comment.body)}</div>
      </ActivityBody>
    </div>
  );
};

const ReplyMessage: FC<{
  log: LogFull;
  fake_log?: { id: number };
  onUpdate: () => void;
  onSendFile: (file: { path: string; name: string }) => Promise<Response<any>>;
}> = ({ log, onUpdate, onSendFile }) => {
  const [comment, setComment] = useState('');
  const { success } = useToasts();
  const smallLoader = useSmallLoader();
  const submitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    smallLoader.start(translate('Sending the message...'));
    http
      .post<Comment>(log.comments_url, { body: comment, type: log.meta.type })
      .then((response) => {
        onUpdate();
        setComment('');
        success(
          `Comment was successfully added by ${response.data.commenter.name}.`,
        );
        smallLoader.stop();
      });
  };

  return (
    <CommentForm
      submitHandler={submitHandler}
      comment={comment}
      setComment={setComment}
      onSendFile={onSendFile}
    />
  );
};

//TODO: Fix when people append some bullsh*t text after mentioning a person
const bodyParser = (body: string) => {
  const result = body.replace(/@[a-zA-Z0-9_.]+(?<![_.])/g, (username) => {
    return `<span class='font-medium text-blue-500'>${username}</span>`;
  });

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: result,
      }}
    />
  );
};
