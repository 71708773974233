import React from 'react';
import Select, {components, DropdownIndicatorProps} from 'react-select';

import {Icon} from 'components/Icon';

type Props = {
    value: number | null;
    onChange: (year: number | null) => void;
    placeholder?: string;
    id: string;
    max: number;
    readOnly?: boolean;
};

const YearSelect: React.FC<Props> = ({value, onChange, placeholder, id, max, readOnly}) => {
    const styles = {
        control: (base: any, state: any) => ({
            ...base,
            border: 'none',
            padding: '9px 0',
            borderRadius: '0',
            borderBottom: state.isFocused ? '1px solid #2684FF' : '1px solid #dee2e6',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            cursor: 'pointer',
            ':hover': {
                ...base[':hover'],
                borderBottom: state.isFocused ? '1px solid #2684FF' : '1px solid #dee2e6',
            },
        }),
        menu: (base: any) => ({
            ...base,
            padding: '6px',
            margin: '0',
            borderRadius: '5px',
            boxShadow: '0 0 0 0px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)',
        }),
        menuList: (base: any) => ({
            ...base,
            padding: '0',
        }),
        option: (base: any, state: any) => ({
            ...base,
            cursor: 'pointer',
            backgroundColor: state.isFocused || state.isSelected ? '#f1f3f5' : 'white',
            borderRadius: '3px',
            color: state.isSelected ? '#2684FF' : 'inherit',
        }),
        indicatorSeparator: (base: any) => ({
            ...base,
            display: 'none',
        }),
        valueContainer: (base: any) => ({
            ...base,
            padding: '0',
        }),
    };

    const currentYear = new Date().getFullYear();
    const maxYear = currentYear + max;
    const options = [];
    let year = 2021;

    while (year < maxYear) {
        options.push({
            value: year,
            label: year,
        });
        year++;
    }

    const option = value
        ? {
              value: value,
              label: value,
          }
        : null;

    const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
        return (
            <components.DropdownIndicator {...props}>
                <Icon
                    name="triangle"
                    className={`text-gray-600 ${props.isFocused ? '' : 'rotate-180'}`}
                    size={2}
                />
            </components.DropdownIndicator>
        );
    };

    return (
        <Select
            id={`year-select-${id}`}
            options={options}
            value={option}
            onChange={({value}: any) => onChange(value)}
            placeholder={placeholder}
            isDisabled={readOnly}
            styles={styles}
            components={{DropdownIndicator}}
        />
    );
};

export default YearSelect;
