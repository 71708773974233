import React from 'react';
import {User} from '@contractool/schema';
import {translate} from 'utils/translations';

export function UserStateLabel({user, className}: {user: User; className?: string}) {
    switch (user.state) {
        case 'deactivated':
            return (
                <div
                    className={`px-3 py-2 rounded text-sm leading-none select-none text-gray-600 bg-gray-100 inline ${className}`}
                >
                    {translate('Deactivated')}
                </div>
            );
        case 'deleted':
            return (
                <div
                    className={`px-3 py-2 text-sm leading-none select-none text-red-600 inline ${className}`}
                >
                    {translate('Deleted')}
                </div>
            );
        default:
            return null;
    }
}
