import React, { FC } from 'react';
import { Icon } from '../../../components/Icon';
import {
  ActivityContainer,
  ActivityHeader,
  ActivityBody,
} from '../ActivityPanel';
import { LogFull } from '@contractool/schema';

export const StateLog: FC<{
  log: LogFull;
}> = ({ log }) => {
  // const iconFrom = iconHandler(log.message.extra.from);
  // const iconTo = iconHandler(log.message.extra.to);

  return (
    <ActivityContainer>
      <ActivityHeader
        causer={log.causer}
        desc={log.message?.title}
        created_at={log.created_at}
      />
      <ActivityBody className="flex items-center">
        <>
          {/* TODO: Find out which icons to use */}
          {/* {iconFrom} */}
          {log.message.extra.direction === 'progress'
            ? log.message.extra.from
            : log.message.extra.to}
          <Icon
            name={
              log.message.extra.direction === 'progress'
                ? 'arrow_forward'
                : 'arrow_back'
            }
            size={6}
            className="text-gray-600 mx-8"
          />
          {/* {iconTo} */}
          <span className="text-blue-700">
            {log.message.extra.direction === 'progress'
              ? log.message.extra.to
              : log.message.extra.from}
          </span>
        </>
      </ActivityBody>
    </ActivityContainer>
  );
};
