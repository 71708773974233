import moment from 'moment';
import React, { useContext } from 'react';

import { CurrencyInput } from 'components/form/inputs';
import { Switch } from 'components/Switch';
import { translate } from 'utils/translations';

import YearSelect from './YearSelect';
import MonthSelect from './MonthSelect';
import SavingInput from './SavingInput';
import { TMonth, IValue, IRecordWithSum } from './types';
import _ from 'lodash';
import { GuardContext } from 'contexts';
import { isUserInGroup } from 'utils/auth';
import { Dropdown } from 'components/Dropdown';
import { useToasts } from 'hooks';

const monthNames = moment.monthsShort();
const months = monthNames.map((...args) => args[1] + 1);

interface IMonthlySavingsRange {
  startYear: number;
  startMonth: number;
  endYear: number;
  endMonth: number;
}

interface IMonthlySavingsProps {
  value: IValue | null;
  onChange: (v: IValue | null) => void;
  ideaMonthlyInflation?: IValue | null;
  onChangeInflation?: (v: IValue | null) => void;
  ideaMonthlyDeflation?: IValue | null;
  onChangeDeflation?: (v: IValue | null) => void;
  showInflationOrDeflation: number;
  readOnly?: boolean;
}

const getYears = (startYear: number, endYear: number) => {
  if (endYear >= startYear) {
    return Array.from(
      { length: endYear - startYear + 1 },
      (_, i) => i + startYear,
    );
  }

  return [];
};

const getYearLabel = (year: number) => {
  return `${year}y`;
};

const parseYearLabel = (yearLabel: string) => {
  return Number(yearLabel.slice(0, -1));
};

const getMonthLabel = (month: number) => {
  return `${month}m`;
};

const parseMonthLabel = (yearLabel: string) => {
  return Number(yearLabel.slice(0, -1));
};

const getValue = (
  range: IMonthlySavingsRange,
  originalValue: IValue | null,
) => {
  const final = originalValue?.final ?? 0;
  const equalDistribution = originalValue?.equalDistribution ?? true;

  const template = {
    final,
    equalDistribution,
  } as IValue;

  const { startYear, endYear } = range;

  let getCellValue: (() => number) | ((year: number, month: number) => number);
  if (equalDistribution) {
    const { startMonth, endMonth } = range;
    let count = (endYear - startYear) * 12 + endMonth - startMonth + 1;

    const avg = Math.round(final / count);
    const lastMonthValue = final - (count - 1) * avg;
    getCellValue = () => {
      count--;
      if (count === 0) {
        return lastMonthValue;
      } else {
        return avg;
      }
    };
  } else {
    getCellValue = (year: number, month: number) => {
      if (originalValue?.years) {
        const originalCellValue = (
          originalValue.years[getYearLabel(year)] as IRecordWithSum
        )?.months[getMonthLabel(month) as TMonth];

        return originalCellValue ? originalCellValue : 0;
      }

      return 0;
    };
  }

  const years = {} as {
    [key: string]: IRecordWithSum;
  };
  let sum = 0;
  getYears(startYear, endYear).forEach((year) => {
    years[getYearLabel(year)] = {
      months: {},
      sum: 0,
    };
    months.forEach((month: number) => {
      if (
        !(year === startYear && month < range.startMonth) &&
        !(year === endYear && month > range.endMonth)
      ) {
        const cellValue = getCellValue(year, month);
        years[getYearLabel(year)].months[getMonthLabel(month) as TMonth] =
          cellValue;
        years[getYearLabel(year)].sum += cellValue;
      }
    });
    sum += years[getYearLabel(year)].sum;
  });
  template.years = years;
  template.sum = sum;

  return template;
};

const refreshYearSummary: (value: IValue, year: number) => void = (
  value,
  year,
) => {
  if (!value.years) {
    return;
  }

  let sum = 0;
  const months = Object.keys(value.years[getYearLabel(year)].months).map(
    parseMonthLabel,
  );
  months.forEach((month) => {
    sum += value.years
      ? value.years[getYearLabel(year)].months[
          getMonthLabel(month) as TMonth
        ] ?? 0
      : 0;
  });

  value.years[getYearLabel(year)].sum = sum;
};

const refreshSum: (value: IValue) => void = (value) => {
  if (!value.years) {
    return;
  }

  let sum = 0;
  const years = Object.keys(value.years).map(parseYearLabel);
  years.forEach((year) => {
    sum += value.years ? value.years[getYearLabel(year)].sum : 0;
  });

  value.sum = sum;
};

const parseRange: (value: IValue | null) => IMonthlySavingsRange | null = (
  value,
) => {
  if (null === value) {
    return null;
  }
  if (!value.years) {
    return null;
  }

  const years = Object.keys(value.years).map(parseYearLabel);
  const startYear = Math.min(...years);
  const endYear = Math.max(...years);

  const startYearMonthRecords = value.years[getYearLabel(startYear)].months;
  const startYearMonths = Object.keys(startYearMonthRecords).map(
    parseMonthLabel,
  );
  const startMonth = Math.min(...startYearMonths);

  const endYearMonthRecords = value.years[getYearLabel(endYear)].months;
  const endYearMonths = Object.keys(endYearMonthRecords).map(parseMonthLabel);
  const endMonth = Math.max(...endYearMonths);

  return { startYear, startMonth, endYear, endMonth };
};

const defaultRange = {
  startYear: null,
  startMonth: null,
  endYear: null,
  endMonth: null,
};

const MonthlySavings: React.FC<IMonthlySavingsProps> = ({
  value,
  onChange,
  onChangeInflation,
  ideaMonthlyInflation,
  ideaMonthlyDeflation,
  onChangeDeflation,
  showInflationOrDeflation,
  readOnly,
}) => {
  // computed cache parsedRange strongly depends on prop - value
  // 'value || null' is because default value of form field is ''
  // todo: 'value || null' can be updated to 'value' after form field default value is updated from '' to null
  const parsedRange = React.useMemo(() => parseRange(value || null), [value]);
  const { error } = useToasts();

  // state
  const [range, setRange] = React.useState<{
    startYear: null | number;
    startMonth: null | number;
    endYear: null | number;
    endMonth: null | number;
  }>(parsedRange ?? defaultRange);
  // range strongly depends on parsedRange - cache
  React.useEffect(() => {
    setRange(parsedRange ?? defaultRange);
  }, [parsedRange]);

  // calculations
  const { startYear, startMonth, endYear, endMonth } = range;

  const previousQuarterEnd = React.useMemo(() => {
    const previousQuarterEnd = new Date();
    const month = new Date().getMonth();
    const previousQuarterMonth = Math.floor(month / 3) * 3 - 1;
    previousQuarterEnd.setMonth(previousQuarterMonth);
    previousQuarterEnd.setDate(14);

    return previousQuarterEnd;
  }, []);

  const validation = () =>
    setRange((previousRange) => {
      const { startYear, startMonth, endYear, endMonth } = previousRange;

      const startDate = new Date();
      const endDate = new Date();

      if (startYear) {
        startDate.setFullYear(startYear);
      }
      if (startMonth) {
        startDate.setMonth(startMonth);
      }
      if (startDate < previousQuarterEnd && !hasSuperPower) {
        error(
          `${translate(
            'It is not allowed to select the date from the previous fiscal quarter.',
          )}`,
        );

        if (startYear && !startMonth) {
          return { ...previousRange, startYear: null };
        }
        if (startMonth) {
          return { ...previousRange, startMonth: null };
        }
      }

      if (endYear) {
        endDate.setFullYear(endYear);
      }
      if (endMonth) {
        endDate.setMonth(endMonth);
      }
      if (endDate < startDate) {
        error(
          `${translate('End date should be always greater than start date.')}`,
        );

        if (endYear && !endMonth) {
          return { ...previousRange, endYear: null };
        }
        if (endMonth) {
          return { ...previousRange, endMonth: null };
        }
      }

      return { ...previousRange };
    });

  const handleStartYearChange = (startYear: number | null) => {
    setRange((previousRange) =>
      previousRange.startYear !== startYear
        ? { ...previousRange, startYear }
        : previousRange,
    );
    validation();
  };

  const handleStartMonthChange = (startMonth: number | null) => {
    setRange((previousRange) =>
      previousRange.startMonth !== startMonth
        ? { ...previousRange, startMonth }
        : previousRange,
    );
    validation();
  };

  const handleEndYearChange = (endYear: number | null) => {
    setRange((previousRange) =>
      previousRange.endYear !== endYear
        ? { ...previousRange, endYear }
        : previousRange,
    );
    validation();
  };

  const handleEndMonthChange = (endMonth: number | null) => {
    setRange((previousRange) =>
      previousRange.endMonth !== endMonth
        ? { ...previousRange, endMonth }
        : previousRange,
    );
    validation();
  };

  // state
  const [valueState, setValueState] = React.useState<IValue | null>(value);
  const [inflationState, setInflationState] = React.useState<IValue | null>(
    ideaMonthlyInflation ? ideaMonthlyInflation : null,
  );
  const [deflationState, setDeflationState] = React.useState<IValue | null>(
    ideaMonthlyDeflation ? ideaMonthlyDeflation : null,
  );
  const [showInflationOrDeflationValue, setShowInflationOrDeflationValue] =
    React.useState<number>(showInflationOrDeflation);

  // valueState strongly depends on prop - value
  React.useEffect(() => {
    setValueState(value ? JSON.parse(JSON.stringify(value)) : value);
  }, [value]);

  React.useEffect(() => {
    setInflationState(
      ideaMonthlyInflation
        ? JSON.parse(JSON.stringify(ideaMonthlyInflation))
        : ideaMonthlyInflation,
    );
  }, [ideaMonthlyInflation]);

  React.useEffect(() => {
    setDeflationState(
      ideaMonthlyDeflation
        ? JSON.parse(JSON.stringify(ideaMonthlyDeflation))
        : ideaMonthlyDeflation,
    );
  }, [ideaMonthlyDeflation]);

  // methods
  const handleInputChange = React.useCallback((value: string) => {
    setValueState((previousValueState) => ({
      ...previousValueState,
      final: Number(value) || null,
    }));
  }, []);

  const handleInflationhange = React.useCallback((value: string) => {
    setInflationState((previousValueState) => ({
      ...previousValueState,
      final: Number(value) || null,
    }));
  }, []);

  const handleDeflationonhange = React.useCallback((value: string) => {
    setDeflationState((previousValueState) => ({
      ...previousValueState,
      final: Number(value) || null,
    }));
  }, []);

  const toggleEqualDistribution = React.useCallback((value: boolean) => {
    setValueState((previousValueState) => ({
      ...previousValueState,
      equalDistribution: value,
    }));
  }, []);
  const toggleEqualDistributionInflation = React.useCallback(
    (value: boolean) => {
      setInflationState((previousValueState) => ({
        ...previousValueState,
        equalDistribution: value,
      }));
    },
    [],
  );
  const toggleEqualDistributionDeflation = React.useCallback(
    (value: boolean) => {
      setDeflationState((previousValueState) => ({
        ...previousValueState,
        equalDistribution: value,
      }));
    },
    [],
  );

  const handleCellChange = (year: number, month: number, input: string) => {
    if (valueState === null) {
      return;
    }
    if (!valueState.years) {
      return;
    }

    const numericValue = Number(input);
    valueState.years[getYearLabel(year)].months[
      getMonthLabel(month) as TMonth
    ] = isNaN(numericValue) ? 0 : numericValue;
    refreshYearSummary(valueState, year);
    refreshSum(valueState);
    setValueState({ ...valueState });
  };

  const handleInflationCellChange = (
    year: number,
    month: number,
    input: string,
  ) => {
    if (inflationState === null) {
      return;
    }
    if (!inflationState.years) {
      return;
    }
    if (Number(input) >= 0) {
      input = '-' + input;
    }
    const numericValue = Number(input);

    inflationState.years[getYearLabel(year)].months[
      getMonthLabel(month) as TMonth
    ] = isNaN(numericValue) ? 0 : numericValue;
    refreshYearSummary(inflationState, year);
    refreshSum(inflationState);
    setInflationState({ ...inflationState });
  };

  const handleDeflationCellChange = (
    year: number,
    month: number,
    input: string,
  ) => {
    if (deflationState === null) {
      return;
    }
    if (!deflationState.years) {
      return;
    }

    const numericValue = Number(input);

    deflationState.years[getYearLabel(year)].months[
      getMonthLabel(month) as TMonth
    ] = isNaN(numericValue) ? 0 : numericValue;
    refreshYearSummary(deflationState, year);
    refreshSum(deflationState);
    setDeflationState({ ...deflationState });
  };

  const handleDropdownChange = (value: number) => {
    if (value == 0) {
      setDeflationState(null);
      setInflationState(null);
      //@ts-ignore
      onChangeInflation(null);
      //@ts-ignore
      onChangeDeflation(null);
    }
    if (value == 1) {
      setDeflationState(null);
      //@ts-ignore
      onChangeDeflation(null);
    }
    if (value == 2) {
      setInflationState(null);
      //@ts-ignore
      onChangeInflation(null);
    }
    setShowInflationOrDeflationValue(value);
  };

  const isRangeReady =
    startYear &&
    startMonth &&
    endYear &&
    endMonth &&
    (startYear < endYear || (startYear === endYear && startMonth <= endMonth));

  // effects
  React.useEffect(() => {
    if (isRangeReady) {
      const range = parseRange(value);
      if (
        range?.startYear !== startYear ||
        range?.startMonth !== startMonth ||
        range?.endYear !== endYear ||
        range?.endMonth !== endMonth ||
        value?.equalDistribution !== valueState?.equalDistribution ||
        value?.final !== valueState?.final ||
        value?.sum !== valueState?.sum
      ) {
        const updatedValue = getValue(
          {
            startYear,
            startMonth,
            endYear,
            endMonth,
          } as IMonthlySavingsRange,
          valueState,
        );
        onChange(updatedValue);
      }
    }
  }, [
    isRangeReady,
    startYear,
    startMonth,
    endYear,
    endMonth,
    onChange,
    value,
    valueState,
  ]);

  React.useEffect(() => {
    if (isRangeReady && showInflationOrDeflationValue == 1) {
      // @ts-ignore
      const range = parseRange(ideaMonthlyInflation);
      if (
        range?.startYear !== startYear ||
        range?.startMonth !== startMonth ||
        range?.endYear !== endYear ||
        range?.endMonth !== endMonth ||
        ideaMonthlyInflation?.equalDistribution !==
          inflationState?.equalDistribution ||
        ideaMonthlyInflation?.final !== inflationState?.final ||
        ideaMonthlyInflation?.sum !== inflationState?.sum
      ) {
        const updatedValue = getValue(
          {
            startYear,
            startMonth,
            endYear,
            endMonth,
          } as IMonthlySavingsRange,
          inflationState,
        );
        // @ts-ignore
        onChangeInflation(updatedValue);
      }
    }
  }, [
    isRangeReady,
    startYear,
    startMonth,
    endYear,
    endMonth,
    onChangeInflation,
    ideaMonthlyInflation,
    inflationState,
    showInflationOrDeflationValue,
  ]);

  React.useEffect(() => {
    if (isRangeReady && showInflationOrDeflationValue == 2) {
      // @ts-ignore
      const range = parseRange(ideaMonthlyDeflation);
      if (
        range?.startYear !== startYear ||
        range?.startMonth !== startMonth ||
        range?.endYear !== endYear ||
        range?.endMonth !== endMonth ||
        ideaMonthlyDeflation?.equalDistribution !==
          deflationState?.equalDistribution ||
        ideaMonthlyDeflation?.final !== deflationState?.final ||
        ideaMonthlyDeflation?.sum !== deflationState?.sum
      ) {
        const updatedValue = getValue(
          {
            startYear,
            startMonth,
            endYear,
            endMonth,
          } as IMonthlySavingsRange,
          deflationState,
        );
        // @ts-ignore
        onChangeDeflation(updatedValue);
      }
    }
  }, [
    isRangeReady,
    startYear,
    startMonth,
    endYear,
    endMonth,
    onChangeDeflation,
    ideaMonthlyDeflation,
    deflationState,
    showInflationOrDeflationValue,
  ]);

  const maxYear = React.useMemo(() => 4, []);

  const { user } = useContext(GuardContext);
  const hasSuperPower =
    isUserInGroup(user, 'admin') || isUserInGroup(user, 'finance_approver');

  return (
    <div className="monthly-savings">
      <div style={{ display: 'flex', flexDirection: 'row' }} className="mb-18">
        <table>
          <thead></thead>
          <tbody>
            <tr>
              <th
                style={{ fontWeight: 400, textAlign: 'left', width: '200px' }}
              >
                Negotiated saving
              </th>
              <th className="w-80">
                <div className="w-58">
                  <Dropdown
                    name="month"
                    value={showInflationOrDeflationValue}
                    onChange={handleDropdownChange}
                    options={[
                      { label: 'No inflation / deflation', value: 0 },
                      { label: 'Final inflation', value: 1 },
                      { label: 'Final deflation', value: 2 },
                    ]}
                    readOnly={readOnly}
                  ></Dropdown>
                </div>
              </th>
            </tr>
            <tr>
              <td>
                <div className="mb-6 w-64">
                  <CurrencyInput
                    name="values"
                    value={String(valueState?.final ?? '')}
                    locale="en-US"
                    currency="USD"
                    onChange={handleInputChange}
                    className="py-5 focus:outline-none border-b leading-none text-gray-700 placeholder-gray-400 border-gray-200 focus:border-blue-700"
                    readOnly={readOnly}
                  />
                </div>
              </td>
              <td>
                {showInflationOrDeflationValue == 1 && (
                  <div className="mb-6 w-64 inflation-input">
                    <CurrencyInput
                      name="values"
                      value={String(inflationState?.final ?? '')}
                      locale="en-US"
                      currency="USD"
                      onChange={handleInflationhange}
                      className="py-5 focus:outline-none border-b leading-none text-gray-700 placeholder-gray-400 border-gray-200 focus:border-blue-700"
                      readOnly={readOnly}
                      negativeValue
                    />
                  </div>
                )}
                {showInflationOrDeflationValue == 2 && (
                  <div className="mb-6 w-64 deflation-input">
                    <CurrencyInput
                      name="values"
                      value={String(deflationState?.final ?? '')}
                      locale="en-US"
                      currency="USD"
                      onChange={handleDeflationonhange}
                      className="py-5 focus:outline-none border-b leading-none text-gray-700 placeholder-gray-400 border-gray-200 focus:border-blue-700"
                      readOnly={readOnly}
                    />
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <Switch
                  value={valueState?.equalDistribution ?? true}
                  onChange={toggleEqualDistribution}
                  readOnly={readOnly}
                >
                  Equal distribution
                </Switch>
              </td>
              <td>
                {showInflationOrDeflationValue == 1 && (
                  <Switch
                    value={inflationState?.equalDistribution ?? true}
                    onChange={toggleEqualDistributionInflation}
                    readOnly={readOnly}
                  >
                    Equal distribution inflation
                  </Switch>
                )}
                {showInflationOrDeflationValue == 2 && (
                  <Switch
                    value={deflationState?.equalDistribution ?? true}
                    onChange={toggleEqualDistributionDeflation}
                    readOnly={readOnly}
                  >
                    Equal distribution deflation
                  </Switch>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="monthly-savings__range">
          <div className="monthly-savings__range-start">
            <div
              className="flex w-70 items-center justify-between"
              data-testid="start"
            >
              <span>{translate('Start year: ')}</span>
              <div className={'w-40'}>
                <YearSelect
                  id="startyear"
                  value={startYear}
                  onChange={handleStartYearChange}
                  placeholder="Year Start"
                  max={maxYear}
                  readOnly={readOnly}
                />
              </div>
            </div>
            <div
              className="flex w-70 items-center justify-between"
              data-testid="start month"
            >
              <span>{translate('Start month: ')}</span>
              <div className="w-40">
                <MonthSelect
                  id="startmonth"
                  value={startMonth}
                  onChange={handleStartMonthChange}
                  placeholder="Month Start"
                  readOnly={readOnly}
                />
              </div>
            </div>
          </div>

          {startYear && startMonth && (
            <div className="monthly-savings__range-end">
              <div
                className="flex w-70 items-center justify-between"
                data-testid="end"
              >
                <span>{translate('End year: ')}</span>
                <div className={'w-40'}>
                  <YearSelect
                    id="endyear"
                    value={endYear}
                    onChange={handleEndYearChange}
                    placeholder="Year End"
                    max={hasSuperPower ? 10 : 4}
                    readOnly={readOnly}
                  />
                </div>
              </div>
              <div
                className="flex w-70 items-center justify-between"
                data-testid="end month"
              >
                <span>{translate('End month: ')}</span>
                <div className="w-40">
                  <MonthSelect
                    id="endmonth"
                    value={endMonth}
                    onChange={handleEndMonthChange}
                    placeholder="Month End"
                    readOnly={readOnly}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isRangeReady && valueState?.years && (
        <table className="monthly-savings__values" data-testid="saving table">
          <thead>
            <tr className="monthly-savings__values-header">
              <th className="monthly-savings__values-year" />
              {monthNames.map((monthName: string) => (
                <th key={monthName} className="monthly-savings__values-month">
                  {monthName}
                </th>
              ))}
              <th className="monthly-savings__values-sum">
                {translate('Sum')}
              </th>
            </tr>
          </thead>
          <tbody className="monthly-savings__values-body">
            {Object.keys(valueState.years)
              .map(parseYearLabel)
              .map((year) => (
                <tr className="monthly-savings__values-row" key={year}>
                  <td className="monthly-savings__values-year">
                    {year}
                    {showInflationOrDeflationValue == 1 &&
                      inflationState?.years && (
                        <div>
                          <div className="mt-2 pt-2 pb-2 bg-gray-100 text-red-900 text-sm">
                            inflation
                          </div>
                          <div className="mt-2 pt-2 pb-2 mb-3">&nbsp;</div>
                        </div>
                      )}
                    {showInflationOrDeflationValue == 2 &&
                      deflationState?.years && (
                        <div>
                          <div className="mt-2 pt-2 pb-2 bg-gray-100 text-sm text-green-900">
                            deflation
                          </div>
                          <div className="mt-2 pt-2 pb-2 mb-3">&nbsp;</div>
                        </div>
                      )}
                  </td>
                  {months.map((month: number) => (
                    <td key={month} className="monthly-savings__values-month">
                      {valueState.years && (
                        <SavingInput
                          disabled={valueState.equalDistribution}
                          value={
                            valueState.years[getYearLabel(year)].months[
                              getMonthLabel(month) as TMonth
                            ]
                          }
                          onChange={(value: string) =>
                            handleCellChange(year, month, value)
                          }
                        />
                      )}
                      {showInflationOrDeflationValue == 1 &&
                        inflationState?.years && (
                          <div>
                            <div className="mt-2 pt-2 pb-2 bg-gray-100 text-sm text-red-900">
                              <SavingInput
                                disabled={inflationState.equalDistribution}
                                value={
                                  inflationState?.years[getYearLabel(year)]
                                    .months[getMonthLabel(month) as TMonth]
                                }
                                onChange={(ideaMonthlyInflation: string) =>
                                  handleInflationCellChange(
                                    year,
                                    month,
                                    ideaMonthlyInflation,
                                  )
                                }
                              />
                            </div>
                            <div className="mt-2 pt-2 pb-2 mb-3">&nbsp;</div>
                          </div>
                        )}
                      {showInflationOrDeflationValue == 2 &&
                        deflationState?.years && (
                          <div>
                            <div className="mt-2 pt-2 pb-2 bg-gray-100 text-sm text-green-900">
                              <SavingInput
                                disabled={deflationState.equalDistribution}
                                value={
                                  deflationState?.years[getYearLabel(year)]
                                    .months[getMonthLabel(month) as TMonth]
                                }
                                onChange={(ideaMonthlyDeflation: string) =>
                                  handleDeflationCellChange(
                                    year,
                                    month,
                                    ideaMonthlyDeflation,
                                  )
                                }
                              />
                            </div>
                            <div className="mt-2 pt-2 pb-2 mb-3">&nbsp;</div>
                          </div>
                        )}
                    </td>
                  ))}
                  <td className="monthly-savings__values-sum">
                    {valueState.years && (
                      <div className="font-bold">
                        <SavingInput
                          bold
                          disabled
                          value={valueState.years[getYearLabel(year)].sum}
                        />
                      </div>
                    )}
                    {showInflationOrDeflationValue == 1 &&
                      valueState.years &&
                      inflationState?.years && (
                        <div>
                          <div className="mt-2 pt-2 pb-2 text-sm bg-gray-100">
                            <SavingInput
                              bold
                              disabled
                              value={
                                inflationState.years[getYearLabel(year)].sum
                              }
                            />
                          </div>
                          <div className="mt-2 pt-2 pb-2 text-sm mb-3 font-normal text-gray-600">
                            <SavingInput
                              bold
                              disabled
                              value={
                                valueState.years[getYearLabel(year)].sum +
                                inflationState.years[getYearLabel(year)].sum
                              }
                            />
                          </div>
                        </div>
                      )}
                    {showInflationOrDeflationValue == 2 &&
                      deflationState?.years &&
                      valueState.years && (
                        <div>
                          <div className="mt-2 pt-2 pb-2 text-sm bg-gray-100 text-green-900">
                            <SavingInput
                              bold
                              disabled
                              value={
                                deflationState.years[getYearLabel(year)].sum
                              }
                            />
                          </div>
                          <div className="mt-2 pt-2 pb-2 text-sm mb-3 font-normal text-gray-600">
                            <SavingInput
                              bold
                              disabled
                              value={
                                valueState.years[getYearLabel(year)].sum +
                                deflationState.years[getYearLabel(year)].sum
                              }
                            />
                          </div>
                        </div>
                      )}
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot className="monthly-savings__values-footer">
            <tr className="monthly-savings__values-row">
              <td className="text-right" colSpan={13}>
                {valueState?.sum !== valueState?.final && (
                  <span className="text-red-900 text-sm mr-5">
                    Saving sum per months doesn't match the Negotiated saving.
                  </span>
                )}
                {translate('Negotiated saving: ')}
              </td>
              <td className="monthly-savings__values-sum">
                <SavingInput
                  bold
                  disabled
                  invalid={valueState.sum !== valueState.final}
                  value={valueState.sum}
                />
              </td>
            </tr>
            {showInflationOrDeflationValue == 1 && (
              <tr className="monthly-savings__values-row">
                <td className="text-right" colSpan={13}>
                  {inflationState?.sum !== ideaMonthlyInflation?.final && (
                    <span className="text-red-900 text-sm mr-5">
                      Inflation sum per months doesn't match the Final
                      inflation.
                    </span>
                  )}
                  {translate('Inflation sum: ')}
                </td>
                <td className="monthly-savings__values-sum">
                  <SavingInput
                    bold
                    disabled
                    invalid={true}
                    value={inflationState?.sum}
                  />
                </td>
              </tr>
            )}
            {showInflationOrDeflationValue == 2 && (
              <tr className="monthly-savings__values-row">
                <td className="text-right" colSpan={13}>
                  {deflationState?.sum !== ideaMonthlyDeflation?.final && (
                    <span className="text-red-900 text-sm mr-5">
                      Deflation sum per months doesn't match the Final
                      deflation.
                    </span>
                  )}
                  {translate('Deflation sum: ')}
                </td>
                <td className="monthly-savings__values-sum">
                  <SavingInput
                    bold
                    disabled
                    invalid={false}
                    value={deflationState?.sum}
                  />
                </td>
              </tr>
            )}
            <tr className="monthly-savings__values-row">
              <td className="text-right" colSpan={13}>
                {translate('Net savings: ')}
              </td>
              <td className="monthly-savings__values-sum">
                <SavingInput
                  bold
                  disabled
                  value={
                    (valueState?.sum ? valueState.sum : 0) +
                    (inflationState?.sum ? inflationState.sum : 0) +
                    (deflationState?.sum ? deflationState.sum : 0)
                  }
                />
              </td>
            </tr>
          </tfoot>
        </table>
      )}
    </div>
  );
};

export default MonthlySavings;
