import React, { FC } from "react";
import { Project } from "../../../../../_common/schema";
import { SidePanel } from "../ProjectDetail";
import { Icon } from "../../../components/Icon";
import moment from "moment";
import { useBigLoader } from "components/Loader";
import { translate } from "utils/translations";

export const Hierarchy: FC<{ project: Project }> = ({ project }) => {
    let parent = project;
    if (project.parent) {
        parent = project.parent;
    }

    const bigLoader = useBigLoader();
    const navigate = (id: any) => {
        bigLoader.start(translate("Opening project..."));
        window.location.href = `/#/projects/${id}`;
        window.location.reload();
    };

    return (
        <SidePanel heading={translate("Hierarchy")}>
            {parent.id !== project.id && (
                <fieldset
                    className={`p-8 border hover:bg-gray-100 rounded  pt-0 ${
                        parent.id === project.id ? "border-blue-300" : "border-gray-300"
                    }`}
                >
                    <legend className="text-gray-600 text-center p-4">
                        {translate("Agreement")}
                    </legend>
                    <div>
                        <div
                            onClick={() => {
                                navigate(parent.id);
                            }}
                            className="flex items-center cursor-pointer hover:underline"
                        >
                            <Icon name={"file-alt"} size={5} className="text-gray-600 mr-4 mb-0" />
                            <div>
                                {parent.title}
                                <small> ({moment(parent.created_at).format("DD-MM-YYYY")})</small>
                            </div>
                        </div>
                    </div>
                </fieldset>
            )}
            {parent.id !== project.id && (
                <div className="flex justify-center">
                    <Icon name={"arrow_downward"} size={6} className="text-gray-600 m-4 mb-0" />
                </div>
            )}
            <fieldset className={"px-8 border-t pt-0 border-gray-300"}>
                <legend className="text-gray-600 text-center p-4">
                    {parent.children.length} {translate("Amendments")}
                </legend>
                {parent.children.map((child: Project, index: number) => {
                    return (
                        <div
                            onClick={() => {
                                navigate(child.id);
                            }}
                            key={`child${child.id}`}
                            className={`py-2 flex items-center cursor-pointer hover:underline ${
                                child.id === project.id ? "text-blue-700" : ""
                            }`}
                        >
                            <Icon name={"file-alt"} size={5} className="text-gray-600 mr-4 mb-0" />
                            <div>
                                {translate("Amendment")}{" "}
                                {child.fields.amendment_number
                                    ? child.fields.amendment_number
                                    : parent.children.length - index}
                                <small> ({moment(child.created_at).format("DD-MM-YYYY")})</small>
                            </div>
                        </div>
                    );
                })}
            </fieldset>
        </SidePanel>
    );
};
