import React, { useContext, useState } from 'react';
import { Route, Switch } from 'react-router';

import { Page } from 'components/layout/Page';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import Workflow from 'components/Workflow';

import { translate } from 'utils/translations';
import { useRequest } from 'hooks/useRequest';

import List from './List';
import Modal from './Modal';

import { FilterTemplate } from '@contractool/schema';

import {
  URL_NEW_RULE,
  URL_EDIT_RULE,
  URL_API_RULES,
} from '@constants/settings';
import { RuleResponseApiType } from 'types/api/setting';
import { http } from 'utils/http';
import { createRule, filter } from 'utils/settings';

import { AppContext } from 'contexts';

const Settings: React.FC = () => {
  const { assessmentWorkflow } = useContext(AppContext);

  const [search, setSearch] = useState('');

  const [data, { refresh }] = useRequest<RuleResponseApiType[]>(
    URL_API_RULES,
    [],
  );
  const [templates] = useRequest<FilterTemplate[]>(
    `/api/project-filter-templates?workflow=${assessmentWorkflow}`,
    [],
  );
  const rules = createRule(data);

  const handleSearch = (value: string) => setSearch(value);

  return (
    <Page
      heading="Settings"
      right={
        <div className="flex">
          <div className="mr-8 w-100">
            <TextInput
              placeholder={translate('Search...')}
              icon="search"
              onChange={handleSearch}
              value={search}
            />
          </div>
          <div className="mr-8">
            <Workflow />
          </div>
          <Button.Link to={URL_NEW_RULE} color="blue" icon="add">
            {translate('Add approval rule')}
          </Button.Link>
        </div>
      }
    >
      <div className="-mx-6">
        <List
          rules={filter(rules, search, assessmentWorkflow)}
          templates={templates}
          onDelete={async (id) => {
            await http.delete(`${URL_API_RULES}/${id}`);
            refresh();
          }}
        />
      </div>
      <Switch>
        <Route path={URL_NEW_RULE}>
          <Modal
            templates={templates}
            onSubmit={async (payload) => {
              await http.post(URL_API_RULES, payload);
              refresh();
            }}
          />
        </Route>
        <Route path={URL_EDIT_RULE}>
          <Modal
            isEditMode
            templates={templates}
            rules={rules}
            onSubmit={async (payload, id) => {
              await http.put(`${URL_API_RULES}/${id}`, payload);
              refresh();
            }}
            onDelete={async (id) => {
              await http.delete(`${URL_API_RULES}/${id}`);
              refresh();
            }}
          />
        </Route>
      </Switch>
    </Page>
  );
};

export default Settings;
