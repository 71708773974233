import React, {FC} from 'react';

interface Props {
    rowsCount?: number;
    colsCount?: number;
}

export const DummyList: FC<Props> = ({rowsCount = 10, colsCount = 4}) => {
    let rows = Array.from(Array(rowsCount).keys());
    let cols = Array.from(Array(colsCount).keys());
    let widths = [10, 20, 25, 31, 40];

    return (
        <>
            {rows.map((number) => (
                <tr key={'dummy' + number}>
                    {cols.map((col) => {
                        var randomNumber = Math.floor(Math.random() * widths.length) + 1;
                        var classNames = 'bg-gray-200 rounded-xl w-' + widths[randomNumber];

                        return (
                            <td className="p-4 w-2" key={'dummy' + number + col}>
                                <div className={classNames}>&nbsp;</div>
                            </td>
                        );
                    })}
                </tr>
            ))}
        </>
    );
};
