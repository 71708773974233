import React, { useContext } from "react";
import { AppContext } from "contexts";
import { Member } from "./components";
import { getWorkflow } from "hooks/workflows";
import ProjectContext from "views/projects/ProjectContext";

const Group = () => {

    const { workflow } = useContext(ProjectContext);
    return (
        <>
            {Object.keys(workflow.widget_fields).map((key) => (
                <Member key={key} memberKey={key} group={workflow.widget_fields[key]} />
            ))}
        </>
    );
};

const PureGroup = React.memo(Group);

export default PureGroup;
