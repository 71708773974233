import * as yup from 'yup';

import { Option } from 'components/Dropdown';
import {
  AskUserActionType,
  AutomaticActionType,
  Tab,
  UserRoleType,
} from 'types/output/setting';

const conditions = yup.array().of(
  yup.object().shape({
    subject: yup.string().required('Required'),
    operation: yup.object().required('Required'),
    value: yup.lazy((value) =>
      Array.isArray(value)
        ? yup.array().min(1, 'Required')
        : yup.string().required('Required'),
    ),
  }),
);

// Tab - Ask user to
const askUser = yup.array().when('tab', {
  is: Tab.ASK_USER,
  then: yup.array().of(
    yup.object().shape({
      action: yup.string().required('Required'),
      fillSomeMetadata: yup.array().when('action', {
        is: AskUserActionType.FILL_SOME_METADATA,
        then: yup.array().min(1, 'Required'),
      }),
      chooseUserToRole: yup.array().when('action', {
        is: AskUserActionType.CHOOSE_USER_TO_ROLE,
        then: yup.array().min(1, 'Required'),
      }),
    }),
  ),
});

// Tab - Do automatic action
const selection = {
  selection: yup.object().nullable().required('Required'),
  user: yup.mixed().when('selection', {
    is: (selection: Option<string>) => selection?.value === UserRoleType.USER,
    then: yup.lazy((value) =>
      Array.isArray(value)
        ? yup.array().min(1, 'Required')
        : yup.object().nullable().required('Required'),
    ),
  }),
  role: yup
    .object()
    .nullable()
    .when('selection', {
      is: (selection: Option<string>) => selection?.value === UserRoleType.ROLE,
      then: yup.object().nullable().required('Required'),
    }),
};

const automatic = yup.array().when('tab', {
  is: Tab.AUTOMATIC,
  then: yup.array().of(
    yup.object().shape({
      action: yup.string().required('Required'),
      createApproval: yup.object().when('action', {
        is: AutomaticActionType.CREATE_APPROVAL,
        then: yup
          .object()
          .shape(selection)
          .shape({
            textareaSingle: yup.string().required('Required'),
          }),
      }),
      createTask: yup.object().when('action', {
        is: AutomaticActionType.CREATE_TASK,
        then: yup
          .object()
          .shape(selection)
          .shape({
            title: yup.string().required('Required'),
            days: yup.string().required('Required'),
            textareaSingle: yup.string().required('Required'),
            mandatory: yup.boolean().optional()
          }),
      }),
      sendNotification: yup.object().when('action', {
        is: AutomaticActionType.SEND_NOTIFICATION,
        then: yup
          .object()
          .shape(selection)
          .shape({
            subject: yup.string().required('Required'),
            textareaInApp: yup.string().required('Required'),
            textareaEmail: yup.string().required('Required'),
          }),
      }),
      attachFile: yup.array().when('action', {
        is: AutomaticActionType.ATTACH_FILE,
        then: yup.array().min(1, 'Required'),
      }),
      addUserToRole: yup.object().when('action', {
        is: AutomaticActionType.ADD_USER_TO_ROLE,
        then: yup.object().shape({
          user: yup.array().min(1, 'Required'),
          role: yup.array().min(1, 'Required'),
        }),
      }),
      assignProjectToRole: yup
        .object()
        .nullable()
        .when('action', {
          is: AutomaticActionType.ASSIGN_PROJECT_TO_ROLE,
          then: yup.object().nullable().required('Required'),
        }),
      addProjectLogMessage: yup.string().when('action', {
        is: AutomaticActionType.ADD_PROJECT_LOG_MESSAGE,
        then: yup.string().required('Required'),
      }),
    }),
  ),
});

const schema = yup.object().shape({
  name: yup.string().required('Required'),
  conditions,
  results: yup.object().shape({
    active: yup.string(),
    askUser,
    automatic,
  }),
});

export { schema };
