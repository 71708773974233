import React, {FC} from 'react';
import {Project} from '../../../../../_common/schema';
import {Confirmation} from '../../../components/Confirmation';
import {MenuItem} from '../../../components/Menu';
import {http} from '../../../utils/http';
import {useHistory} from 'react-router-dom';
import {translate} from 'utils/translations';
import {useToasts} from 'hooks';

export const Renew: FC<{project: Project}> = ({project}) => {
    const {success} = useToasts();
    const history = useHistory();

    return (
        <Confirmation
            onConfirm={() => {
                http.post(`api/projects/${project.id}/amend`).then((data: any) => {
                    console.log('data.data', data);
                    history.push(`/projects/${data.data.id}`);
                    success(`${translate('Project renewed')}.`);
                });
            }}
            trigger={({onClick}) => (
                <MenuItem
                    icon="refresh"
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick();
                    }}
                >
                    {translate('Renew')}
                </MenuItem>
            )}
            heading={`${translate('Renew')}?`}
            buttonText={`${translate('Yes, renew')}`}
            color="yellow"
        >
            {translate('Are you sure you want to renew this project?')}
        </Confirmation>
    );
};
