import React, {useCallback, useContext} from 'react';
import {useHistory} from 'react-router';
import {FilterTemplate} from '@contractool/schema';

import {Modal} from 'components/Modal';
import {Form} from 'components/Form';
import {Button} from 'components/Button';
import TaskForm from './Form';
import {AiCollection} from '../collections/New';

import {translate} from 'utils/translations';

import {useToasts} from 'hooks';
import {useRequest} from 'hooks/useRequest';

import {http} from 'utils/http';

import {AppContext} from 'contexts';

export interface AiTask {
    id: string;
    name: string;
    description: string;
    collections?: AiCollection[];
    type: string;
    fields?: any[];
}

const NewTask = ({onCreate, closeUrl}: {onCreate: (task: AiTask) => void; closeUrl: string}) => {
    const {assessmentWorkflow} = useContext(AppContext);
    const [filterTemplates] = useRequest<FilterTemplate[]>(
        `/api/project-filter-templates?workflow=${assessmentWorkflow}`,
        [],
        {},
    );
    const metaDataFields = filterTemplates.map((template: FilterTemplate) => ({
        value: template.name,
        label: template.label,
    }));
    const history = useHistory();
    const close = useCallback(() => {
        history.push(closeUrl);
    }, [history, closeUrl]);

    const handleSubmit = useCallback((values) => http.post<AiTask>('/api/ai/tasks', values), []);
    const {success} = useToasts();
    const handleSuccess = useCallback(
        (task) => {
            onCreate(task);
            success(
                `${translate('Ai :task was added successfully', {
                    task: task.name,
                })}`,
            );
            close();
        },
        [onCreate, close, success],
    );

    return (
        <Modal heading={translate('Add Task')} onClose={close}>
            <Form
                initialValues={{
                    name: '',
                    type: 'Metadata Extraction',
                }}
                onSubmit={handleSubmit}
                onSuccess={handleSuccess}
            >
                <TaskForm fields={metaDataFields} />
                <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={close}>
                        {translate('Cancel')}
                    </Button>

                    <Form.Submit>{translate('Add task')}</Form.Submit>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default NewTask;
