import { Document, DocumentCategory } from '@contractool/schema';
import * as React from 'react';
import { ReactSortable } from 'react-sortablejs';

import { Button } from 'components/Button';
import { Confirmation } from 'components/Confirmation';
import { Icon } from 'components/Icon';
import { Modal } from 'components/Modal';
import { TextInput } from 'components/TextInput';
import { http } from 'utils/http';
import { translate } from 'utils/translations';
import { cx } from 'utils/classnames';
// import {useToasts} from 'hooks';

const CategoriesOverlay: React.FC<{
  categories: DocumentCategory[];
  documents: Document[];
  refreshCategories: () => void;
  onClose: () => void;
}> = ({
  categories: documentCategories,
  documents,
  onClose,
  refreshCategories: refreshDocumentCategories,
}) => {
  const [draftCategory, setDraftCategory] =
    React.useState<DocumentCategory | null>(null);
  const handleCreateClick = React.useCallback(() => {
    setDraftCategory({
      id: 0,
      title: '',
      order: documentCategories.length,
    });
  }, [documentCategories]);

  const [sortableList, setSortableList] = React.useState(documentCategories);
  React.useEffect(() => {
    setSortableList(documentCategories);
    setDraftCategory(null);
  }, [documentCategories]);

  const orderDocumentCategory = React.useCallback(
    async (payload: DocumentCategory[]) => {
      const { data: updatedDocumentCategory } =
        await http.post<DocumentCategory>('/api/library/categories-order', {
          payload,
        });

      return updatedDocumentCategory;
    },
    [],
  );

  const handleCreate = React.useCallback(
    async (payload: DocumentCategory) => {
      await http.post<DocumentCategory>('/api/library/categories', payload);
      await refreshDocumentCategories();
    },
    [refreshDocumentCategories],
  );

  const handleUpdate = React.useCallback(
    async (payload: DocumentCategory) => {
      await http.put<DocumentCategory>(
        `/api/library/categories/${payload.id}`,
        payload,
      );
      await refreshDocumentCategories();
    },
    [refreshDocumentCategories],
  );

  const handleRemove = React.useCallback(
    async (payload: DocumentCategory) => {
      await http.delete<DocumentCategory>(
        `/api/library/categories/${payload.id}`,
      );
      await refreshDocumentCategories();
    },
    [refreshDocumentCategories],
  );

  const handleReorderList = React.useCallback(
    async (event: any) => {
      const payload = [...documentCategories];
      const movedDocumentCategory = payload.splice(event.oldIndex, 1);
      payload.splice(event.newIndex, 0, ...movedDocumentCategory);

      await orderDocumentCategory(payload);
      await refreshDocumentCategories();
    },
    [orderDocumentCategory, documentCategories, refreshDocumentCategories],
  );

  return (
    <Modal
      heading={translate('Manage categories')}
      isOpen
      onClose={onClose}
      size="small"
      corner={
        <Button
          className="flex text-blue-600 items-center"
          color="white"
          onClick={handleCreateClick}
        >
          <Icon name="add" size={5} className="mr-2" />
          {translate('Create new')}
        </Button>
      }
      compact={false}
    >
      {documentCategories.length > 0 && (
        <ReactSortable
          tag="ul"
          list={sortableList}
          setList={setSortableList}
          onUpdate={handleReorderList}
          handle=".drag-handle"
        >
          {sortableList.map((sortableListItem: any) => (
            <li key={sortableListItem.id}>
              <CategoryCrud
                category={sortableListItem}
                documents={documents}
                onUpdate={handleUpdate}
                onRemove={handleRemove}
              />
            </li>
          ))}
        </ReactSortable>
      )}
      {draftCategory && (
        <CategoryCrud
          category={draftCategory}
          onCreate={handleCreate}
          mode="create"
        />
      )}
      <div className="mt-16 flex justify-end">
        <Button color="blue" onClick={onClose}>
          {translate('Done')}
        </Button>
      </div>
    </Modal>
  );
};

const CategoryCrud: React.FC<{
  category: any;
  onUpdate?: (payload: DocumentCategory) => void;
  onRemove?: (payload: DocumentCategory) => void;
  onCreate?: (payload: DocumentCategory) => void;
  mode?: string;
  documents?: Document[];
}> = ({
  category,
  onUpdate,
  onRemove,
  onCreate,
  mode = 'edit',
  documents = [],
}) => {
  // const {success} = useToasts();

  const handleUpdate = (payload: DocumentCategory) => {
    if (onUpdate) {
      onUpdate(payload);
    }
  };
  const handleRemove = () => {
    if (onRemove) {
      onRemove(category);
    }
  };
  const handleCreate = (payload: DocumentCategory) => {
    if (onCreate) {
      onCreate(payload);
    }
  };

  const categoryDocuments = React.useMemo(() => {
    return documents.filter(({ category_id }) => category_id === category.id);
  }, [category, documents]);

  return (
    <div className="flex items-center text-gray-700">
      <div className="drag-handle cursor-pointer mr-6">
        <Icon name="reorder" size={6} className="text-gray-500" />
      </div>
      <div className="flex-auto">
        <Heading
          category={category}
          mode={mode}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
        />
      </div>
      <div className="flex w-6">
        {mode === 'edit' && (
          <Confirmation
            onConfirm={handleRemove}
            trigger={({ onClick }) => {
              const isDeleteAllowed = !categoryDocuments.length;

              return (
                <div
                  onClick={isDeleteAllowed ? onClick : () => {}}
                  title={translate(
                    isDeleteAllowed
                      ? 'Delete category'
                      : 'This category can not be deleted because it has documents.',
                  )}
                >
                  <Icon
                    name="delete"
                    size={6}
                    className={cx(
                      'text-gray-500',
                      isDeleteAllowed ? 'cursor-pointer' : 'cursor-not-allowed',
                    )}
                  />
                </div>
              );
            }}
            heading={translate('Remove category')}
            buttonText={translate('Yes, remove')}
            color="red"
          >
            {translate('Are you sure you want to remove this category?')}
          </Confirmation>
        )}
      </div>
    </div>
  );
};

export default CategoriesOverlay;

const input = {
  className: 'leading-tighter',
};

const Heading: React.FC<{
  category: DocumentCategory;
  handleCreate: (payload: DocumentCategory) => void;
  handleUpdate: (payload: DocumentCategory) => void;
  mode: string;
}> = ({ category, handleCreate, handleUpdate, mode }) => {
  const { title } = category;

  const handleHeadingInput = React.useCallback(
    async (updatedTitle) => {
      const handleInput = mode === 'create' ? handleCreate : handleUpdate;
      await handleInput({
        ...category,
        title: updatedTitle,
      });
    },
    [category, handleCreate, handleUpdate, mode],
  );

  const [editing, setEditing] = React.useState<boolean>(mode === 'create');
  const [headingModel, setHeadingModel] = React.useState<string>(title);

  const handleClick = () => {
    setEditing(true);
  };

  const handleBlur = async () => {
    if (headingModel) {
      await handleHeadingInput(headingModel);
      setEditing(false);
    }
  };

  return editing ? (
    <TextInput
      autoFocus
      input={input}
      name="title"
      onBlur={handleBlur}
      onChange={setHeadingModel}
      value={headingModel}
    />
  ) : (
    <div
      className="border-b border-gray-200 leading-tighter py-5"
      onClick={handleClick}
    >
      {headingModel}
    </div>
  );
};
