import { FilterTemplate } from "@contractool/schema";
import { useRequest } from "hooks/useRequest";
import { groupBy } from "lodash";

export const loadFilterTemplates = (workflow: string) => {
    const [filterTemplates] = useRequest<FilterTemplate[]>(
        `/api/report-groups?workflow=${workflow}`,
        []
    );

    const groupedFilterTemplates = groupBy(filterTemplates, "name");
    return {
        filterTemplates,
        groupedFilterTemplates
    };
};
