import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import {
  FieldArray,
  FieldArrayRenderProps,
  FieldInputProps,
  Form,
  Formik,
} from 'formik';

import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { Modal } from 'components/Modal';

import Item from './Item';

import { translate } from 'utils/translations';
import { schema } from 'validation/createCategory';
import { Category, ModalForm } from '../types';
import { ReportTab } from '@contractool/schema';

type Props = {
  tabs: ReportTab[];
  title: string;
  onClose: () => void;
  onOrder: (ids: number[], categories: any) => void;
  onCreate: (
    parentField: FieldInputProps<any>,
    field: FieldInputProps<any>,
  ) => void;
  onUpdate: (
    index: number,
    parentField: FieldInputProps<any>,
    field: FieldInputProps<any>,
  ) => void;
  onDelete: (index: number) => void;
};

const Manage: React.FC<Props> = ({
  tabs,
  title,
  onClose,
  onOrder,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const initialValues = {
    categories:
      tabs.map<Category>((tab, index) => ({
        ...tab,
        id: index,
        new: false,
        focused: false,
      })) || [],
  };

  const handleUpdateList = async (event: any) => {
    const { categories } = initialValues;
    const ids = categories.map((category) => category.id);

    if (Array.isArray(ids)) {
      ids.splice(event.oldIndex, 1);
      ids.splice(event.newIndex, 0, event.oldIndex);

      if (onOrder) {
        onOrder(ids, categories);
      }
    }
  };

  const handleCreate = (
    push: (category: Category) => void,
    values: ModalForm,
  ) => {
    const allowCreate = values.categories.filter(
      (category) => category.new === true,
    );
    if (allowCreate.length === 0) {
      push({
        id: values.categories.length,
        heading: '',
        new: true,
        focused: true,
        reports: [],
      });
    }
  };

  return (
    <Formik<ModalForm>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
    >
      {({ values, handleBlur, setValues }) => (
        <Form onBlur={handleBlur}>
          <FieldArray name="categories">
            {({ push, remove }) => (
              <Modal
                heading={title}
                isOpen={true}
                onClose={onClose}
                size="small"
                corner={
                  <Button
                    className="flex text-blue-600 items-center"
                    color="white"
                    onClick={() => handleCreate(push, values)}
                  >
                    <Icon name="add" size={5} className="mr-2" />
                    {translate('Create new')}
                  </Button>
                }
                compact={false}
              >
                <ReactSortable
                  tag="ul"
                  list={values.categories}
                  setList={(newState) => setValues({ categories: newState })}
                  onUpdate={handleUpdateList}
                  handle=".drag-handle"
                >
                  {values.categories.length > 0 &&
                    values.categories.map((category, index) => (
                      <li key={`${category.heading}_${index}`}>
                        <Item
                          index={index}
                          onCreate={onCreate}
                          onUpdate={onUpdate}
                          onDelete={onDelete}
                          remove={remove}
                        />
                      </li>
                    ))}
                </ReactSortable>
                <div className="mt-16 flex justify-end">
                  <Button color="blue" type="submit" onClick={onClose}>
                    {translate('Done')}
                  </Button>
                </div>
              </Modal>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};

export default Manage;
