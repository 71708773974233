import React from 'react';
import { HeaderGroup } from 'react-table';

import { Icon } from 'components/Icon';

type Props = {
  headerGroups: HeaderGroup[];
  stickyHeader?: boolean;
};

const TableHead: React.FC<Props> = ({ headerGroups, stickyHeader }) => {
  const stickyHeaderClass = stickyHeader ? 'sticky top-0 bg-white' : '';

  return (
    <thead className={stickyHeaderClass}>
      {headerGroups.map((headerGroup: HeaderGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column: any) => (
            <th
              {...column.getHeaderProps(
                column.getSortByToggleProps({
                  style: {
                    width: column.width,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                  },
                  className: 'whitespace-nowrap',
                }),
              )}
            >
              <div className="flex">
                {column.render('Header')}
                <span className="w-6">
                  {column.isSorted &&
                    (column.isSortedDesc ? (
                      <Icon name="keyboard_arrow_down" size={6} />
                    ) : (
                      <Icon name="keyboard_arrow_up" size={6} />
                    ))}
                </span>
              </div>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default TableHead;
