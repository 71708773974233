import ButterToast, {
    POS_BOTTOM,
    POS_CENTER,
    POS_RIGHT,
    POS_TOP
} from "butter-toast/dist/lean.min.js";
import { PusherContext } from "contexts";
import { useToasts } from "hooks";
import React, { FC } from "react";

interface ToastPayload {
    message: string;
    status: number;
}

const positions = ["top-center", "top-right", "bottom-center", "bottom-right"];
// PositionType should include all values from positions
export type TPosition = "top-center" | "top-right" | "bottom-center" | "bottom-right";

const positionMap: {
    [key in TPosition]: {
        vertical: string;
        horizontal: string;
    };
} = {
    "top-center": {
        vertical: POS_TOP,
        horizontal: POS_CENTER
    },
    "top-right": {
        vertical: POS_TOP,
        horizontal: POS_RIGHT
    },
    "bottom-center": {
        vertical: POS_BOTTOM,
        horizontal: POS_CENTER
    },
    "bottom-right": {
        vertical: POS_BOTTOM,
        horizontal: POS_RIGHT
    }
};

const ToastContainer: FC<{ position: TPosition }> = ({ position }) => {
    return <ButterToast spacing={15} namespace={position} position={positionMap[position]} />;
};

interface INotificationProps {
}

const Notifications: FC<INotificationProps> = () => {
    const pusher = React.useContext(PusherContext);

    const { success } = useToasts();
    if (pusher && pusher.private) {
        pusher.leave("toast");
        pusher.private("toast").listen(".success", (payload: ToastPayload) => {
            success(payload.message);
        });
    }

    return (
        <>
            {positions.map((position: string) => (
                <ToastContainer key={position} position={position as TPosition} />
            ))}
        </>
    );
};

export default Notifications;
