import React, { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';
import FullCalendar, { EventApi } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { CalendarEvent } from '@contractool/schema';
import { Page } from '../../components/layout/Page';
import { Icon } from '../../components/Icon';
import { http } from '../../utils/http';
import { translate } from 'utils/translations';
import './Calendar.css';

export function Calendar() {
  const [events, setEvents] = useState([{}]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    // Calendar fetch
    http.get<CalendarEvent[]>('api/calendar').then((response) => {
      setEvents(
        response.data
          .filter((evt) => evt.type === 'TASK_DEADLINE')
          .map((evt) => ({
            title: titleExtract(evt.title),
            start: extractDate(evt.date),
            extendedProps: {
              type: evt.type,
              subTitle: evt.sub_title,
              url: urlExtract(evt.url),
              date: evt.date,
              important: evt.important,
            },
            backgroundColor: '#D4E4FF',
            borderColor: 'transparent',
          })),
      );
    });
  };

  return (
    <Page heading="Calendar">
      <FullCalendar
        initialView="dayGridMonth"
        plugins={[dayGridPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth dayGridWeek',
        }}
        events={events}
        eventContent={(arg) => eventTransform(arg.event)}
        titleFormat={{
          month: 'short',
          year: 'numeric',
        }}
        firstDay={0}
        eventClassNames="my-4 whitespace-normal p-3"
        themeSystem="bootstrap"
      />
    </Page>
  );
}

const extractDate = (date: string) => {
  const newDate = date.match(/\w+-\w+-\w+/gm);

  return newDate ? newDate[0] : date;
};

const titleExtract = (text: string) => {
  return text.replace(/\w+ '/, '').replace(/' deadline.*/, '');
};

const urlExtract = (url: string) => {
  const newUrl = url.match(/projects\/\d+\/tasks\/\d+/gm);

  return newUrl ? newUrl[0] : url;
};

const eventTransform = (evtApi: EventApi) => {
  switch (evtApi.extendedProps.type) {
    case 'TASK_DEADLINE': {
      return (
        <Event
          type="Task"
          title={evtApi.title}
          subTitle={evtApi.extendedProps.subTitle}
          url={evtApi.extendedProps.url}
          date={evtApi.extendedProps.date}
        />
      );
    }
    default:
      return (
        <Event
          type="Other"
          title={evtApi.title}
          subTitle={evtApi.extendedProps.subTitle}
          url={evtApi.extendedProps.url}
          date={evtApi.extendedProps.date}
        />
      );
  }
};

const Event: FC<{
  type: string;
  title: string;
  subTitle: string;
  url: string;
  date: string;
}> = ({ type, title, subTitle, url, date }) => {
  const [open, setOpen] = useState(false);

  const popperRef = useRef<HTMLElement>(null);
  const handleRef = useRef<HTMLElement>(null);

  return (
    <div onMouseLeave={() => setOpen(false)}>
      <Manager>
        <Reference innerRef={handleRef}>
          {({ ref }) => (
            <div ref={ref} onMouseEnter={() => setOpen(true)}>
              <div className="flex items-center text-small text-blue-700">
                <Icon name="task_assignment" size={6} className="mr-2" />
                <span>{type}</span>
              </div>
              <div className="text-base text-gray-700 py-3 break-words">
                {titleExtract(title)}
              </div>
            </div>
          )}
        </Reference>
        {open &&
          createPortal(
            <Popper
              innerRef={popperRef}
              placement="bottom-end"
              modifiers={{
                flip: { enabled: false },
                preventOverflow: { enabled: false },
                hide: { enabled: false },
              }}
            >
              {({ ref, style }) => (
                <div ref={ref} style={style} data-cy={`tooltip-${title}`}>
                  <Tooltip
                    title={titleExtract(title)}
                    subTitle={subTitle}
                    date={date}
                    url={url}
                  />
                </div>
              )}
            </Popper>,
            document.body,
          )}
      </Manager>
    </div>
  );
};

const Tooltip: FC<{
  title: string;
  subTitle: string;
  date: string;
  url: string;
}> = ({ title, subTitle, date, url }) => {
  return (
    <div className="bg-white p-6 rounded text-base shadow-lg">
      <div className="text-lg mb-2">{title}</div>
      <div className="text-base mb-8 text-gray-600">{subTitle}</div>
      <div className="flex items-center justify-between">
        <div className="flex justify-between items-center whitespace-nowrap mr-2">
          <Icon name="calendar_today" size={6} className="mr-3" />
          <div>{`${new Date(date).toLocaleString('default', {
            day: 'numeric',
            month: 'short',
          })}`}</div>
        </div>
        <Link
          to={url}
          className="text-blue-700 flex justify-between items-center whitespace-nowrap"
        >
          <Icon name="launch" size={6} className="mr-3" />
          {translate('Open in Project')}
        </Link>
      </div>
    </div>
  );
};
