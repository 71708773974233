import {Configuration, FieldTemplate, User} from '@contractool/schema';
import { useLocalStorage } from '@rehooks/local-storage';
import Echo from 'laravel-echo';
import 'pusher-js';
import React, { FC, useContext, useState } from 'react';

import { AppContext, AuthContext, PusherContext } from 'contexts';
import { SamlRouter } from 'components';
import { LS_USER_KEY, LS_PROJECTS_QUERY_KEY, LS_REDIRECT_URL } from 'data';
import { useRequest } from 'hooks/useRequest';
import { useCequenceStorage } from 'hooks/useCequenceStorage';
import {
  registerAppVersionInterceptor,
  registerErrorResponseInterceptor,
} from 'utils/http';
import {
  init as initTranslations,
  getFetching,
  getReadiness,
} from 'utils/translations';
import { AppVersionModal } from 'components/AppVersionModal';

function App() {
  const [, , unsetUserInLocalStorage] = useLocalStorage<User>(LS_USER_KEY);
  const locationArray = window.location.href.split('#');
  const routeString = locationArray[1] !== undefined ? locationArray[1] : '';
  const configParam = routeString === '/login' ? '?showForm=1' : '';
  const [, setRedirectUrl] = useLocalStorage<string>(LS_REDIRECT_URL);

  const [assessmentWorkflow, setAssessmentWorkflow] = useCequenceStorage(
    'workflow',
    'default',
  );

  const [loaded, setLoaded] = useState(false);
  const [appVersionModal, setAppVersionModal] = useState(false);
  const [beVersion, setBeVersion] = useState('');
  const [config, { refresh }] = useRequest<Configuration | undefined>(
    '/api/configuration' + configParam,
    undefined,
    {},
    () => {
      setLoaded(true);
    },
  );

  // needs to be called synchronously (not within a useEffect)
  registerAppVersionInterceptor((beVersion) => {
    console.log('beVersion', beVersion);
    let existing = localStorage.getItem('_app_version');
    if (!beVersion) {
      return;
    }

    if (!existing) {
      localStorage.setItem('_app_version', beVersion);

      return;
    }

    setBeVersion(beVersion);
    if (existing !== beVersion) {
      setAppVersionModal(true);
    }
  });
  registerErrorResponseInterceptor((response) => {
    if (response?.status === 401) {
      unsetUserInLocalStorage();
      if (response.data.data.redirect) {
        setRedirectUrl(window.location.href);
        setLoaded(false);
        window.location.href = response.data.data.redirect;
      } else {
        setLoaded(true);
      }
    }
  });

  if (!getFetching() && !getReadiness()) {
    initTranslations();
  }

  const context = {
    loaded,
    config,
    refresh,
    assessmentWorkflow,
    setAssessmentWorkflow,
  };
  const [, setUserInLocalStorage] = useLocalStorage<User>(LS_USER_KEY);
  const [userSaved, setUserSaved] = useState(false);
  if (config && !userSaved) {
    setUserInLocalStorage(config.user);
    setUserSaved(true);

    if (config.user.fields.default_workflow) {
        let workflow = config.workflows.filter((workflow) => {return workflow.label === config.user.fields.default_workflow.value})[0];
        if (workflow) {
            setAssessmentWorkflow(workflow.key);
        }
    }
  }

  return (
    <AppContext.Provider value={context}>
      {appVersionModal && <AppVersionModal beVersion={beVersion} />}
      {loaded ? (
        <PusherContextProvider>
          <AuthContextProvider>
            <SamlRouter />
          </AuthContextProvider>
        </PusherContextProvider>
      ) : (
        <div className="text-2xl p-25">Loading, please wait...</div>
      )}
    </AppContext.Provider>
  );
}

const AuthContextProvider: FC = ({ children }) => {
  const [userInLocalStorage, setUserInLocalStorage, unsetUserInLocalStorage] =
    useLocalStorage<User>(LS_USER_KEY);
  const [, , unsetProjectsQueryInLocalStorage] = useLocalStorage<string>(
    LS_PROJECTS_QUERY_KEY,
  );

  const [user, setUser] = React.useState(userInLocalStorage);
  const updateUser = (user: User) => {
    setUserInLocalStorage(user);
    setUser(user);
  };
  const { refresh } = useContext(AppContext);

  const value = {
    user,
    loggedIn: Boolean(user),
    login(user: User) {
      updateUser(user);
      refresh();
    },
    logout() {
      unsetProjectsQueryInLocalStorage();
      unsetUserInLocalStorage();
      setUser(null);
    },
    update(user: User) {
      updateUser(user);
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const PusherContextProvider: FC = ({ children }) => {
  const { config } = useContext(AppContext);

  let echo = {} as Echo;
  if (config) {
    console.log('Pusher establishment');
    echo = new Echo({
      broadcaster: 'pusher',
      key: config.config.pusher_key,
      encrypted: true,
      cluster: config.config.pusher_cluster,
      authEndpoint: '/api/broadcasting/auth',
    });
  }

  return (
    <PusherContext.Provider value={echo}>{children}</PusherContext.Provider>
  );
};

export default App;
