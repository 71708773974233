import React, {FC} from 'react';

export interface TextAreaProps {
    name: string;
    cols?: number;
    rows?: number;
    placeholder?: string;
    hasError?: boolean;
    required?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    value?: string;
    defaultValue?: string;
    onChange?: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

export const TextArea: FC<TextAreaProps> = ({name, onChange, hasError = false, ...rest}) => {
    return (
        <textarea
            id={name}
            name={name}
            onChange={(e) => onChange && onChange(e.target.value)}
            className={`pb-5 mt-5 w-full focus:outline-none border-b leading-none text-gray-700 placeholder-gray-400 resize-none leading-tight ${
                hasError ? 'border-red-700' : 'border-gray-200 focus:border-blue-700'
            }`}
            style={{backgroundColor: 'transparent'}}
            {...rest}
        ></textarea>
    );
};
