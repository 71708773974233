import React from 'react';
import { FieldArray, FormikProps, useFormikContext } from 'formik';

import DynamicTab from '../Then';

import {
  AutomaticActionType,
  FieldType,
  SettingsForm,
} from 'types/output/setting';
import { automaticOptions } from '@constants/settings';

const Automatic = () => {
  const { values }: FormikProps<SettingsForm> = useFormikContext();
  const config = [
    {
      action: AutomaticActionType.CREATE_APPROVAL,
      fields: [
        {
          name: FieldType.SELECTION,
          path: 'createApproval.selection',
          isMulty: false,
        },
        {
          name: FieldType.TEXTAREA_SINGLE,
          path: 'createApproval.textareaSingle',
          label: 'Approval text',
          className: 'w-full mt-9',
        },
      ],
    },
    {
      action: AutomaticActionType.CREATE_TASK,
      fields: [
        {
          name: FieldType.SELECTION,
          path: 'createTask.selection',
          isMulty: false,
        },
        {
          name: FieldType.SWITCH,
          path: 'createTask.mandatory',
          label: 'Mandatory',
          className: 'w-full mt-9',
        },
        {
          name: FieldType.TITLE,
          path: 'createTask.title',
          label: 'Task title',
          className: 'w-full mt-9',
        },
        {
          name: FieldType.DAYS,
          path: 'createTask.days',
          label: 'Deadline (days)',
          className: 'w-full mt-9',
        },
        {
          name: FieldType.TEXTAREA_SINGLE,
          path: 'createTask.textareaSingle',
          label: 'Task text',
          className: 'w-full mt-9',
        },
      ],
    },
    {
      action: AutomaticActionType.SEND_NOTIFICATION,
      fields: [
        {
          name: FieldType.SELECTION,
          path: 'sendNotification.selection',
          isMulty: false,
        },
        {
          name: FieldType.TEXTAREA_IN_APP,
          path: 'sendNotification.textareaInApp',
          label: 'In app notification',
          className: 'w-full mt-9',
        },
        {
          name: FieldType.SUBJECT,
          path: 'sendNotification.subject',
          label: 'Email notification subject',
          className: 'w-full mt-9',
        },
        {
          name: FieldType.TEXTAREA_EMAIL,
          path: 'sendNotification.textareaEmail',
          label: 'Email notification text',
          className: 'w-full mt-9',
        },
      ],
    },
    {
      action: AutomaticActionType.ATTACH_FILE,
      fields: [
        {
          name: FieldType.ATTACH_FILE,
          path: 'attachFile',
          isMulty: false,
          className: 'w-full',
        },
      ],
    },
    {
      action: AutomaticActionType.ADD_USER_TO_ROLE,
      fields: [
        {
          name: FieldType.USER,
          path: 'addUserToRole.user',
          className: 'w-95 mr-9',
        },
        {
          name: FieldType.ROLE,
          path: 'addUserToRole.role',
          className: 'w-95',
        },
      ],
    },
    {
      action: AutomaticActionType.ASSIGN_PROJECT_TO_ROLE,
      fields: [
        {
          name: FieldType.ROLE,
          path: 'assignProjectToRole',
          isMulty: false,
          className: 'w-200',
        },
      ],
    },
    {
      action: AutomaticActionType.ADD_PROJECT_LOG_MESSAGE,
      fields: [
        {
          name: FieldType.TEXTAREA_SINGLE,
          path: 'addProjectLogMessage',
          label: 'Log message',
          className: 'w-full mt-9',
        },
      ],
    },
  ];

  return (
    <FieldArray name="results.automatic">
      {({ push, remove, replace, name }) => (
        <DynamicTab
          path={name}
          remove={remove}
          replace={replace}
          push={push}
          options={automaticOptions}
          config={config}
          values={values.results.automatic}
        />
      )}
    </FieldArray>
  );
};

export default Automatic;
