import {FieldTemplate, Project} from '@contractool/schema';
import React, {FC} from 'react';

interface IHighlightedFieldProps {
    field: FieldTemplate;
    project: Project;
}

const HighlightedField: FC<IHighlightedFieldProps> = ({field, project}) => (
    <div className="rounded-xl bg-white p-6">
        <div className="text-gray-500">{field.label}</div>
        <div className="flex items-center mt-4">

            {project.fields_human[field.name] == 'No' &&
                <svg height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"
                     className="mr-3 text-red-300 fill-current w-4 h-4">
                    <path d="M24 24H0V0h24v24z" fill="none"></path>
                    <circle cx="12" cy="12" r="8"></circle>
                </svg>
            }

            {project.fields_human[field.name] == 'Yes' &&
            <svg height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"
                 className="mr-3 text-lime-500 fill-current w-4 h-4">
                <path d="M24 24H0V0h24v24z" fill="none"></path>
                <circle cx="12" cy="12" r="8"></circle>
            </svg>}


            {field.type == 'DATE' && <svg viewBox="0 0 24 24" className="mr-3 text-gray-500 fill-current w-4 h-4">
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path
                    d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z"></path>
            </svg>
            }
            <div className="align-middle">{project.fields_human[field.name]}</div>
        </div>
    </div>
);

export default HighlightedField;
