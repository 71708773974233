import * as React from 'react';
import { Modal } from 'components/Modal';
import { translate } from 'utils/translations';
import { CustomReportFilter, FilterTemplate } from '@contractool/schema';
import { Button } from 'components/Button';
import { loadFilterTemplates } from 'utils/filters';
import Search from 'components/Search/Search';
import { useState } from 'react';

const FiltersMap: React.FC<{
  setOpenModal: (value: boolean) => void;
  handleFilterUpdate: (updatedFilter: CustomReportFilter, idx: number) => void;
  idx: number;
  filters: CustomReportFilter[];
  workflow?: string;
}> = ({ setOpenModal, handleFilterUpdate, filters, idx, workflow }) => {
  if (!workflow) {
    workflow = 'default';
  }
  const { filterTemplates: groups, groupedFilterTemplates } =
    loadFilterTemplates(workflow);
  let [search, setSearch] = useState('');
  const getFilterType = React.useCallback(
    (property: string) =>
      property in groupedFilterTemplates
        ? groupedFilterTemplates[property][0].type
        : '',
    [groupedFilterTemplates],
  );
  const parsedFilters = React.useMemo(
    () => parseFilters(groups, search),
    [groups, search],
  );

  let fields = {
    roles: [],
    fields: [],
    dateFields: [],
  };
  if (groups.length > 0 && parsedFilters) {
    fields = getProperties(parsedFilters);
  }
  console.log('fields', fields);
  const updateProperty = React.useCallback(
    (value: any) => {
      const filterType = getFilterType(value);
      if (filterType === 'DATE_RANGE') {
        const dateRange = 'last_month';
        handleFilterUpdate(
          {
            ...filters[idx],
            property: value,
            values: [dateRange],
          },
          idx,
        );
      } else {
        handleFilterUpdate(
          {
            ...filters[idx],
            property: value,
          },
          idx,
        );
      }
      setOpenModal(false);
    },
    [setOpenModal, handleFilterUpdate, getFilterType, filters, idx],
  );

  const searchField = (phrase: string) => {
    setSearch(phrase);
  };

  return (
    <Modal
      heading={translate('Select a property')}
      onClose={() => {
        setOpenModal(false);
      }}
      size="-"
      corner={
        <Search
          placeholder={translate('Filter properties...')}
          value={search}
          onChange={searchField}
        />
      }
    >
      <div className="flex items-stretch h-96">
        <div
          className="flex items-stretch h-96 overflow-y-auto overflow-x-hidden"
          id="workflow-properties-list"
        >
          <div className="border-r">
            <MapMenu
              label={translate('Basic')}
              selected={(filters && filters[idx]?.property) ?? ''}
              items={
                parsedFilters && parsedFilters.basic
                  ? parsedFilters.basic ?? []
                  : []
              }
              selectValue={updateProperty}
            />
          </div>
          <div className="border-r">
            <MapMenu
              label={translate('Fields')}
              selected={(filters && filters[idx]?.property) ?? ''}
              items={fields ? fields.fields ?? [] : []}
              selectValue={updateProperty}
            />
          </div>
          <div className="border-r">
            <MapMenu
              label={translate('Roles')}
              selected={(filters && filters[idx]?.property) ?? ''}
              items={fields ? fields.roles ?? [] : []}
              selectValue={updateProperty}
            />
          </div>
          <div className="border-r">
            <MapMenu
              label={translate('Dates')}
              selected={(filters && filters[idx]?.property) ?? ''}
              items={fields ? fields.dateFields ?? [] : []}
              selectValue={updateProperty}
            />
          </div>
        </div>
      </div>
      <Modal.Footer className="flex justify-between">
        <Button
          color="white"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          {translate('Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

interface IMapItem {
  label: string;
  value: string;
  name?: string;
}

const MapMenu: React.FC<{
  barName?: string;
  items: IMapItem[];
  selected: string;
  label?: string;
  selectValue: (value: any) => void;
}> = ({ barName, items, selected, label, selectValue }) => {
  return (
    <>
      {label && <div className="p-3">{label}</div>}
      <div className={'flex flex-col items-center shrink-0 h-full w-60'}>
        <ul className="w-full">
          {items.map((item, index) => (
            <MapItem
              barName={barName}
              selected={selected}
              selectValue={selectValue}
              key={index}
              {...item}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

const MapItem: React.FC<
  IMapItem & {
    barName?: string;
    selected: string;
    selectValue: (value: string) => void;
  }
> = ({ barName, label, value, name, selected, selectValue }) => {
  return (
    <li
      className={`py-3 cursor-pointer hover:bg-gray-150 ${
        selected === value || selected === name
          ? 'bg-gray-150 border-r-2 border-blue-700'
          : ''
      }`}
      onClick={() => {
        selectValue(value ?? name);
      }}
    >
      <div
        className={`flex items-center hover:text-blue-700 focus:text-blue-700 focus:outline-none ${
          selected === value || selected === name
            ? 'text-blue-700 '
            : 'text-gray-600'
        }`}
      >
        <div className="ml-3 leading-tighter select-none">
          {barName === 'workflow' && value !== 'basic' && 'Workflow - '}
          {label}
        </div>
      </div>
    </li>
  );
};

const parseFilters = (groups: FilterTemplate[], search: string) => {
  let list = groups.filter((filter: FilterTemplate) => {
    return filter.label.toLowerCase().match(search.toLowerCase());
  });
  if (list.length === 0) {
    return false;
  }
  return list.reduce((prev: any, group) => {
    if (group.type === 'DATE_RANGE') {
      group.values = [
        { label: 'Last Week', value: 'last_week' },
        { label: 'Last Month', value: 'last_month' },
        { label: 'Last Year', value: 'last_year' },
        { label: 'Current Month', value: 'current_month' },
        { label: 'Current year', value: 'current_year' },
        { label: 'Custom range', value: 'custom_range' },
      ];
    }
    if (!group.name.includes('fields->') && !group.name.includes('role=')) {
      prev['basic'] = prev['basic'] ? [...prev['basic'], group] : [group];
    } else {
      prev['default'] = prev['default'] ? [...prev['default'], group] : [group];
    }

    return prev;
  }, {});
};

const getProperties = (parsedFilters: any) => {
  return parsedFilters?.default?.reduce((prev: any, group: any) => {
    if (group?.name.includes('fields->') && group?.type === 'DATE_RANGE') {
      return {
        ...prev,
        dateFields: prev?.dateFields ? [...prev?.dateFields, group] : [group],
      };
    } else if (group?.name.includes('role=') && group?.type !== 'DATE_RANGE') {
      return {
        ...prev,
        roles: prev?.roles ? [...prev?.roles, group] : [group],
      };
    } else if (group?.name.includes('fields->')) {
      return {
        ...prev,
        fields: prev?.fields ? [...prev?.fields, group] : [group],
      };
    }
    return prev;
  }, {});
};

export default FiltersMap;
