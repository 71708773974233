import React, {FC} from 'react';
import {useRequest} from 'hooks/useRequest';
import ReactECharts from 'echarts-for-react';
import {translate} from 'utils/translations';
import {Project} from '../../../../../_common/schema';

export const Workload: FC<{project: Project}> = ({project}) => {
    const [data] = useRequest<any>('/api/workload/' + project.id, undefined);

    const onChartReadyCallback = React.useCallback(() => {
        console.log('onChartReadyCallback');
    }, []);

    const EventsDict = React.useMemo(() => ({}), []);

    if (!data) {
        return null;
    }

    const style = {
        height: data.grid.height,
    };

    return (
        <div>
            <h1 className="text-2xl">{translate('Workload per person')}</h1>
            <div
                className="workload__echart--boundary"
                style={{paddingTop: '300px', overflow: 'hidden'}}
            >
                <ReactECharts
                    option={data}
                    notMerge={true}
                    lazyUpdate={true}
                    theme={'default'}
                    onChartReady={onChartReadyCallback}
                    onEvents={EventsDict}
                    style={style}
                />
            </div>
        </div>
    );
};
