import React, { FC, useContext, useCallback, useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { FieldTemplate, Project } from '@contractool/schema';
import { FormTab, WorklfowTab } from '@contractool/schema/FormTab';

import { DefaultForm } from './DefaultForm';
import ProgressFormBadge from './ProgressFormBadge';

import { Page } from 'components/layout/Page';
import { Form } from 'components/Form';
import { Button } from 'components/Button';
import { FormTabs, Tab } from 'components/Tabs';
import { InviteUserModal } from 'components/form/InviteUserModal';
import { Mutable as ProjectTitle } from 'components/form/ProjectTitle';
import { Form as DellJIForm } from 'integrations/dell_ji/components/views/project/components';

import { useToasts } from 'hooks';
import { http } from 'utils/http';
import { translate } from 'utils/translations';
import { disableTab, goToNextTab } from 'utils/newProject';
import { EventEmitter } from 'utils/eventEmitter';
import { fromQueryString, toQueryString } from 'utils/url';
import { useRequest } from 'hooks/useRequest';
import { getWorkflow } from 'hooks/workflows';

import { AppContext } from 'contexts';

import ProjectFormProvider from 'views/project/ProjectFormProvider';
import { useLocalStorage } from '@rehooks/local-storage';

type Props = {
  initialProject: Project;
  tab?: string;
  titleEdited: boolean;
  onChange?: (field: FieldTemplate) => void;
};

const FormContainer: FC<Props> = ({
  initialProject,
  tab,
  titleEdited,
  onChange,
}) => {
  const { config } = useContext(AppContext);

  return config.integration === 'dell_ji' ? (
    <DellJIForm project={initialProject} />
  ) : (
    <DefaultForm
      project={initialProject}
      tab={tab}
      titleEdited={titleEdited}
      onChange={onChange}
    />
  );
};

export function NewProjectForm() {
  const { config } = useContext(AppContext);
  const history = useHistory();

  const close = () => {
    history.push(`/projects/new/form${history.location.search}`);
  };
  const { success } = useToasts();

  const searchParams = fromQueryString(history.location.search);

  let url = `api/projects/new${history.location.search}`;
  let clone = false;
  if (searchParams && searchParams.clone !== undefined) {
    url = `api/projects/new/${searchParams.clone}${history.location.search}`;
    clone = true;
  }
  let [initialProject] = useRequest<Project | undefined>(url, undefined);

  const workflow: string =
    initialProject?.workflow || (searchParams.workflow as string) || 'default';
  const formTabs = getWorkflow(workflow).form_tabs;
  const metaData: FieldTemplate[] = getWorkflow(workflow).fields.meta_data;
  const settings = getWorkflow(workflow).settings;
  const [workflowTabs, setWorkflowTabs] = useState<WorklfowTab[]>([]);
  const [titleEdited, setTitleEdited] = useState<boolean>(false);

  const activeTab = workflowTabs.find((tab) => tab?.active);
  const isRegularForm = metaData.every(
    (field) => field.progressFormOnClick === false,
  );

  const [draft, setDraft, clearDraft] =
    useLocalStorage<Project>('_projectDraft');

  if (!clone && draft && initialProject) {
    if (
      (searchParams.workflow && searchParams.workflow === draft.workflow) ||
      (initialProject && initialProject.workflow === draft.workflow)
    ) {
      if (searchParams.fields) {
        history.replace(
          `/projects/new/form?workflow=${searchParams.workflow}&kind=Create%20project`,
        );
      } else {
        initialProject = draft;
      }
    } else {
      setDraft(initialProject);
    }
  }

  useEffect(() => {
    if (formTabs) {
      setWorkflowTabs(
        formTabs.map((tab: FormTab, index: number): WorklfowTab => {
          // Initial workflow setup based on URL param
          if (searchParams.tab) {
            const active = index === Number(searchParams.tab) ? true : false;
            const valid = index <= Number(searchParams.tab);
            return {
              ...tab,
              active,
              validated: false,
              valid,
            };
          }

          // Initial workflow setup based on config file
          const active = index === 0 ? true : false;
          const valid = active;
          return {
            ...tab,
            active,
            validated: false,
            valid,
          };
        }),
      );
    }
  }, [formTabs]);

  const handleSubmit = useCallback(
    async (values): Promise<any> => {
      const lastTab: FormTab =
        formTabs.length === 0 ? null : formTabs[formTabs.length - 1];

      const params = {
        tab: activeTab?.id,
      };

      const validationResult = await http.post<Project>(
        `/api/projects/validate?${toQueryString(params)}`,
        values,
      );

      goToNextTab({ formTabs, activeTab, setWorkflowTabs });

      // Call if validation went through
      // and user is on last tab
      if (activeTab?.id === lastTab?.id) {
        return http.post<Project>(`/api/projects`, values);
      }

      if (validationResult) {
        return validationResult;
      }

      return null;
    },
    [formTabs, workflowTabs],
  );

  const handleUserModalClose = useCallback(() => {
    EventEmitter.dispatch('api/users.invalidated-no-current', []);
    close();
  }, [close]);

  const handleSuccess = (data: any) => {
    if (data.id) {
      clearDraft();
      success(`${translate('Project was created')}.`);
      if (data.created_popup) {
        setCreatedPopup(data.created_popup);
      }
      history.push('/projects/' + data.id);
    } else {
      success(`${translate('Draft saved')}.`);
    }
  };

  const [, setCreatedPopup] = useLocalStorage('_createdPopup');

  const formName = 'new-project';
  EventEmitter.unsubscribe('form.setValues.' + formName);
  EventEmitter.subscribe('form.setValues.' + formName, (values: Project) => {
    setDraft(values);
  });

  const handleSelectTab = (name: string) => {
    const titleElement = document.getElementById('page-title');
    if (titleElement) {
      titleElement.scrollIntoView({ behavior: 'smooth' });
    }

    setWorkflowTabs(
      workflowTabs.map((tab) => {
        if (tab?.title === name) {
          return {
            ...tab,
            active: true,
          };
        }
        return {
          ...tab,
          active: false,
        };
      }),
    );
  };

  return (
    <>
      <Switch>
        <Route
          path={'/projects/new/form/new-user/:userGroup'}
          render={() => <InviteUserModal onClose={handleUserModalClose} />}
        />
      </Switch>
      <Page
        heading={translate('New Project Request')}
        right={
          <>
            {!clone && draft && (
              <Button
                color="white"
                onClick={() => {
                  clearDraft();
                  window.location.reload();
                }}
              >
                {translate('Clear draft')}
              </Button>
            )}
          </>
        }
      >
        {initialProject && (
          <Form
            name={formName}
            initialValues={{
              ...initialProject,
            }}
            onSuccess={handleSuccess}
            onSubmit={handleSubmit}
            allowPristineSubmission={true}
            guard={false}
            loader={'big'}
          >
            <div style={{ maxWidth: '960px' }}>
              <ProjectFormProvider create={true}>
                <>
                  {formTabs.length > 0 ? (
                    <>
                      <ProjectTitle
                        className="mb-8 mt-8"
                        canEdit={settings.edit_title}
                        titleEdited={() => {
                          setTitleEdited(true);
                        }}
                        legend={settings.title_legend}
                      />
                      <FormTabs
                        selected={activeTab?.title}
                        onSelect={handleSelectTab}
                      >
                        {formTabs.map(
                          (tab: FormTab, index: number, array: FormTab[]) => {
                            let nextTabIndex = index++;
                            nextTabIndex++;
                            const displayNextStepButton =
                              nextTabIndex !== array.length;
                            const displayCreateProjectButton =
                              displayNextStepButton === false;

                            return (
                              initialProject && (
                                <Tab
                                  key={tab?.id}
                                  name={tab?.title}
                                  heading={tab?.title}
                                  className="w-full h-auto overflow-auto mb-20"
                                  disabled={disableTab(workflowTabs, tab)}
                                >
                                  <ProgressFormBadge
                                    fields={metaData}
                                    initialProject={initialProject}
                                  />
                                  <FormContainer
                                    initialProject={initialProject}
                                    tab={tab?.id}
                                    titleEdited={titleEdited}
                                    onChange={(field: FieldTemplate) => {
                                      if (field.progressFormOnClick) {
                                        goToNextTab({
                                          formTabs,
                                          activeTab,
                                          setWorkflowTabs,
                                        });
                                      }
                                    }}
                                  />
                                  {(isRegularForm ||
                                    displayCreateProjectButton) && (
                                    <Form.Submit mode="">
                                      {translate(
                                        displayNextStepButton
                                          ? 'Next Step'
                                          : 'Create Project',
                                      )}
                                    </Form.Submit>
                                  )}
                                </Tab>
                              )
                            );
                          },
                        )}
                      </FormTabs>
                    </>
                  ) : (
                    <>
                      {config.integration !== 'dell_ji' && <ProjectTitle
                        className="mb-8 mt-8"
                        canEdit={settings.edit_title}
                        titleEdited={() => {
                          setTitleEdited(true);
                        }}
                        legend={settings.title_legend}
                      />}
                      <FormContainer
                        initialProject={initialProject}
                        titleEdited={titleEdited}
                      />
                      <Form.Submit mode="">
                        {translate('Create Project')}
                      </Form.Submit>
                    </>
                  )}
                </>
              </ProjectFormProvider>
            </div>
          </Form>
        )}
      </Page>
    </>
  );
}
