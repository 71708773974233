import React, { FC } from 'react';

import {
  AdvancedRadioValue,
  FieldTemplate,
  Project,
} from '@contractool/schema';

import { getWorkflow } from 'hooks/workflows';

import Badge from 'components/Badge';
import { Icon } from 'components/Icon';

export const ProjectLabels: FC<{ project: Project }> = ({ project }) => {
  let fields = getWorkflow(project.workflow).fields.meta_data.filter(
    (field: FieldTemplate) => {
      return field.showInProjectHeader;
    },
  );

  return (
    <>
      {fields.map((field: FieldTemplate) => {
        let selected = field.advancedRadioValues.filter(
          (option: AdvancedRadioValue) => {
            return option.value === project.fields[field.name];
          },
        );
        if (selected.length === 0) {
          return null;
        }

        return (
          <span key={field.name}>
            {selected[0]?.icon && (
              <div className="pr-3">
                <Badge
                  icon={<Icon name={selected[0].icon} size={4} />}
                  color={selected[0].color}
                >
                  {selected[0].title}
                </Badge>
              </div>
            )}
          </span>
        );
      })}
    </>
  );
};
