import React, { FC, useEffect, useState } from 'react';
import { Button } from 'components/Button';
import { Confirmation } from 'components/Confirmation';
import { Form } from 'components/Form';
import { Icon } from 'components/Icon';
import { Modal } from 'components/Modal';
import { CustomField } from 'components';
import { http } from 'utils/http';
import { PreconditionResponse } from './ProjectActions';
import { Project, User } from '@contractool/schema';
import { permissionRequest } from 'utils/wildcard';
import { translate } from 'utils/translations';
import { useBigLoader } from 'components/Loader';
import { Route, Switch, useHistory } from 'react-router-dom';
import { InviteUserModal } from 'components/form/InviteUserModal';
import { useToasts } from 'hooks';

export const ProgressPanel: FC<{
  project: Project;
  onRefresh: () => void;
  projectLoading: boolean;
  setProjectLoading: (loading: boolean) => void;
}> = ({ project, onRefresh, projectLoading, setProjectLoading }) => {
  const loader = useBigLoader();
  const { success } = useToasts();
  const [loadingText, setLoadingText] = useState<string>('');
  const canceled = ['denied', 'canceled'].some((item) =>
    project.state.key.includes(item),
  );
  const [modal, setModal] = useState(false);
  const [response, setResponse] = useState<PreconditionResponse>();
  const [modalAction, setModalAction] = useState('progress');
  const history = useHistory();
  const currentUrl = history.location.pathname;
  let inviteApproverModalPath = `${currentUrl.replace(
    /\/new-user\/.*/,
    '/new-user/:userGroup',
  )}`;
  if (!inviteApproverModalPath.match(/new-user/)) {
    inviteApproverModalPath += '/new-user/:userGroup';
  }
  const closeInviteUser = () => {
    let backUrl = currentUrl.replace(/\/new-user\/.*/, '');
    console.log('backUrl', backUrl);
    history.push(backUrl);
  };
  const permissions = project.can;

  const TypeColor = (type: string) => {
    switch (type) {
      case 'past': {
        return { backgroundColor: 'bg-blue-700', textColor: 'text-blue-700' };
      }
      case 'current': {
        return {
          backgroundColor: 'bg-yellow-800',
          textColor: 'text-yellow-800',
        };
      }
      case 'finished': {
        return { backgroundColor: 'bg-green-700', textColor: 'text-green-700' };
      }
      case 'canceled': {
        return { backgroundColor: 'bg-gray-500', textColor: 'text-gray-600' };
      }
      // TODO: not designed skipped state
      case 'past-dimmed': {
        return { backgroundColor: 'bg-gray-500', textColor: 'text-gray-500' };
      }
      default:
        return { backgroundColor: 'bg-gray-100', textColor: 'text-gray-600' };
    }
  };

  const progress = (value: string) => {
    setProjectLoading(true);

    return http
      .put(`/api/projects/${project.id}/state`, {
        input: value,
      })
      .then(onRefresh)
      .catch((error) => {
        setProjectLoading(false);
        if (error.response?.status !== 412) {
          return;
        }
        setResponse(error.response.data.data);
        setModal(true);
        setModalAction(value);
      });
  };

  useEffect(() => {
    if (projectLoading) {
      loader.start(loadingText);
    } else {
      loader.stop();
    }
  }, [loader, projectLoading, loadingText]);

  return (
    <>
      <div className="mt-16 invisible hidden md:flex md:visible ">
        {project.statistics
          .filter((statistic) => statistic.type !== 'canceled')
          .map((statistic, index) => {
            const { backgroundColor, textColor } = canceled
              ? TypeColor(statistic.type)
              : TypeColor(statistic.type);

            return (
              <div
                key={index}
                className="w-full mr-1"
                data-cy-type={statistic.type}
              >
                <div className="text-center text-gray-600 mb-4">
                  {statistic.duration ? (
                    <div className={textColor}>
                      {/* TODO: not designed sipped state */}
                      {statistic.duration === 'skipped'
                        ? 'Skipped'
                        : statistic.duration}
                    </div>
                  ) : (
                    <div className={textColor}>
                      {statistic.type === 'finished' ? '-' : '-'}
                    </div>
                  )}
                </div>
                <div
                  className={`w-full mr-1 h-1 mb-4 rounded-full ${backgroundColor}`}
                ></div>
                <div className="text-center text-gray-600">
                  {statistic.title}
                </div>
              </div>
            );
          })}
        <div className="flex ml-12">
          {project.state.transitions.map((transition, index) => {
            return (
              <div key={index} className="self-center">
                {transition.value === 'progress' &&
                  permissionRequest(permissions, 'project.progress') && (
                    <Button
                      onClick={() => {
                        setLoadingText(
                          `${translate('Progressing to the next state')}...`,
                        );
                        progress(transition.value + '');
                      }}
                      className="whitespace-nowrap"
                    >
                      {translate(transition.label)}
                      <Icon className="ml-3" name="arrow_forward" size={5} />
                    </Button>
                  )}
                {transition.value === 'regress' &&
                  permissionRequest(permissions, 'project.regress') && (
                    <Confirmation
                      onConfirm={() => {
                        setLoadingText(
                          `${translate('Regressing to the previous state')}...`,
                        );
                        progress(transition.value + '');
                      }}
                      trigger={({ onClick }) => (
                        <Button
                          onClick={onClick}
                          title={translate(transition.label)}
                          className="mr-4 regress-button"
                          id="regress-button"
                          color="white"
                          icon="settings_restore"
                        />
                      )}
                      heading={translate(transition.label)}
                      buttonText={`${translate('Yes, :value', {
                        value: translate(transition.value),
                      })}`}
                      color="yellow"
                    >
                      {translate(
                        project.state.key === 'completed'
                          ? 'Are you sure that you want to re-open this project?'
                          : 'Are you sure that you want to regress this project to the previous state?',
                      )}
                    </Confirmation>
                  )}
                {transition.value && transition.value.match(/reopen/) && (
                  <div>
                    <Confirmation
                      onConfirm={() => {
                        setLoadingText(`${translate('Re-opening project')}...`);
                        progress(transition.value + '');
                      }}
                      trigger={({ onClick }) => (
                        <Button
                          onClick={onClick}
                          color="white"
                          className="whitespace-nowrap"
                        >
                          <Icon className="mr-3" name="refresh" size={5} />
                          {transition.label}
                        </Button>
                      )}
                      heading={translate(transition.label)}
                      buttonText={`${translate('Yes, re-open')}`}
                      color="yellow"
                    >
                      {transition.extra.confirm}
                    </Confirmation>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <Modal
          heading={
            response?.title
              ? response.title
              : `${translate('Before you continue')}...`
          }
          isOpen={modal}
          onClose={() => {
            setModal(false);
            setProjectLoading(false);
          }}
          size={response?.width}
        >
          <Switch>
            <Route
              path={inviteApproverModalPath}
              render={() => (
                <InviteUserModal
                  onClose={closeInviteUser}
                  onSuccess={(user: User) => {
                    success(
                      `User ${user.name}has been added to the dropdown options.`,
                    );
                    progress(modalAction);
                  }}
                />
              )}
            />
          </Switch>
          <Form
            initialValues={{ ...response?.state }}
            onSubmit={(values) =>
              http.put(`/api/projects/${project.id}/state`, {
                dialog: values,
                input: modalAction,
              })
            }
            onSuccess={() => {
              onRefresh();
              setModal(false);
            }}
            allowPristineSubmission
            loader="big"
            loaderText={loadingText}
          >
            {response?.text && <div className="my-10">{response.text}</div>}
            {response &&
              response.form.map((field, index) => (
                <CustomField
                  field={field}
                  option=""
                  key={`modal-field-${index}`}
                  className="mb-5"
                />
              ))}

            <Modal.Footer className="flex justify-between">
              <Button
                color="white"
                onClick={() => {
                  setModal(false);
                  setProjectLoading(false);
                }}
              >
                {translate('Cancel')}
              </Button>
              <Form.Submit>{translate('Save Changes')}</Form.Submit>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
      <div className="mt-16 visible flex md:hidden md:invisible ">
        {project.statistics
          .filter((statistic) =>
            ['finished', 'current'].includes(statistic.type),
          )
          .map((statistic, index) => {
            const { backgroundColor, textColor } = canceled
              ? TypeColor(statistic.type)
              : TypeColor(statistic.type);

            return (
              <div
                key={index}
                className="w-full mr-1"
                data-cy-type={statistic.type}
              >
                <div className="text-center text-gray-600 mb-4">
                  {statistic.duration ? (
                    <div className={textColor}>
                      {/* TODO: not designed sipped state */}
                      {statistic.duration === 'skipped'
                        ? 'Skipped'
                        : statistic.duration}
                    </div>
                  ) : (
                    <div className={textColor}>-</div>
                  )}
                </div>
                <div
                  className={`w-full mr-1 h-1 mb-4 rounded-full ${backgroundColor}`}
                ></div>
                <div className="text-center text-gray-600">
                  {statistic.title}
                </div>
              </div>
            );
          }, null)}
      </div>
    </>
  );
};
