import React, {
  ReactElement,
  FC,
  ReactNode,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import Downshift, {
  DownshiftState,
  StateChangeOptions,
  GetInputPropsOptions,
} from 'downshift';
import { Manager, Reference, Popper } from 'react-popper';
import { createPortal } from 'react-dom';
import { Placement } from 'popper.js';
import matchSorter from 'match-sorter';

import { Icon } from 'components/Icon';
import { EventEmitter } from 'utils/eventEmitter';
import { http } from 'utils/http';
import highlightWord from 'utils/highlightWord';

export interface Option<T> {
  label: string;
  value: T;
}

export function Dropdown<T>({
  name,
  value, // value injection
  options,
  placeholder,
  api,
  onChange,
  onBlur,
  apiParams,
  hasError = false,
  usePortal = true,
  autocomplete = false,
  highlight = true,
  highlightClass = 'rounded border border-blue-200 font-bold ',
  autoFocus = false,
  dropdownPlacement = 'bottom-start',
  choicesChanged,
  disableUpdate = false,
  renderInput = DefaultRenderInput,
  readOnly,
}: {
  name?: string;
  value: T | null;
  options?: Option<T>[];
  api?: string;
  onChange: (value: T) => void;
  onBlur?: (e: any) => void;
  apiParams?: object;
  placeholder?: string;
  hasError?: boolean;
  usePortal?: boolean;
  autocomplete?: boolean;
  highlight?: boolean;
  highlightClass?: string;
  autoFocus?: boolean;
  dropdownPlacement?: Placement;
  choicesChanged?: () => void;
  disableUpdate?: boolean;
  renderInput?: FC<RenderInputProps<T>>;
  readOnly?: boolean;
}): ReactElement {
  const [realOptions, setRealOptions] = useState<Option<T>[]>(
    options ? options : [],
  );
  const [selectedItem, setSelectedItem] = useState<Option<T> | null>(null);
  const inputVal = useRef<string>('');
  const refDownshift = useRef<any>();
  //temporary input for select field for saving data with autocomplete and clearSelection
  const [temporaryInput, setTemporaryInput] = useState<any>();
  const loadOptions = useCallback(
    (key: string) => {
      api &&
        http
          .get<any>(api, {
            ...apiParams,
            phrase: key,
            dropdown: '1',
          })
          .then((response) => {
            setRealOptions(
              response.data.map((item: any) => ({
                label: item.name,
                value: item.id,
              })),
            );
            if (choicesChanged) {
              choicesChanged();
            }
          });
    },
    [api, apiParams, choicesChanged],
  );

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      loadOptions(inputVal.current);

      if (options) {
        setRealOptions(options);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [inputVal, options, loadOptions]);

  // temporary solution, todo: update using rest-hook
  useEffect(() => {
    if (api) {
      EventEmitter.subscribe(api + '.invalidated', () => {
        loadOptions(inputVal.current);
      });
      EventEmitter.subscribe(api + '.invalidated-no-current', () => {
        loadOptions('');
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      // value + '' === '' && refDownshift.current.clearSelection();
    }

    return () => {
      isSubscribed = false;
    };
  }, [value]);

  const handleChange = (selectedItem: Option<T> | null): void => {
    selectedItem && onChange(selectedItem.value);
    !disableUpdate && setSelectedItem(selectedItem);
  };

  const itemToString = (item: Option<T> | null): string => {
    return item ? item.label : '';
  };

  const findSelectedIndex = (
    selectedItem: Option<T> | null | undefined,
  ): number => {
    return realOptions.findIndex(
      (option) => option.value === selectedItem?.value,
    );
  };

  function stateReducer(
    state: DownshiftState<Option<T>>,
    changes: StateChangeOptions<Option<T>>,
  ) {
    if (changes.isOpen === undefined) {
      return changes;
    }

    return {
      ...changes,
      highlightedIndex: findSelectedIndex(
        changes.selectedItem || state.selectedItem,
      ),
    };
  }

  function render(content: ReactNode): ReactNode {
    return usePortal ? createPortal(content, document.body) : content;
  }

  const filterOptions = (
    options: Option<T>[],
    filter: string | null,
  ): Option<T>[] => {
    if (autocomplete && filter) {
      return matchSorter(options, filter || '', {
        keys: ['value', 'label'],
      });
    }

    return options;
  };

  useEffect(() => {
    if (value || (typeof value === 'number' && value === 0)) {
      const selectedItem =
        realOptions.find((option) => option.value === value) || null;
      !disableUpdate && setSelectedItem(selectedItem);
    }
  }, [value, realOptions]);

  return (
    <Manager>
      <Downshift
        selectedItem={selectedItem}
        stateReducer={stateReducer}
        itemToString={itemToString}
        onChange={handleChange}
        onStateChange={(changes) => {
          if (changes.hasOwnProperty('inputValue')) {
            api && (inputVal.current = changes.inputValue || '');
          }
        }}
        ref={refDownshift}
      >
        {({
          isOpen,

          openMenu,
          toggleMenu,

          selectedItem,
          highlightedIndex,

          getMenuProps,
          getItemProps,
          getInputProps,

          inputValue,
          clearSelection,
        }) => (
          <div id={name}>
            <Reference>
              {({ ref }) => (
                <div ref={ref}>
                  {renderInput({
                    name,
                    ref,
                    selectedItem,
                    placeholder,
                    hasError,
                    isOpen,
                    openMenu,
                    toggleMenu,
                    onBlur,
                    getInputProps,
                    autocomplete,
                    autoFocus,
                    setSelectedItem,
                    setTemporaryInput,
                    temporaryInput,
                    clearSelection,
                    inputValue,
                    disableUpdate,
                    readOnly,
                  })}
                </div>
              )}
            </Reference>

            {isOpen &&
              render(
                <Popper
                  modifiers={{
                    hide: { enabled: true },
                    preventOverflow: { enabled: false },
                  }}
                  placement={dropdownPlacement}
                >
                  {({ ref, style, placement }) => (
                    <div
                      ref={ref}
                      style={style}
                      data-placement={placement}
                      className="bg-white rounded-lg shadow-lg py-2"
                    >
                      <ul
                        {...getMenuProps()}
                        className="max-h-66 overflow-auto px-2"
                      >
                        {filterOptions(realOptions, inputValue).map(
                          (item, index) => {
                            const highlighted = highlightedIndex === index;
                            const selected = selectedItem?.value === item.value;

                            return (
                              <li
                                {...getItemProps({
                                  key: String(item.value) + name + index,
                                  index,
                                  item,
                                })}
                                className={`px-6 py-3 leading-tighter rounded cursor-pointer ${
                                  highlighted ? 'bg-gray-100' : ''
                                } ${selected ? 'text-blue-500' : ''}`}
                              >
                                {autocomplete && highlight
                                  ? highlightWord(
                                      item.label,
                                      inputValue,
                                      highlightClass,
                                    )
                                  : item.label}
                              </li>
                            );
                          },
                        )}
                      </ul>
                    </div>
                  )}
                </Popper>,
              )}
          </div>
        )}
      </Downshift>
    </Manager>
  );
}

export interface RenderInputProps<T> {
  name?: string;
  ref: React.Ref<any>;
  selectedItem: Option<T> | null;
  placeholder: string | undefined;
  hasError: boolean;
  isOpen: boolean;
  openMenu: () => void;
  toggleMenu: () => void;
  onBlur?: (e: any) => void;
  getInputProps: <T>(options?: T | undefined) => T & GetInputPropsOptions;
  autocomplete: boolean;
  autoFocus?: boolean;
  setSelectedItem?: any;
  setTemporaryInput?: any;
  temporaryInput?: any;
  clearSelection: () => void;
  inputValue: any;
  disableUpdate: boolean;
  readOnly?: boolean;
}

function DefaultRenderInput<T>({
  name,
  hasError,
  placeholder,
  isOpen,
  openMenu,
  toggleMenu,
  onBlur,
  getInputProps,
  autocomplete,
  autoFocus,
  setSelectedItem,
  selectedItem,
  temporaryInput,
  setTemporaryInput,
  clearSelection,
  inputValue,
  disableUpdate,
  readOnly,
}: RenderInputProps<T>) {
  const inputProps = getInputProps();

  const handleBlur = (e: any) => {
    if (selectedItem == null && temporaryInput != null) {
      setSelectedItem(temporaryInput);
    }
    if (inputProps.onBlur) {
      inputProps.onBlur(e);
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  const showHideMenu = () => {
    if (!readOnly) {
      selectedItem && setTemporaryInput(selectedItem);
      autocomplete && clearSelection();
      toggleMenu();
    }
  };

  const cursorClass = readOnly ? null : 'cursor-pointer';

  return (
    <div className={`relative dropdown-${name}`}>
      <input
        type="search"
        className={`h-16 py-5 w-full leading-tighter outline-none focus:outline-none focus:border-blue-700 text-gray-700 border-b ${cursorClass} ${
          hasError ? 'border-red-700' : 'border-gray-200'
        }`}
        style={{ backgroundColor: 'transparent' }}
        readOnly={readOnly || !autocomplete}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...getInputProps({
          onClick: showHideMenu,
          onKeyDown: (e) => {
            switch (e.key) {
              case 'Enter':
                if (!isOpen) {
                  openMenu();
                }
                break;
            }
          },
          value: disableUpdate ? undefined : inputValue,
          id: name,
        })}
        onBlur={handleBlur}
      />
      {!readOnly && (
        <button
          onClick={showHideMenu}
          className="absolute w-4 h-16 top-0 right-0 flex items-center justify-center focus:outline-none"
          tabIndex={-1}
          onBlur={handleBlur}
        >
          <Icon
            name="triangle"
            className={`text-gray-600 ${isOpen ? '' : 'rotate-180'}`}
            size={2}
          />
        </button>
      )}
    </div>
  );
}
