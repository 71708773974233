import * as React from 'react';

import {Dropdown, Option} from 'components/Dropdown';

const options: Option<string>[] = [
    {label: 'Last Week', value: 'last_week'},
    {label: 'Last Month', value: 'last_month'},
    {label: 'Last Year', value: 'last_year'},
    {label: 'Current Month', value: 'current_month'},
    {label: 'Current year', value: 'current_year'},
    {label: 'Custom range', value: 'custom_range'},
    {label: 'Less than', value: 'less_than'},
    {label: 'More than', value: 'more_than'},
];

const DateRangeSelect: React.FC<{
    values: string[];
    onChange: (result: Option<string>[]) => void;
}> = ({values, onChange}) => {
    const [value, setValue] = React.useState(values[0] || '');
    const handleChange = (updatedValue: string) => {
        setValue(updatedValue);
        const option = options.find(({value}) => value === updatedValue);
        onChange(option ? [option] : []);
    };

    React.useEffect(() => {
        setValue(values[0] || '');
    }, [values]);

    return <Dropdown name={'date-range'} value={value} onChange={handleChange} options={options} />;
};

export default DateRangeSelect;
