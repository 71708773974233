import { FieldTemplate, LogFull } from '@contractool/schema';
import React, { FC, useContext } from 'react';
import { difference, groupBy } from 'lodash';

import { Tab } from 'pages/projects/Widgets/Group/components/Member/interfaces';
import {
  ActivityContainer,
  ActivityHeader,
  ActivityBody,
} from '../../ActivityPanel';
import ProjectContext from 'views/projects/ProjectContext';

interface Map {
  [key: string]: any;
}

const MetaUpdateRenderer: FC<{ from: any; to: any }> = ({ from, to }) => {
  // todo: find better solution
  const { workflow } = useContext(ProjectContext);
  let widgetFields = workflow.widget_fields;

  const oldWidgetFields = from?.widget_fields;
  const newWidgetFields = to?.widget_fields;
  const widgetFieldExists = (member: string) => {
    return widgetFields[member];
  };

  const oldMembers = Object.keys(oldWidgetFields || {}).filter(
    widgetFieldExists,
  );
  const newMembers = Object.keys(newWidgetFields || {}).filter(
    widgetFieldExists,
  );

  const addedMembers = difference(newMembers, oldMembers).filter(
    widgetFieldExists,
  );
  const removedMembers = difference(oldMembers, newMembers).filter(
    widgetFieldExists,
  );

  // eslint-disable-next-line
  const fields = React.useMemo(() => {
    const map: Map = {};
    for (let member in widgetFields) {
      if (widgetFields.hasOwnProperty(member)) {
        map[member] = widgetFields[member].tabs
          ? widgetFields[member].tabs.reduce(
              (fields: FieldTemplate[], tab: Tab) => [...fields, ...tab.fields],
              [],
            )
          : widgetFields[member].fields;

        map[member] = groupBy(map[member], 'name');
      }
    }

    return map;
  }, [widgetFields]);

  return (
    <div>
      {addedMembers.map((member, idx) => (
        <div key={idx}>Added new field - {widgetFields[member].name}</div>
      ))}
      {removedMembers.map((member, idx) => (
        <div key={idx}>Removed a field - {widgetFields[member].name}</div>
      ))}
      {newMembers &&
        newMembers
          .filter((member) => !addedMembers.includes(member))
          .filter(
            (member) =>
              JSON.stringify(oldWidgetFields[member].values) !==
              JSON.stringify(newWidgetFields[member].values),
          )
          .map((member, idx) => (
            <div key={idx}>Updated field - {widgetFields[member].name}</div>
          ))}
    </div>
  );
};

const ProjectUpdateLog: FC<{
  desc: string;
  log: LogFull;
}> = ({ desc, log }) => {
  const metaChanges = log.message.extra.changes?.meta;

  return (
    <ActivityContainer>
      <ActivityHeader
        causer={log.causer}
        desc={desc}
        created_at={log.created_at}
      />
      {metaChanges?.from || metaChanges?.to ? (
        <ActivityBody>
          <MetaUpdateRenderer from={metaChanges.from} to={metaChanges.to} />
        </ActivityBody>
      ) : (
        <ActivityBody className="flex items-center">
          <div
            className="flex items-center"
            dangerouslySetInnerHTML={{
              __html: log.message.body + '',
            }}
          />
        </ActivityBody>
      )}
    </ActivityContainer>
  );
};

const PureProjectUpdateLog = React.memo(ProjectUpdateLog);

export default PureProjectUpdateLog;
