import React from 'react';

import { getColors } from './IconCircle';

export type Props = {
  icon?: React.ReactNode;
  color?: string;
};

const Badge: React.FC<Props> = ({ icon, color = 'blue', children }) => {
  let colors = getColors(color);

  return (
    <div
      className={`${colors.bgColor} ${colors.textColor} relative inline-flex flex-auto box-border justify-center items-center rounded-full pl-4 pr-4`}
    >
      {icon && <div className="flex shrink-0 pr-2">{icon}</div>}
      <div className="pt-2 pb-2 leading-none">{children}</div>
    </div>
  );
};

export default Badge;
