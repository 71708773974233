import React from 'react';
import { FieldArray, FormikProps, useFormikContext } from 'formik';

import DynamicTab from '../Then';

import {
  SettingsForm,
  AskUserActionType,
  FieldType,
} from 'types/output/setting';
import { askUserOptions } from '@constants/settings';

const AskUser = () => {
  const { values }: FormikProps<SettingsForm> = useFormikContext();
  const config = [
    {
      action: AskUserActionType.FILL_SOME_METADATA,
      fields: [
        {
          name: FieldType.METADATA,
          path: 'fillSomeMetadata',
          className: 'w-202',
        },
      ],
    },
    {
      action: AskUserActionType.CHOOSE_USER_TO_ROLE,
      fields: [
        {
          name: FieldType.ROLE,
          path: 'chooseUserToRole',
          className: 'w-202',
        },
      ],
    },
  ];

  return (
    <FieldArray name="results.askUser">
      {({ push, remove, replace, name }) => (
        <DynamicTab
          path={name}
          remove={remove}
          replace={replace}
          push={push}
          options={askUserOptions}
          config={config}
          values={values.results.askUser}
        />
      )}
    </FieldArray>
  );
};

export default AskUser;
