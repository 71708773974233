import React, { useContext } from "react";
import { Approval, Project } from "@contractool/schema";
import { Form } from "components/Form";
import { http } from "utils/http";
import { translate } from "utils/translations";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { useToasts } from "hooks";
import { GuardContext } from "contexts";
import { EventEmitter } from "utils/eventEmitter";
import { InviteUserModal } from "components/form/InviteUserModal";
import {useRequest} from "hooks/useRequest";
import {getOptions} from "utils/form";

export function ApprovalNew({ onCreate, project }: { onCreate: () => void; project: Project }) {
    const history = useHistory();
    const { success } = useToasts();

    const { user } = useContext(GuardContext);

    const currentUrl = history.location.pathname;
    const close = () => {
        history.push(`/projects/${project.id}`);
    };
    const closeInviteUser = () => {
        let backUrl = currentUrl.replace("/new-user/approver", "");
        console.log("backUrl", backUrl);
        history.push(backUrl);
    };
    const handleUserModalClose = () => {
        EventEmitter.dispatch("api/users.invalidated-no-current", []);
        closeInviteUser();
    };
    const inviteApproverModalLink = `${currentUrl.replace("/new-user/approver", "")}/new-user/approver`;
    const inviteApproverModalPath = `${currentUrl.replace("/new-user/approver", "")}/new-user/:userGroup`;
    console.log("inviteApproverModalPath", inviteApproverModalPath, currentUrl);
    console.log("inviteApproverModalLink", inviteApproverModalLink, currentUrl);

    return (
        <Modal heading={translate("Add approval")} onClose={close}>
            <Switch>
                <Route
                    path={inviteApproverModalPath}
                    render={() => <InviteUserModal onClose={handleUserModalClose} />}
                />
            </Switch>
            <Form
                initialValues={{
                    title: "",
                    body: "",
                    deadline: null,
                    assignee_id: user.id
                }}
                onSubmit={(values) => http.post<Approval>(project.approvals_url, values)}
                onSuccess={(approval: Approval) => {
                    close();
                    onCreate();
                    success(
                        `${translate("Approval :title was added successfully", {
                            title: approval.title
                        })}`
                    );
                }}
            >
                <Form.TextInput
                    name="title"
                    label={translate("Title")}
                    className="mb-6"
                    autoFocus={true}
                />
                <Form.DatePicker
                    name="deadline"
                    label={translate("Due date")}
                    className="mb-6"
                    formatInput="dd MMM yyyy"
                />
                <Form.UserDropdown
                    api="api/users?deleted=false"
                    name="assignee_id"
                    label={translate("Approver")}
                    className="mb-6"
                    autocomplete
                    choicesChanged={() => {

                    }}
                    right={<Link
                        className="text-blue-600 hover:text-blue-300"
                        to={inviteApproverModalLink}
                    >
                        {translate("+ Invite Approver")}
                    </Link>}
                />
                <Form.TextArea name="body" label={translate("Description")} rows={6} />

                <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={close}>
                        {translate("Cancel")}
                    </Button>

                    <Form.Submit>{translate("Create Approval")}</Form.Submit>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
