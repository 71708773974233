import ButterToast from 'butter-toast/dist/lean.min.js';
import React from 'react';

import {Toast, TVariation} from 'components/Toast';
import {Icons} from 'components/Icon';
import {TPosition} from 'components/Notifications';
import {translate} from 'utils/translations';

const RaiseToast = (
    variation: TVariation,
    message: string = '',
    position: TPosition = 'bottom-center',
    icon: Icons = 'visibility',
    ttl: number = 6000,
) =>
    ButterToast.raise({
        namespace: position,
        timeout: ttl,
        content: ({dismiss}: any) => (
            <Toast variation={variation} onClose={dismiss} icon={icon} message={message} />
        ),
    });

export default () => {
    const toaster =
        (variation: TVariation) =>
        (
            message: string,
            options: {
                position?: TPosition;
                icon?: Icons;
                closeTimeout?: number;
            } = {},
        ) =>
            RaiseToast(
                variation,
                translate(message),
                options.position,
                options.icon,
                options.closeTimeout,
            );

    return {
        success: toaster('success'),
        error: toaster('error'),
        warning: toaster('warning'),
        info: toaster('info'),
    };
};
