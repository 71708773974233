import React, { FC, useMemo, Fragment } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { Icon } from '../../../components/Icon';
import {
  ActivityContainer,
  ActivityHeader,
  ActivityBody,
} from '../ActivityPanel';
import { Log } from '@contractool/schema';
import { translate } from 'utils/translations';
import _ from 'lodash';
import './../Widgets/Milestones.css';

export const MilestonesLog: FC<{ log: Log }> = ({ log }) => {
  const newStyles: any = {
    variables: {
      light: {
        diffViewerBackground: '#F1EFEF',
        diffViewerColor: '#24292e',
        addedBackground: '#F1EFEF',
        addedColor: '#24292e',
        removedBackground: '#ffffff',
        removedColor: '#24292e',
        wordAddedBackground: '#BFD8FE',
        wordRemovedBackground: '#BFD8FE',
      },
    },
    line: {
      padding: '10px 2px',
    },
  };

  const groupedChanges = useMemo(
    () =>
      Object.values(
        _.groupBy(
          log.message.extra.milestones.changes,
          (change) => change.id + change.action,
        ),
      ),
    [log],
  );

  return (
    <ActivityContainer>
      <ActivityHeader
        causer={log.causer}
        desc={log.message.title}
        created_at={log.created_at}
        old={log.message.extra.milestones?.old}
      />
      <ActivityBody className="mb-8 mr-14">
        {groupedChanges.map(
          (singleMilestoneChanges: any, milestoneIdx: number) => (
            <Fragment key={milestoneIdx}>
              <div className="text-lg mt-5 mb-2">
                {translate('Milestone')} #{singleMilestoneChanges[0].id + 1}(
                {singleMilestoneChanges.length === 1
                  ? singleMilestoneChanges[0].action === 'create'
                    ? translate('Created')
                    : singleMilestoneChanges[0].action === 'delete'
                    ? translate('Deleted')
                    : translate('Edited')
                  : translate('Edited')}
                )
              </div>
              {singleMilestoneChanges.map((change: any, idx: number) => (
                <div key={idx}>
                  {typeof change.key === 'number' ? (
                    <>
                      <div className="mb-4">
                        <div className="text-gray-600">
                          {translate('Dell Engineering activities')}
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: change.to.value.dell,
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <div className="text-gray-600">
                          {translate('Proof of performance')}
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: change.to.value.proof,
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <div className="text-gray-600">
                          {translate('Payment amount')}
                        </div>
                        <div>$ {change.to.value.amount}</div>
                      </div>
                      <div className="mb-4">
                        <div className="text-gray-600">
                          {translate('Status')}
                        </div>
                        <div>{change.to.value.status}</div>
                      </div>
                    </>
                  ) : (
                    <div className="mb-4">
                      <div className="text-gray-600">
                        {change.key === 'dell'
                          ? translate('Dell Engineering activities')
                          : ''}
                        {change.key === 'proof'
                          ? translate('Proof of performance')
                          : ''}
                        {change.key === 'amount'
                          ? translate('Payment amount')
                          : ''}
                        {change.key === 'status' ? translate('Status') : ''}
                      </div>
                      {change.key === 'amount' ? (
                        <div className="flex">
                          $ {change.from.value}
                          <Icon
                            name="arrow_forward"
                            size={6}
                            className="text-gray-600 mx-8"
                          />
                          <span className="text-blue-700">
                            $ {change.to.value}
                          </span>
                        </div>
                      ) : (
                        <div className="diff-viewer">
                          <ReactDiffViewer
                            styles={newStyles}
                            oldValue={change.from.value.replace('&nbsp;', ' ')}
                            newValue={change.to.value.replace('&nbsp;', ' ')}
                            splitView={true}
                            hideLineNumbers={true}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </Fragment>
          ),
        )}
      </ActivityBody>
    </ActivityContainer>
  );
};
